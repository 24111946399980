import PropTypes from 'prop-types';

const IconLinkedin = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest} className="IconLinkedin">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3292 0C19.252 0 20 0.747522 20 1.67079V18.3292C20 19.252 19.2525 20 18.3292 20H1.67079C0.748038 20 0 19.2525 0 18.3292V1.67079C0 0.748038 0.747522 0 1.67079 0H18.3292ZM13.5188 7.5C12.1098 7.5 11.1424 8.11816 10.7053 8.84019L10.625 8.98583V7.625H7.91667V17.2083H10.8333V12.5662C10.8333 11.0104 11.4062 10.0208 12.7833 10.0208C13.724 10.0208 14.1239 10.8447 14.1634 12.3152L14.1667 12.5662V17.2083H17.0833V11.9154C17.0833 9.0575 16.3996 7.5 13.5188 7.5ZM5.83333 7.5H2.91667V17.0833H5.83333V7.5ZM4.375 2.5C3.33958 2.5 2.5 3.33958 2.5 4.375C2.5 5.41042 3.33958 6.25 4.375 6.25C5.41042 6.25 6.25 5.41042 6.25 4.375C6.25 3.33958 5.41042 2.5 4.375 2.5Z"
      fill={color}
    />
  </svg>
);

IconLinkedin.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconLinkedin.defaultProps = {
  color: '#012934',
  height: 20,
  width: 20,
};

export default IconLinkedin;
