import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { IconClipboardCheck } from 'shared/icons';
import { formatDate, capitalizeFirstLetter } from 'shared/helpers';
import api from 'shared/api';
import {
  Alert,
  Button,
  TextInput,
  Loader,
  Notice,
} from 'shared/components';
import { refreshUser, refreshUserProfile } from 'redux/account/actions';
import * as Styled from './styles';

const KycConfirm = ({
  data,
  onConfirm,
  onRetry,
  documentType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line camelcase
  const { user_id, id_document_front_key, id_document_back_key } = data;

  const [isLoading, setLoading] = useState(true);
  const [idCardTextData, setIdCardTextData] = useState(null);
  const [idCardTextFile, setIdCardTextFile] = useState(null);
  const [extractTextFailed, setExtractTextFailed] = useState(false);

  const setAsVerified = (userSession, textData) => {
    // set user as validated in redux state
    dispatch(refreshUser(userSession));
    // refresh user profile
    dispatch(refreshUserProfile(textData));
  };

  useEffect(() => {
    api.post('/api/kyc/extract-text', {
      user_id, id_document_front_key, id_document_back_key, type: documentType.value,
    })
      .then((res) => {
        const textFile = get(res, 'data.file');
        const textData = get(res, 'data.profile');
        const userSession = get(res, 'data.user');
        setIdCardTextData(textData);
        setIdCardTextFile(textFile);
        setLoading(false);
        setAsVerified(userSession, textData);
      })
      .catch(() => {
        toast.error('KYC text extraction has failed');
        setLoading(false);
        setExtractTextFailed(true);
      });
  }, []);

  if (isLoading) {
    return (
      <Styled.KycConfirm>
        <Styled.LoadingScreen><Loader color="#20201E" /></Styled.LoadingScreen>
      </Styled.KycConfirm>
    );
  }

  return (
    <Styled.KycConfirm>
      <Styled.Icon><IconClipboardCheck /></Styled.Icon>
      <Styled.Title>
        Confirm your details
      </Styled.Title>
      <Styled.Text>
        Please take a moment to make sure that all your details are correct.
      </Styled.Text>
      <Styled.Label>
        Personal details
      </Styled.Label>
      <Styled.DataContainer>
        <TextInput
          id="nik"
          label="Personal ID number"
          onChange={() => { }}
          value={get(idCardTextFile, 'file_id') || ''}
          disabled
        />
        <TextInput
          id="fullname"
          label="Full name"
          onChange={() => { }}
          value={get(idCardTextData, 'full_name') || ''}
          disabled
        />
        <TextInput
          id="gender"
          label="Gender"
          onChange={() => { }}
          value={capitalizeFirstLetter(get(idCardTextData, 'gender'), '')}
          disabled
        />
        <TextInput
          id="dob"
          label="Date of birth"
          onChange={() => { }}
          value={formatDate(get(idCardTextData, 'birthday'))}
          disabled
        />
        <TextInput
          id="address"
          label="Residence Address"
          onChange={() => { }}
          value={get(idCardTextData, 'address') || ''}
          disabled
        />
      </Styled.DataContainer>
      <Notice
        title="Notice"
        content="To change fields extracted from KYC send a support request to the admin."
      />
      <Styled.Actions>
        <Button handleClick={onConfirm}>
          Confirm
        </Button>
      </Styled.Actions>
      {extractTextFailed && (
        <Alert
          isOpen
          title="Invalid ID Card"
          text="The document you uploaded doesn’t seem to be valid. Please retry the verification process or contact us for support."
          primaryBtnText="Retry the verification"
          onPrimaryBtnClick={onRetry}
          secondaryBtnText="Contact support"
          onSecondaryBtnClick={() => history.push('/support')}
        />
      )}
    </Styled.KycConfirm>
  );
};

KycConfirm.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  documentType: PropTypes.object.isRequired,
};

export default KycConfirm;
