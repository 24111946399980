/* eslint-disable consistent-return */
import { get } from 'lodash';
import styled, { css } from 'styled-components';

const getSM = () => css`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 20px;
  min-height: 32px;
`;

const getLG = () => css`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding: 8px 24px;
`;

const sizeStyling = (props) => {
  const { size } = props;

  switch (size) {
    case 'small':
      return getSM();
    case 'large':
      return getLG();
    default:
      return getLG();
  }
};

const getContainedVariation = (props) => css`
  background: ${() => get(props, 'theme.colors.primary.flamingo.flamingo50') || '#F26545'};
  color: ${() => get(props, 'theme.colors.shades.neutral.white') || '#fff'};

  &:hover,
  &:focus {
    background: ${() => get(props, 'theme.colors.primary.flamingo.flamingo40') || '#F5846A'};
  }

  &:disabled {
    background: ${() => get(props, 'theme.colors.shades.warm.dawn10')};
    color: ${() => get(props, 'theme.colors.shades.warm.dawn50')};
    cursor: not-allowed;
  }
`;

const getWarningVariation = (props) => css`
  background: ${() => get(props, 'theme.colors.danger.danger50') || '#F26545'};
  color: ${() => get(props, 'theme.colors.shades.neutral.white') || '#fff'};

  &:hover,
  &:focus {
    background: ${() => get(props, 'theme.colors.danger.danger40') || '#F5846A'};
  }

  &:disabled {
    background: ${() => get(props, 'theme.colors.shades.warm.dawn10')};
    color: ${() => get(props, 'theme.colors.shades.warm.dawn50')};
    cursor: not-allowed;
  }
`;

const getOutlinedVariation = (props) => css`
  background: ${() => get(props, 'theme.colors.shades.neutral.white') || '#fff'};
  border-width: 1px;
  border-style: solid;
  border-color: ${() => get(props, 'theme.colors.shades.warm.dawn30')};
  color: ${() => get(props, 'theme.colors.primary.daintree.daintree50') || '#012934'};
  font-weight: normal;

  &:hover,
  &:focus {
    background: ${() => get(props, 'theme.colors.primary.daintree.daintree05') || '#E6EAEB'};
    border-color: ${() => get(props, 'theme.colors.primary.daintree.daintree40') || '#34545D'};
  }

  &:disabled {
    background: ${() => get(props, 'theme.colors.shades.warm.dawn10')};
    color: ${() => get(props, 'theme.colors.shades.warm.dawn50')};
    border-color: transparent;
    cursor: not-allowed;
  }
`;

const getTextVariation = (props) => css`
  background: transparent;
  border-color: transparent;
  padding: 0px 4px;
  color: ${() => get(props, 'theme.colors.primary.daintree.daintree50') || '#E6EAEB'};

  &:hover,
  &:focus {
    background: ${() => get(props, 'theme.colors.primary.daintree.daintree05') || '#E6EAEB'};
  }

  &:disabled {
    color: ${() => get(props, 'theme.colors.shades.warm.dawn40')};
    cursor: not-allowed;
  }
`;

const variationStyling = (props) => {
  const { variant } = props;

  switch (variant) {
    case 'contained':
      return getContainedVariation(props);
    case 'warning':
      return getWarningVariation(props);
    case 'outlined':
      return getOutlinedVariation(props);
    case 'text':
      return getTextVariation(props);
    default:
      return getContainedVariation(props);
  }
};

const getWideStyling = () => css`
  width: 100%;
  max-width: 400px;
`;

export const Button = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  text-align: center;
  font-size: 16px;
  color: #fff;
  outline: 0;
  cursor: pointer;
  transition: 150ms all ease-in-out;
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  margin: 10px 0;

  ${props => sizeStyling(props)}
  ${props => variationStyling(props)}
  ${props => {
    if (props.wide) {
      return getWideStyling();
    }
  }}

  .Loader {
    position: absolute;
    left: 10px;
    top: 25%;
    padding: 0;
  }

  svg {
    margin-right: 10px;
  }
`;
