import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import api from 'shared/api';
import { capitalizeFirstLetter, formatDate, canCreateAsset } from 'shared/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import { useWindowDimensions } from 'shared/HOCs';
import {
  Button,
  Loader,
  PageLoader,
  Table,
} from 'shared/components';
import { IconDownload, IconDelete } from 'shared/icons';
import OfferingFilesUpload from '../OfferingFilesUpload';
import * as Styled from './styles';

const OfferingFiles = ({
  offering,
  isAuth,
  user,
}) => {
  const { isMobile } = useWindowDimensions();
  const offeringID = get(offering, 'id');
  const isAllowedToCreate = canCreateAsset(user);

  const [isLoading, setLoading] = useState(true);
  const [fileDeleting, setFileDeleting] = useState(null);
  const [fileDownloading, setFileDownloading] = useState(null);
  const [isAddFilesDisplayed, setAddFilesDisplayed] = useState(false);
  const [files, setFiles] = useState([]);

  const getFiles = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/files`)
      .then((res) => {
        setFiles(get(res, 'data') || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const handleDocDelete = (file) => {
    const fileID = get(file, 'file.id');
    setFileDeleting(fileID);
    api.delete(`/api/investing/offerings/files/${fileID}`)
      .then(() => {
        getFiles();
        setLoading(false);
        setFileDeleting(null);
        toast.success('Offering document deleted');
      })
      .catch(() => {
        setLoading(false);
        setFileDeleting(null);
        toast.error('Error occured');
      });
  };

  const handleDocDownload = (file) => {
    if (fileDownloading) {
      return false;
    }
    const fileUrl = get(file, 'file.parts[0]');
    const fileID = get(file, 'file.id');
    const fileExtension = get(file, 'file.file_extension');
    const fileName = get(file, 'file.name');
    setFileDownloading(fileID);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': `application/${fileExtension}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          // eslint-disable-next-line no-undef
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        const fallbackName = `offering_document-${fileID}`;
        link.setAttribute(
          'download',
          `${fileName || fallbackName}`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setFileDownloading(null);
      })
      .catch(() => {
        setFileDownloading(null);
        toast.error('Error occured');
      });
    return true;
  };

  const tableData = useMemo(() => files, [files]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'file.name',
      Cell: cell => capitalizeFirstLetter(cell.value, 'N/A'),
    },
    {
      Header: 'File extension',
      accessor: 'file.file_extension',
      Cell: cell => `.${get(cell, 'value') || 'N/A'}`,
      show: !isMobile,
    },
    {
      Header: 'Created at',
      accessor: 'file.created_at',
      Cell: cell => formatDate(get(cell, 'value'), defaultDateTimeFormat),
      show: !isMobile,
    },
    {
      Header: 'Download',
      headerClassName: 'centered',
      id: 'download',
      Cell: (cell) => {
        const isDownloading = fileDownloading === get(cell, 'cell.row.original.file.id');
        return (
          <Styled.TableBtn>
            <Button
              variant="text"
              size="small"
              onClick={() => handleDocDownload(get(cell, 'row.original'))}
            >
              {isDownloading ? <Loader /> : <IconDownload />}
            </Button>
          </Styled.TableBtn>
        );
      },
      width: 110,
      maxWidth: 110,
    },
    {
      Header: 'Delete',
      headerClassName: 'centered',
      id: 'delete',
      Cell: (cell) => {
        const isDeleting = fileDeleting === get(cell, 'cell.row.original.file.id');
        return (
          <Styled.TableBtn>
            <Button
              variant="text"
              size="small"
              onClick={() => handleDocDelete(cell, 'row.original')}
            >
              {isDeleting ? <Loader /> : <IconDelete color="#CC3429" />}
            </Button>
          </Styled.TableBtn>
        );
      },
      width: 80,
      maxWidth: 80,
      show: isAuth,
    },
  ], [files, fileDownloading, fileDeleting]);

  if (isLoading) {
    return <Styled.OfferingFiles><PageLoader /></Styled.OfferingFiles>;
  }

  return (
    <Styled.OfferingFiles>
      {isAuth && isAllowedToCreate && (
        <Button
          size="small"
          type="submit"
          handleClick={() => setAddFilesDisplayed(true)}
        >
          Add files
        </Button>
      )}
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable={false}
        rowClassName="UsersListRow"
      />
      {isAddFilesDisplayed && (
        <OfferingFilesUpload
          isOpen={isAddFilesDisplayed}
          closeCb={() => setAddFilesDisplayed(false)}
          offering={offering}
          refetchFiles={getFiles}
        />
      )}
    </Styled.OfferingFiles>
  );
};

OfferingFiles.propTypes = {
  offering: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isAuth: PropTypes.bool,
};

OfferingFiles.defaultProps = {
  isAuth: false,
};

export default OfferingFiles;
