import styled from 'styled-components';

export const AddOfferingMemberForm = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;

  button {
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }
`;

export const Form = styled.div`

  .bulk-search-input {

    .react-dropdown-select-option {
      display: none;
    }
  }
`;

export const BulkUsersList = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
  margin-bottom: 20px;
`;

export const RemoveMember = styled.div`
  display: none ;
  margin-left: auto;
  padding-left: 10px;
`;

export const ClickableUserCell = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: start;
  padding: 10px;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.colors.shades.warm.dawn10};

    .member-delete {
      display: flex;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .TextInput {
    max-width: unset;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
