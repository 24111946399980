import PropTypes from 'prop-types';

const IconProfile = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconProfile">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66665 2.3335C1.66665 1.22893 2.56208 0.333496 3.66665 0.333496H10.3333C11.4379 0.333496 12.3333 1.22893 12.3333 2.3335V12.3335L13 12.3335C13.3682 12.3335 13.6666 12.6319 13.6666 13.0001C13.6667 13.3683 13.3682 13.6668 13 13.6668H0.999971C0.631781 13.6668 0.333308 13.3683 0.333313 13.0001C0.333318 12.6319 0.631799 12.3335 0.999989 12.3335L1.66665 12.3335V2.3335ZM2.99998 12.3335H4.99998V9.66681C4.99998 8.93044 5.59693 8.33348 6.33331 8.33348H7.66665C8.40303 8.33348 8.99998 8.93044 8.99998 9.66681V12.3335H11V2.3335C11 1.96531 10.7015 1.66683 10.3333 1.66683H3.66665C3.29846 1.66683 2.99998 1.96531 2.99998 2.3335V12.3335ZM7.66665 12.3335V9.66681H6.33331V12.3335H7.66665ZM4.33331 3.66681C4.33331 3.29862 4.63179 3.00015 4.99998 3.00015H5.66665C6.03484 3.00015 6.33331 3.29862 6.33331 3.66681C6.33331 4.035 6.03484 4.33348 5.66665 4.33348H4.99998C4.63179 4.33348 4.33331 4.035 4.33331 3.66681ZM7.66665 3.66681C7.66665 3.29862 7.96512 3.00015 8.33331 3.00015H8.99998C9.36817 3.00015 9.66665 3.29862 9.66665 3.66681C9.66665 4.035 9.36817 4.33348 8.99998 4.33348H8.33331C7.96512 4.33348 7.66665 4.035 7.66665 3.66681ZM4.33331 6.33348C4.33331 5.96529 4.63179 5.66681 4.99998 5.66681H5.66665C6.03484 5.66681 6.33331 5.96529 6.33331 6.33348C6.33331 6.70167 6.03484 7.00015 5.66665 7.00015H4.99998C4.63179 7.00015 4.33331 6.70167 4.33331 6.33348ZM7.66665 6.33348C7.66665 5.96529 7.96512 5.66681 8.33331 5.66681H8.99998C9.36817 5.66681 9.66665 5.96529 9.66665 6.33348C9.66665 6.70167 9.36817 7.00015 8.99998 7.00015H8.33331C7.96512 7.00015 7.66665 6.70167 7.66665 6.33348Z"
      fill={color}
    />
  </svg>
);

IconProfile.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconProfile.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconProfile;
