/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useFlexLayout,
  useAsyncDebounce,
  useSortBy,
} from 'react-table';
import {
  IconArrowDown,
  IconArrowUp,
} from 'shared/icons';
import * as Styled from './styles';

const Table = ({
  columns,
  data,
  isClickable,
  onFetchData,
  onRowClick,
  getRowStyle,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {
      pageIndex, pageSize, sortBy, filters,
    },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map(column => {
          if (column.show === false) return column.accessor || column.id;
        }),
      },
    },
    useFlexLayout,
    useSortBy,
  );

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100);

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex, pageSize, sortBy, filters,
    });
  }, [onFetchData, pageIndex, pageSize, sortBy, filters]);

  return (
    <Styled.TableWrapper>
      <Styled.Table {...getTableProps()}>
        <Styled.Thead>
          {headerGroups.map(headerGroup => (
            <Styled.TrHead {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Styled.Th {...column.getHeaderProps([
                  column.getSortByToggleProps(),
                  {
                    className: column.headerClassName,
                    style: column.style,
                  },
                ])}
                >
                  {column.render('Header')}
                  <Styled.ThSortIndicator>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <IconArrowDown />
                        : <IconArrowUp />
                      : ''}
                  </Styled.ThSortIndicator>
                </Styled.Th>
              ))}
            </Styled.TrHead>
          ))}
        </Styled.Thead>
        <Styled.Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Styled.TrBody
                {...row.getRowProps()}
                onClick={() => onRowClick(row.original)}
                isClickable={isClickable}
                style={{ ...row.getRowProps().style, ...getRowStyle(row) }}
              >
                {row.cells.map(cell => (
                  <Styled.Td {...cell.getCellProps({ className: cell.column.className })}>
                    {cell.render('Cell')}
                  </Styled.Td>
                ))}
              </Styled.TrBody>
            );
          })}
        </Styled.Tbody>
        {!rows.length && (
          <Styled.NoData>
            <tr>
              <td>No data</td>
            </tr>
          </Styled.NoData>
        )}
      </Styled.Table>
    </Styled.TableWrapper>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isClickable: PropTypes.bool,
  onFetchData: PropTypes.func,
  onRowClick: PropTypes.func,
  getRowStyle: PropTypes.func,
};

Table.defaultProps = {
  isClickable: false,
  onFetchData: () => { },
  onRowClick: () => { },
  getRowStyle: () => { },
};

export default Table;
