/* eslint-disable no-useless-escape */
import {
  EmojiFrowning,
  EmojiSlightlyFrowning,
  EmojiConfused,
  EmojiNeutral,
  EmojiSlightlySmiling,
  EmojiGrining,
  EmojiSmiling,
} from 'shared/icons';

export const appName = 'client';

export const defaultDateTimeFormat = 'DD/MM/YYYY HH:mm';
export const defaultDateFormat = 'DD/MM/YYYY';
export const defaultTimeFormat = 'HH:mm';

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const invitationTypes = {
  number: 'number',
  email: 'email',
};

export const ticketSeverityOptions = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Critical',
    value: 'critical',
  },
];

export const genderOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Unknown',
    value: 'unknown',
  },
  {
    label: 'Not applicable',
    value: 'notapplicable',
  },
];

export const emojiAnswers = {
  disagree_completely: {
    title: 'Completely disagree',
    emoji: EmojiFrowning,
  },
  disagree_strongly: {
    title: 'Strongly disagree',
    emoji: EmojiSlightlyFrowning,
  },
  disagree: {
    title: 'Disagree',
    emoji: EmojiConfused,
  },
  neutral: {
    title: 'Neutral',
    emoji: EmojiNeutral,
  },
  agree: {
    title: 'Agree',
    emoji: EmojiSlightlySmiling,
  },
  agree_strongly: {
    title: 'Strongly agree',
    emoji: EmojiGrining,
  },
  agree_completely: {
    title: 'Completely agree',
    emoji: EmojiSmiling,
  },
};

export const documentTypes = [
  {
    value: 'id_card',
    label: 'ID card',
  },
  {
    value: 'passport',
    label: 'Passport',
  },
];

export const cameraTypes = {
  front: 'front',
  back: 'back',
  selfie: 'selfie',
};

export const clientRoleOptions = [
  {
    label: 'Venture partner',
    value: 'negotiator',
  },
  {
    label: 'Investor',
    value: 'maker',
  },
  {
    label: 'Entrepreneur',
    value: 'taker',
  },
];

export const adminRoleOptions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Super admin',
    value: 'super_admin',
  },
  {
    label: 'Reporter',
    value: 'reporter',
  },
];
