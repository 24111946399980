import PropTypes from 'prop-types';
import { IconNoProfilePicture } from 'shared/icons';
import * as Styled from './styles';

const UserInfoCell = ({
  profileImg,
  name,
  metaFirst,
  metaSecond,
}) => (
  <Styled.UserInfoCell>
    <Styled.ImgContainer>
      {profileImg ? (
        <Styled.ProfileImg src={profileImg} alt="profile picture" />
      ) : (
        <IconNoProfilePicture />
      )}
    </Styled.ImgContainer>
    <Styled.UserData>
      <Styled.Name>{name || '-'}</Styled.Name>
      {(metaFirst || metaSecond) && (
        <Styled.MetaContainer>
          <Styled.Meta>{metaFirst}</Styled.Meta>
          {metaSecond && <Styled.MetaDivider />}
          <Styled.Meta>{metaSecond}</Styled.Meta>
        </Styled.MetaContainer>
      )}
    </Styled.UserData>
  </Styled.UserInfoCell>
);

UserInfoCell.propTypes = {
  profileImg: PropTypes.string,
  name: PropTypes.string,
  metaFirst: PropTypes.string,
  metaSecond: PropTypes.string,
};

UserInfoCell.defaultProps = {
  profileImg: '',
  name: '',
  metaFirst: '',
  metaSecond: '',
};

export default UserInfoCell;
