import PropTypes from 'prop-types';

const IconLogout = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} className="IconLogout">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99998 1.33333C2.2636 1.33333 1.66665 1.93029 1.66665 2.66667V9.33333C1.66665 10.0697 2.2636 10.6667 2.99998 10.6667H7.66665C8.40303 10.6667 8.99998 10.0697 8.99998 9.33333V8.66667C8.99998 8.29848 9.29846 8 9.66665 8C10.0348 8 10.3333 8.29848 10.3333 8.66667V9.33333C10.3333 10.8061 9.13941 12 7.66665 12H2.99998C1.52722 12 0.333313 10.8061 0.333313 9.33333V2.66667C0.333313 1.19391 1.52722 0 2.99998 0H7.66665C9.13941 0 10.3333 1.19391 10.3333 2.66667V3.33333C10.3333 3.70152 10.0348 4 9.66665 4C9.29846 4 8.99998 3.70152 8.99998 3.33333V2.66667C8.99998 1.93029 8.40303 1.33333 7.66665 1.33333H2.99998ZM6.80472 2.86193C7.06507 3.12228 7.06507 3.54439 6.80472 3.80474L5.27612 5.33333L13 5.33333C13.3682 5.33333 13.6666 5.63181 13.6666 6C13.6666 6.36819 13.3682 6.66667 13 6.66667L5.27612 6.66667L6.80472 8.19526C7.06507 8.45561 7.06507 8.87772 6.80472 9.13807C6.54437 9.39842 6.12226 9.39842 5.86191 9.13807L3.19524 6.4714C2.93489 6.21106 2.93489 5.78895 3.19524 5.5286L5.86191 2.86193C6.12226 2.60158 6.54437 2.60158 6.80472 2.86193Z"
      fill={color}
    />
  </svg>
);

IconLogout.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconLogout.defaultProps = {
  color: '#E6EAEB',
  height: 12,
  width: 14,
};

export default IconLogout;
