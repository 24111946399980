import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import { Button, Loader } from 'shared/components';
import { getFileExtension } from 'shared/helpers';
import { sendErrorReport } from 'shared/errorReport';
import api from 'shared/api';
import { IconUser, IconClose, IconUpload } from 'shared/icons';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const ChangeOfferingBannerForm = ({
  isOpen,
  closeCb,
  confirmCb,
  offering,
}) => {
  const offeringID = get(offering, 'id');

  const [isLoading, setLoading] = useState(false);
  const [offeringPicture, setOfferingPicture] = useState(get(offering, 'logo'));
  const [isOfferingPictureLoading, setOfferingPictureLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const updateOfferingPicture = useCallback(() => {
    setLoading(true);

    const formData = new FormData();
    formData.append(
      'image',
      selectedFile,
      get(selectedFile, 'name') || 'profile_picture',
    );
    formData.append('type', 'logo');
    formData.append('extension', getFileExtension(get(selectedFile, 'name')));

    api.post(`/api/investing/offerings/${offeringID}/branding`, formData)
      .then(() => {
        setOfferingPicture(URL.createObjectURL(selectedFile));
        setOfferingPictureLoading(false);
        setLoading(false);
        setSelectedFile(null);
        confirmCb();
        toast.success('Offering picture uploaded');
      })
      .catch((err) => {
        sendErrorReport(err, 'change_offering_logo', formData);
        setLoading(false);
        setOfferingPictureLoading(false);
        toast.error('Unable to upload image');
      });
  }, [offeringID, selectedFile]);

  const handleSubmit = () => {
    if (isLoading) {
      return false;
    }

    setLoading(true);
    updateOfferingPicture();
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  const getContent = () => {
    if (isOfferingPictureLoading) {
      return <Styled.UserOfferingPictureUpload><Loader color="#A3A3A3" /></Styled.UserOfferingPictureUpload>;
    }

    if (offeringPicture) {
      return (
        <Styled.UserOfferingPictureUpload>
          <Styled.PicturePreview>
            <Styled.PicturePreviewImg src={offeringPicture} alt="profile_picture" />
          </Styled.PicturePreview>
          <div>
            <Styled.Label className="custom-file-upload" htmlFor="file-upload">
              <Styled.Input type="file" id="file-upload" accept=".jpg,.jpeg,.png" onChange={onFileChange} />
              <Styled.UploadData>
                {selectedFile ? (
                  <Styled.UploadPreviewWrapper>
                    <Styled.UploadPreview src={URL.createObjectURL(selectedFile)} />
                    <Button wide size="small" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
                      Upload
                    </Button>
                  </Styled.UploadPreviewWrapper>
                ) : (
                  <Styled.ChangePicture>
                    <IconUpload height={14} width={14} />
                    Change picture
                  </Styled.ChangePicture>
                )}
              </Styled.UploadData>
            </Styled.Label>
          </div>
        </Styled.UserOfferingPictureUpload>
      );
    }

    return (
      <Styled.UserOfferingPictureUpload>
        <Styled.Picture src={noImagePlaceholder} alt="no picture placeholder" />
        <Styled.Label className="custom-file-upload" htmlFor="file-upload">
          <Styled.Input type="file" id="file-upload" onChange={onFileChange} accept=".jpg,.jpeg,.png" />
          <Styled.Uploader>
            <Styled.Left>
              <Styled.UploaderLabel>Offering banner</Styled.UploaderLabel>
              {selectedFile ? (
                <Styled.UploadPreviewWrapper>
                  <Styled.UploadPreview src={URL.createObjectURL(selectedFile)} />
                </Styled.UploadPreviewWrapper>
              ) : (
                <Styled.Desc>Upload...</Styled.Desc>
              )}
            </Styled.Left>
            <Styled.Right><IconUpload /></Styled.Right>
          </Styled.Uploader>
        </Styled.Label>
        {selectedFile && (
          <Button wide size="small" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
            Upload
          </Button>
        )}
      </Styled.UserOfferingPictureUpload>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="ChangeOfferingBannerFormModal"
    >
      <Styled.ChangeOfferingBannerForm>
        <Styled.Header>
          <IconUser />
          <Styled.HeaderTitle>Change offering banner</Styled.HeaderTitle>
        </Styled.Header>
        {getContent()}
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.ChangeOfferingBannerForm>
    </ReactModal>
  );
};

ChangeOfferingBannerForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
};

export default ChangeOfferingBannerForm;
