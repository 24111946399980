import PropTypes from 'prop-types';

const IconRetry = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...rest} className="IconRetry">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0C1.55228 0 2 0.447715 2 1V3.34298C3.64938 1.30446 6.17168 0 9 0C13.5903 0 17.3767 3.43564 17.9304 7.87626C17.9988 8.4243 17.6099 8.92397 17.0618 8.99232C16.5138 9.06066 16.0141 8.67178 15.9458 8.12374C15.5153 4.67174 12.5689 2 9 2C6.62231 2 4.51998 3.18566 3.25442 5H6C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7H1C0.447715 7 0 6.55228 0 6V1C0 0.447715 0.447715 0 1 0ZM0.938154 9.00769C1.48619 8.93935 1.98587 9.32822 2.05421 9.87626C2.48467 13.3283 5.43109 16 9 16C11.3777 16 13.48 14.8143 14.7456 13H12C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11H17C17.5523 11 18 11.4477 18 12V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V14.657C14.3506 16.6955 11.8283 18 9 18C4.40967 18 0.623317 14.5644 0.0695792 10.1237C0.0012396 9.5757 0.390114 9.07603 0.938154 9.00769Z"
      fill={color}
    />
  </svg>
);

IconRetry.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconRetry.defaultProps = {
  color: '#fff',
  height: 18,
  width: 18,
};

export default IconRetry;
