import PropTypes from 'prop-types';

const IconInterest = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 17 20" fill="none" {...rest} className="IconInterest">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2C8.22386 2 8 2.22386 8 2.5V9C8 9.55229 7.55228 10 7 10C6.44772 10 6 9.55229 6 9V3.5C6 3.22386 5.77614 3 5.5 3C5.22386 3 5 3.22386 5 3.5V12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12V9.5C3 9.22386 2.77614 9 2.5 9C2.22386 9 2 9.22386 2 9.5V11.5C2 15.0899 4.91015 18 8.5 18C12.0899 18 15 15.0899 15 11.5V6.5C15 6.22386 14.7761 6 14.5 6C14.2239 6 14 6.22386 14 6.5V9C14 9.55229 13.5523 10 13 10C12.4477 10 12 9.55229 12 9V3.5C12 3.22386 11.7761 3 11.5 3C11.2239 3 11 3.22386 11 3.5V9C11 9.55229 10.5523 10 10 10C9.44771 10 9 9.55229 9 9V2.5C9 2.22386 8.77614 2 8.5 2ZM10.6123 1.16218C10.8882 1.05738 11.1874 1 11.5 1C12.8807 1 14 2.11929 14 3.5V4.05001C14.1616 4.01721 14.3288 4 14.5 4C15.8807 4 17 5.11929 17 6.5V11.5C17 16.1944 13.1944 20 8.5 20C3.80558 20 0 16.1944 0 11.5V9.5C0 8.11929 1.11929 7 2.5 7C2.67123 7 2.83845 7.01722 3 7.05001V3.5C3 2.11929 4.11929 1 5.5 1C5.81262 1 6.11183 1.05738 6.38769 1.16218C6.83104 0.463627 7.61138 0 8.5 0C9.38862 0 10.169 0.463627 10.6123 1.16218Z"
      fill={color}
    />
  </svg>
);

IconInterest.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconInterest.defaultProps = {
  color: '#fff',
  height: 20,
  width: 17,
};

export default IconInterest;
