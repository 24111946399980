/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Table,
  Loader,
  Pagination,
  UserInfoCell,
} from 'shared/components';
import { useWindowDimensions } from 'shared/HOCs';
import * as Styled from './styles';

const YocConnections = ({
  userID,
  userClickCb,
}) => {
  const { isMobile } = useWindowDimensions();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const getUserYOC = useCallback((
    page = 0,
  ) => {
    api.get(`/api/yoc/users/${userID}/connections?page=${page + 1}&per_page=10&mode=inviter`)
      .then((res) => {
        const yocData = get(res, 'data');
        setPageCount(get(yocData, 'count_pages'));
        setActivePage(page);
        setData(get(yocData, 'records'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    getUserYOC();
  }, [getUserYOC]);

  const changeListPage = (val) => {
    const { selected } = val;
    if (selected !== activePage) {
      setActivePage(selected);
      getUserYOC(selected);
    }
  };

  const handleRowClick = row => userClickCb(row);

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'display_name',
      Cell: cell => (
        <UserInfoCell
          profileImg={get(cell, 'row.original.profile_picture')}
          name={get(cell, 'value')}
          metaFirst={get(cell, 'row.original.user_name')}
          metaSecond={`${get(cell, 'row.original.num_connections')} connections`}
        />
      ),
      minWidth: 250,
    },
  ], [data]);

  if (isLoading) {
    return <Styled.YocConnections><Loader color="#616E7F" /></Styled.YocConnections>;
  }

  return (
    <Styled.YocConnections>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onFetchData={() => { }}
        onRowClick={handleRowClick}
        rowClassName="YocListRow"
      />
      <Styled.ListPagination>
        <Pagination
          page={activePage}
          pageCount={pageCount}
          handlePageClick={changeListPage}
          pageRangeDisplayed={isMobile ? 1 : 3}
        />
      </Styled.ListPagination>
    </Styled.YocConnections>
  );
};

YocConnections.propTypes = {
  userID: PropTypes.number.isRequired,
  userClickCb: PropTypes.func.isRequired,
};

export default YocConnections;
