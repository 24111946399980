import PropTypes from 'prop-types';

const IconLocation = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 9 11" fill="none" {...rest} className="IconLocation">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5302 1.4301C3.1704 -0.210099 5.82969 -0.2101 7.4699 1.4301C9.1101 3.0703 9.1101 5.7296 7.4699 7.3698L4.50005 10.3396L1.5302 7.3698C-0.110002 5.7296 -0.110002 3.0703 1.5302 1.4301ZM4.50005 5.59995C5.16279 5.59995 5.70005 5.06269 5.70005 4.39995C5.70005 3.73721 5.16279 3.19995 4.50005 3.19995C3.83731 3.19995 3.30005 3.73721 3.30005 4.39995C3.30005 5.06269 3.83731 5.59995 4.50005 5.59995Z"
      fill={color}
    />
  </svg>
);

IconLocation.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconLocation.defaultProps = {
  color: '#615F59',
  height: 11,
  width: 9,
};

export default IconLocation;
