import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { IconYOC, IconOrganization, IconOffering } from 'shared/icons';
import { canCreateAsset, isInvestor } from 'shared/helpers';
import * as Styled from './styles';

const IntroCard = () => {
  const user = useSelector(state => get(state, 'account.user'));
  const isAllowedToCreate = canCreateAsset(user, false);
  const userIsInvestor = isInvestor(user);

  return (
    <Styled.IntroCard>
      <Styled.Title>Try out our new features:</Styled.Title>
      <Styled.Item>
        <Link to="/yoc">
          <IconYOC height={14} width={14} color="#191919" />
          <Styled.Text>Invite the people you trust</Styled.Text>
        </Link>
      </Styled.Item>
      {isAllowedToCreate && (
        <Styled.Item>
          <Link to="/organizations">
            <IconOrganization height={14} width={14} color="#191919" />
            <Styled.Text>Create your own organization and offering</Styled.Text>
          </Link>
        </Styled.Item>
      )}
      {userIsInvestor && (
        <Styled.Item>
          <Link to="/offerings">
            <IconOffering height={14} width={14} color="#191919" />
            <Styled.Text>Invest in deals vetted by our Venture Partners network</Styled.Text>
          </Link>
        </Styled.Item>
      )}
    </Styled.IntroCard>
  );
};

export default IntroCard;
