import PropTypes from 'prop-types';

const IconClock = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconClock">
    <path
      d="M4.2 0.599998C3.75817 0.599998 3.4 0.958171 3.4 1.4C3.4 1.84183 3.75817 2.2 4.2 2.2H5.8C6.24182 2.2 6.6 1.84183 6.6 1.4C6.6 0.958171 6.24182 0.599998 5.8 0.599998H4.2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13.4C10.5346 13.4 13.4 10.5346 13.4 7C13.4 3.46538 10.5346 0.599998 7 0.599998C3.46538 0.599998 0.6 3.46538 0.6 7C0.6 10.5346 3.46538 13.4 7 13.4ZM8 3.8C8 3.24771 7.55229 2.8 7 2.8C6.44772 2.8 6 3.24771 6 3.8V7C6 7.26522 6.10536 7.51957 6.29289 7.70711L8.55564 9.96985C8.94616 10.3604 9.57932 10.3604 9.96985 9.96985C10.3604 9.57932 10.3604 8.94616 9.96985 8.55563L8 6.58579V3.8Z"
      fill={color}
    />
  </svg>
);

IconClock.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconClock.defaultProps = {
  color: '#A19E95',
  height: 14,
  width: 14,
};

export default IconClock;
