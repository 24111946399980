import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  SidePanel,
  Selector,
} from 'shared/components';
import { IconClose, IconSupportFill, IconSend } from 'shared/icons';
import api from 'shared/api';
import { ticketSeverityOptions } from 'shared/constants';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  message: yup
    .string()
    .required(),
});

const AddTicketForm = ({
  isOpen,
  onClose,
  refreshTickets,
}) => {
  const currentUser = useSelector(state => get(state, 'account.user'));
  const userID = get(currentUser, 'user_id');
  const [severity, setSeverity] = useState([ticketSeverityOptions[0]]);

  const [isLoading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const msg = get(data, 'message');
    if (isLoading || !msg) {
      return false;
    }

    const formData = {
      severity: get(severity, '[0].value'),
      text: get(data, 'message'),
      user_id: userID,

    };

    setLoading(true);
    api.post('/api/user-management/tickets', formData)
      .then(() => {
        refreshTickets();
        toast.success('New support ticket created');
        setLoading(false);
        onClose();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <SidePanel isOpen={isOpen} close={onClose}>
      <Styled.AddTicketForm>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconSupportFill />
            <Styled.HeaderTitleText>Add new ticket</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={onClose}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.DetailsInner>
          <Styled.DetailsRow>
            <Selector
              id="severity-input"
              name="severity"
              label="Priority"
              placeholder="Select priority"
              options={ticketSeverityOptions}
              onChange={(val) => setSeverity(val)}
              values={severity}
              labelField="label"
              valueField="value"
            />
          </Styled.DetailsRow>
        </Styled.DetailsInner>
        <Styled.InputContainer>
          <Controller
            name="message"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                id="message"
                name="message"
                size="medium"
                placeholder="Write your message"
                error={errors.message?.message}
                {...field}
              />
            )}
          />
          <Button handleClick={() => handleSubmit(onSubmit)()}>
            <IconSend />
          </Button>
        </Styled.InputContainer>
      </Styled.AddTicketForm>
    </SidePanel>
  );
};

AddTicketForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshTickets: PropTypes.func.isRequired,
};

export default AddTicketForm;
