import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Banner, Page } from 'shared/components';
import { checkKycStatus } from 'shared/helpers';
import {
  ExternalCard,
  IntroCard,
  KybCard,
  KycCard,
  NotificationsCard,
  UnfinishedProfileCard,
} from './components';
import * as Styled from './styles';

const Dashboard = () => {
  const organizations = useSelector(state => get(state, 'investing.organizations'));
  const user = useSelector(state => get(state, 'account.user'));
  const userProfile = useSelector(state => get(state, 'account.userProfile'));
  const userFullName = get(userProfile, 'full_name');
  const userName = get(user, 'user_name');
  const kYCStatus = checkKycStatus(get(user, 'status'));

  const external = kYCStatus.isExternal;
  const notValidated = !kYCStatus.isValidated && !kYCStatus.isExternal;
  const kybOrganizations = organizations.filter(o => o.status === 'pending');

  return (
    <Page type="dashboard" title="dashboard">
      <Styled.Dashboard>
        <Banner
          title={`Hi, ${userFullName || userName}`}
        />
        <NotificationsCard />
        <Styled.Actions>
          {external && <ExternalCard />}
          {notValidated && <KycCard />}
          {kybOrganizations.map(o => <KybCard key={o.id} organization={o} />)}
          <UnfinishedProfileCard userProfile={userProfile} />
        </Styled.Actions>
        <IntroCard />
      </Styled.Dashboard>
    </Page>
  );
};

export default Dashboard;
