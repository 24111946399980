import { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import {
  Button,
  TextInput,
  Selector,
} from 'shared/components';
import { IconClose } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');
const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.7)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const SelectUserRole = ({
  isOpen,
  closeCb,
  confirmCb,
  user,
  userRoles,
}) => {
  const userName = get(user, 'full_name');
  const userUsername = get(user, 'user_name');
  const [selectedRole, setSelectedRole] = useState([userRoles[0]]);

  const onSubmit = () => {
    confirmCb(selectedRole[0]);
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="SelectUserRoleModal"
    >
      <Styled.SelectUserRole>
        <Styled.Header>
          <Styled.HeaderTitle>Select user role</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.InputContainer>
          <TextInput
            id="user-input"
            label="User"
            type="text"
            value={userName || userUsername || 'N/A'}
            onChange={() => { }}
            disabled
          />
        </Styled.InputContainer>
        <Styled.InputContainer>
          <Selector
            id="role-input"
            name="selected-role"
            label="Your role"
            options={userRoles}
            onChange={(val) => {
              setSelectedRole(val);
            }}
            values={selectedRole}
            labelField="label"
            valueField="value"
          />
        </Styled.InputContainer>
        <Styled.ButtonContainer>
          <Button type="submit" wide handleClick={onSubmit}>
            Save
          </Button>
        </Styled.ButtonContainer>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.SelectUserRole>
    </ReactModal>
  );
};

SelectUserRole.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  userRoles: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default SelectUserRole;
