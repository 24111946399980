import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Page,
  PageLoader,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  NotAuthorized,
} from 'shared/components';
import { IconOffering } from 'shared/icons';
import {
  OfferingFiles,
  OfferingMembers,
  OfferingOwners,
  OfferingInvestments,
} from '../components';
import * as Styled from './styles';

const OfferingDataroom = ({
  // eslint-disable-next-line no-unused-vars
  isAuth,
}) => {
  const params = useParams();
  const history = useHistory();
  const { offeringID } = params;
  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [offeringData, setOfferingData] = useState(null);
  // offering owners
  const [owners, setOwners] = useState([]);
  const [ownersLoading, setOwnersLoading] = useState(true);
  // offering members
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState(true);

  const getOfferingOwners = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/owners`)
      .then((res) => {
        setOwners(get(res, 'data') || []);
        setOwnersLoading(false);
      })
      .catch(() => {
        setOwnersLoading(false);
      });
  }, [offeringID]);

  const getOfferingMembers = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/members`)
      .then((res) => {
        setMembers(get(res, 'data') || []);
        setMembersLoading(false);
      })
      .catch(() => {
        setMembersLoading(false);
      });
  }, [offeringID]);

  const getOffering = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}`)
      .then((res) => {
        setOfferingData(get(res, 'data.offering'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getOffering();
    getOfferingOwners();
    getOfferingMembers();
  }, [getOffering, getOfferingOwners, getOfferingMembers]);

  if (isLoading || ownersLoading || membersLoading) {
    return <Page showBack type="offerings" title="Offering dataroom"><PageLoader /></Page>;
  }

  const offeringName = get(offeringData, 'name');
  const isUserOffering = owners.find(o => get(o, 'profile.user_id') === currentUserID);
  const isUserOfferingMember = members.find(o => get(o, 'profile.user_id') === currentUserID);

  if (!isUserOffering && !isUserOfferingMember) {
    return (
      <NotAuthorized
        title="You can’t access this page"
        text="You are not a member of this offering"
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  return (
    <Page type="offerings" title="Offering dataroom">
      <Styled.OfferingDataroom>
        <Styled.OfferingTabs>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconOffering color="#20201E" /></Styled.TitleIcon>
              <Styled.TitleText>
                <Link to={`/offerings/${offeringID}`}>{`${offeringName}`}</Link>
                <Styled.Heading>dataroom</Styled.Heading>
              </Styled.TitleText>
            </Styled.ListTitle>
          </Styled.ListHeader>
          <Tabs>
            <TabsHeader>
              {isUserOffering && <Tab>Members</Tab>}
              {isUserOffering && <Tab>Owners</Tab>}
              <Tab>Files</Tab>
              {isUserOffering && <Tab>Investments</Tab>}
            </TabsHeader>
            {isUserOffering && (
              <TabContent>
                <OfferingMembers offering={offeringData} isAuth={!!isUserOffering} />
              </TabContent>
            )}
            {isUserOffering && (
              <TabContent>
                <OfferingOwners offering={offeringData} isAuth={!!isUserOffering} />
              </TabContent>
            )}
            <TabContent>
              <OfferingFiles offering={offeringData} isAuth={!!isUserOffering} user={currentUser} />
            </TabContent>
            {isUserOffering && (
              <TabContent>
                <OfferingInvestments offering={offeringData} />
              </TabContent>
            )}
          </Tabs>
        </Styled.OfferingTabs>
      </Styled.OfferingDataroom>
    </Page>
  );
};

OfferingDataroom.propTypes = {
  isAuth: PropTypes.bool,
};

OfferingDataroom.defaultProps = {
  isAuth: false,
};

export default OfferingDataroom;
