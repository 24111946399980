import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, IndicatorDot, DocumentTypeSelector } from 'shared/components';
import { documentTypes } from 'shared/constants';
import {
  IconKyc,
  IconIdentification,
  IconSun,
  IconGlobe,
} from 'shared/icons';
import * as Styled from './styles';

const OnboardingGallery = ({
  steps,
  onFinishConfirm,
  finishBtn,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState([documentTypes[0]]);

  const onBoardingSteps = [
    {
      icon: IconKyc,
      title: 'Let’s verify your identity first.',
      text: 'For security and compliance reasons we need to verify the identity of all our users. It will only take a few minutes.',
    },
    {
      icon: IconIdentification,
      title: 'Have your ID handy.',
      text: 'We’ll ask you to take a picture of your ID, then a selfie so that our system can verify that the two match. The process is completely automated.',
    },
    {
      icon: IconSun,
      title: 'Snap the pictures in a well lit environment.',
      text: 'Make the check run smoothly by placing yourself in a well lit environment so that the images come out bright and crisp.',
    },
    {
      icon: IconGlobe,
      title: 'Choose document type',
      text: '',
      children: () => <DocumentTypeSelector selected={selectedDocument} onSelect={val => setSelectedDocument(val)} />,
    },
  ];

  const gallerySteps = steps || onBoardingSteps;
  const isLastStep = activeStep === gallerySteps.length - 1;
  const isFirstStep = activeStep === 0;

  const handleBtn = () => {
    if (isLastStep) {
      onFinishConfirm(selectedDocument[0]);
    } else {
      setActiveStep(prevState => prevState + 1);
    }
  };

  const getBtnContent = () => {
    if (isLastStep) {
      return finishBtn;
    }
    return 'Next';
  };

  return (
    <Styled.Onboarding>
      <Styled.Indicators>
        {gallerySteps.map((s, i) => <IndicatorDot key={s.title} isActive={activeStep === i} />)}
      </Styled.Indicators>
      <Styled.OnboardingMain>
        {gallerySteps.map((s, i) => (
          <Styled.StepContainer key={s.title} isActive={activeStep === i}>
            <Styled.StepIcon>
              <s.icon color="#34545D" />
            </Styled.StepIcon>
            <Styled.StepTitle>{s.title}</Styled.StepTitle>
            <Styled.StepText>{s.text}</Styled.StepText>
            {s.children ? <s.children /> : null}
          </Styled.StepContainer>
        ))}
      </Styled.OnboardingMain>
      <Styled.OnboardingActions>
        <div>{!isFirstStep && <Button variant="outlined" handleClick={() => setActiveStep(prevState => prevState - 1)}>Back</Button>}</div>
        <Button handleClick={handleBtn}>{getBtnContent()}</Button>
      </Styled.OnboardingActions>
    </Styled.Onboarding>
  );
};

OnboardingGallery.propTypes = {
  steps: PropTypes.array,
  onFinishConfirm: PropTypes.func.isRequired,
  finishBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
};

OnboardingGallery.defaultProps = {
  steps: null,
};

export default OnboardingGallery;
