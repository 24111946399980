import PropTypes from 'prop-types';
import { Selector } from 'shared/components';
import { documentTypes } from 'shared/constants';

const DocumentTypeSelector = ({
  onSelect,
  selected,
}) => (
  <div>
    <Selector
      id="type-input"
      name="type"
      label="Document type"
      placeholder="Document type"
      options={documentTypes}
      onChange={(val) => onSelect(val)}
      values={selected}
      labelField="label"
      valueField="value"
    />
  </div>
);

DocumentTypeSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.array,
};

DocumentTypeSelector.defaultProps = {
  selected: [],
};

export default DocumentTypeSelector;
