import PropTypes from 'prop-types';
import * as Styled from './styles';

const Notice = ({
  title,
  content,
  type,
}) => (
  <Styled.Notice className="Notice" type={type}>
    {title && <Styled.Title className="Title">{title}</Styled.Title>}
    {content && <Styled.Content className="Content">{content}</Styled.Content>}
  </Styled.Notice>
);

Notice.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['default', 'error', 'success']),
};

Notice.defaultProps = {
  title: '',
  content: '',
  type: 'default',
};

export default Notice;
