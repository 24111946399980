import PropTypes from 'prop-types';

const IconSurvey = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 10 14" fill="none" {...rest} className="IconSurvey">
    <path
      d="M4.2 0.599998C3.75817 0.599998 3.4 0.958171 3.4 1.4C3.4 1.84183 3.75817 2.2 4.2 2.2H5.8C6.24182 2.2 6.6 1.84183 6.6 1.4C6.6 0.958171 6.24182 0.599998 5.8 0.599998H4.2Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.199997 3C0.199997 2.11634 0.916341 1.4 1.8 1.4C1.8 2.72548 2.87451 3.8 4.2 3.8H5.8C7.12548 3.8 8.2 2.72548 8.2 1.4C9.08365 1.4 9.8 2.11634 9.8 3V11.8C9.8 12.6837 9.08365 13.4 8.2 13.4H1.8C0.916342 13.4 0.199997 12.6837 0.199997 11.8V3ZM2.6 6.2C2.15817 6.2 1.8 6.55817 1.8 7C1.8 7.44183 2.15817 7.8 2.6 7.8H2.608C3.04982 7.8 3.408 7.44183 3.408 7C3.408 6.55817 3.04982 6.2 2.608 6.2H2.6ZM5 6.2C4.55817 6.2 4.2 6.55817 4.2 7C4.2 7.44183 4.55817 7.8 5 7.8H7.4C7.84182 7.8 8.2 7.44183 8.2 7C8.2 6.55817 7.84182 6.2 7.4 6.2H5ZM2.6 9.4C2.15817 9.4 1.8 9.75817 1.8 10.2C1.8 10.6418 2.15817 11 2.6 11H2.608C3.04982 11 3.408 10.6418 3.408 10.2C3.408 9.75817 3.04982 9.4 2.608 9.4H2.6ZM5 9.4C4.55817 9.4 4.2 9.75817 4.2 10.2C4.2 10.6418 4.55817 11 5 11H7.4C7.84182 11 8.2 10.6418 8.2 10.2C8.2 9.75817 7.84182 9.4 7.4 9.4H5Z"
      fill={color}
    />
  </svg>
);

IconSurvey.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconSurvey.defaultProps = {
  color: '#A19E95',
  height: 14,
  width: 10,
};

export default IconSurvey;
