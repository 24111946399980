import PropTypes from 'prop-types';

const IconDownload = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 19" fill="none" {...rest} className="IconDownload">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 14.5C2 15.6046 2.89543 16.5 4 16.5L14 16.5C15.1046 16.5 16 15.6046 16 14.5L16 13.5C16 12.9477 16.4477 12.5 17 12.5C17.5523 12.5 18 12.9477 18 13.5L18 14.5C18 16.7091 16.2091 18.5 14 18.5L4 18.5C1.79086 18.5 -7.8281e-08 16.7091 -1.74846e-07 14.5L-2.18557e-07 13.5C-2.42698e-07 12.9477 0.447715 12.5 1 12.5C1.55228 12.5 2 12.9477 2 13.5L2 14.5ZM4.29289 8.79289C4.68342 8.40237 5.31658 8.40237 5.70711 8.79289L8 11.0858L8 1.5C8 0.947715 8.44771 0.5 9 0.5C9.55228 0.5 10 0.947715 10 1.5L10 11.0858L12.2929 8.79289C12.6834 8.40237 13.3166 8.40237 13.7071 8.79289C14.0976 9.18342 14.0976 9.81658 13.7071 10.2071L9.70711 14.2071C9.31658 14.5976 8.68342 14.5976 8.29289 14.2071L4.29289 10.2071C3.90237 9.81658 3.90237 9.18342 4.29289 8.79289Z"
      fill={color}
    />
  </svg>
);

IconDownload.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconDownload.defaultProps = {
  color: '#012934',
  height: 19,
  width: 18,
};

export default IconDownload;
