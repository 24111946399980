import styled from 'styled-components';

export const OfferingImagesUpload = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: scroll;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 450px;
  max-width: 450px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  button {
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.colors.shades.neutral.white};

    &:hover {
      background-color: ${props => props.theme.colors.shades.neutral.white};
    }
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 16px;

  .TextInput,
  .TextArea,
  .Selector {
    max-width: 100%;
  }

  .react-dropdown-select {
    max-width: 100% !important;

    .react-dropdown-select-content {
      max-width: 100%;
    }

    .react-dropdown-select-dropdown {
      max-width: 100%;
    }
  }

  .FileUploader {
    .dzu-dropzone {
      min-height: 300px;
    }

    .dzu-submitButtonContainer {
      display: none !important;
    }
  }
`;

export const FileUploaderLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
  margin-bottom: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 100%;
  }
`;
