import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import {
  Button,
  Selector,
} from 'shared/components';
import api from 'shared/api';
import { getUserRoles, getErrMsg } from 'shared/helpers';
import { IconUserAddFill, IconClose } from 'shared/icons';
import SelectUserRole from '../SelectUserRole';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    paddingTop: '40px',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const AddDealroomMember = ({
  isOpen,
  closeCb,
  refetchMembers,
  dealRoom,
}) => {
  const dealRoomID = get(dealRoom, 'id');
  const [usersSearch, setUsersSearch] = useState('');
  const [usersLoading, setUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserRoles, setSelectedUserRoles] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const addMember = useCallback((postData) => {
    api.post(`/api/investing/deal-rooms/${dealRoomID}/members`, postData)
      .then(() => {
        toast.success('Offering member added succesfully');
        setLoading(false);
        refetchMembers();
        closeCb();
      })
      .catch((err) => {
        const errMsg = getErrMsg(err);
        toast.error(errMsg || 'Error occured');
        setLoading(false);
      });
  }, [dealRoomID]);

  const addMemberWithSelectedRole = useCallback((selectedRole) => {
    const data = {
      ...formData,
      user_role_id: get(selectedRole, 'id'),
    };
    addMember(data);
  }, [addMember]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }
    if (!selectedUser || !selectedUser.length) {
      toast.error('Please select user');
      return false;
    }

    setLoading(true);
    const data = {};

    const roles = getUserRoles(get(selectedUser, '[0]'), true);
    if (!roles.length) {
      toast.error('Selected user has no approved roles');
      setLoading(false);
      return false;
    }
    if (roles.length === 1) {
      const postData = {
        ...data,
        user_role_id: get(roles, '[0].id'),
      };
      addMember(postData);
    } else {
      setFormData(data);
      const filteredRoles = roles.filter(r => r.value !== 'maker');
      if (filteredRoles.length === 1) {
        const postData = {
          ...data,
          user_role_id: get(filteredRoles, '[0].id'),
        };
        addMember(postData);
      } else {
        setSelectedUserRoles(filteredRoles);
      }
    }
    return true;
  };

  const getInitialUsers = useCallback(() => {
    api.get('/api/user-management/user-profiles?page=1&per_page=1000&app_name=client&excluded_statuses=external')
      .then((res) => {
        const list = get(res, 'data.records') || [];
        const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
        setUsers(mappedList);
        setUsersLoading(false);
      })
      .catch(() => setUsersLoading(false));
  }, []);

  useEffect(() => {
    getInitialUsers();
  }, [getInitialUsers]);

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="AddDealroomMemberModal"
    >
      <Styled.AddDealroomMember>
        <Styled.Header>
          <IconUserAddFill />
          <Styled.HeaderTitle>Add member</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Form onSubmit={onSubmit}>
          <Styled.InputWrapper>
            <Selector
              id="user-input"
              name="selected-user"
              label="Search user"
              options={users}
              onChange={(val) => {
                setSelectedUser(val);
              }}
              values={selectedUser}
              labelField="label"
              valueField="value"
              placeholder="Search..."
              searchable
              searchFn={({ state, methods }) => {
                if (state.search !== usersSearch) {
                  setUsersSearch(state.search);
                  api.get(`/api/user-management/user-profiles?page=1&full_name=${methods.safeString(state.search)}&per_page=1000&app_name=client&excluded_statuses=external`)
                    .then((res) => {
                      const list = get(res, 'data.records') || [];
                      const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
                      setUsers(mappedList);
                    })
                    .catch(() => []);
                }
              }}
              disabled={usersLoading}
            />
          </Styled.InputWrapper>
          <Styled.ButtonContainer>
            <Button type="submit" wide disabled={isLoading}>
              Save
            </Button>
          </Styled.ButtonContainer>
        </Styled.Form>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.AddDealroomMember>
      {!!selectedUserRoles && (
        <SelectUserRole
          isOpen={!!selectedUserRoles}
          closeCb={() => {
            setSelectedUserRoles(null);
            setLoading(false);
          }}
          confirmCb={(role) => addMemberWithSelectedRole(role)}
          user={get(selectedUser, '[0]')}
          userRoles={selectedUserRoles}
        />
      )}
    </ReactModal>
  );
};

AddDealroomMember.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchMembers: PropTypes.func.isRequired,
  dealRoom: PropTypes.object.isRequired,
};

export default AddDealroomMember;
