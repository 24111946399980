import styled from 'styled-components';

export const ProgressBar = styled.div`
  position: relative;
  height: ${props => props.height || '4px'};
  background-color: ${props => props.theme.colors.shades.warm.dawn20};
  width: 100%;
`;

export const FilledBar = styled.div`
  height: 100%;
  transition: width .2s ease-in;
  width: ${props => props.width || '1%'};
  background: ${props => props.color || props.theme.colors.success.success50}
`;
