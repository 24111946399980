import { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InitialPasswordReset from 'auth/InitialPasswordReset';
import InvitationDetails from 'auth/InvitationDetails';
import Dashboard from 'Dashboard';
import Kyc from 'Kyc';
import Profile from 'Profile';
import Support from 'Support';
import Yoc from 'Yoc';
import Organizations from 'Organizations';
import Offerings from 'Offerings';
import DealRooms from 'DealRooms';
import Notifications from 'Notifications';
import { NotFound, PageLoader } from 'shared/components';
import { useInterval } from 'shared/hooks';
import { getTopologyTypes } from 'redux/topology/actions';
import { refreshUnreadNotifications } from 'redux/notifications/actions';
import {
  getCountries,
  getCurrencies,
  getUserOrganizations,
  getUserOfferings,
  getUserInvestments,
} from 'redux/investing/actions';
import { getUserProfile } from 'redux/account/actions';
import api from 'shared/api';

const AppContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'account.user'));
  const invitation = useSelector(state => get(state, 'account.invitation'));
  const organizationsLoading = useSelector(state => get(state, 'investing.organizationsLoading'));
  const offeringsLoading = useSelector(state => get(state, 'investing.offeringsLoading'));
  const investmentsLoading = useSelector(state => get(state, 'investing.investmentsLoading'));
  const countriesLoading = useSelector(state => get(state, 'investing.countriesLoading'));
  const currenciesLoading = useSelector(state => get(state, 'investing.currenciesLoading'));
  const userProfileLoading = useSelector(state => get(state, 'account.userProfileLoading'));
  const userID = get(user, 'user_id');
  const isImported = get(user, 'status') === 'imported';

  const getNotifications = useCallback(() => {
    api.get(`/api/notifying/users/${userID}/notifications?page=1&per_page=10000&status=pending`)
      .then((res) => {
        dispatch(refreshUnreadNotifications(get(res, 'data.objects.records')));
      });
  }, [userID]);

  useEffect(() => {
    dispatch(getTopologyTypes());
    dispatch(getCurrencies());
    dispatch(getCountries());
    dispatch(getUserOrganizations(userID));
    dispatch(getUserOfferings(userID));
    dispatch(getUserInvestments(userID));
    dispatch(getUserProfile(userID));
  }, [dispatch]);

  useEffect(getNotifications, [getNotifications]);
  useInterval(getNotifications, 7000);

  if (offeringsLoading || investmentsLoading || organizationsLoading || countriesLoading || currenciesLoading || userProfileLoading) {
    return <PageLoader />;
  }

  if (isImported) {
    return (
      <InitialPasswordReset />
    );
  }

  if (invitation) {
    return (
      <InvitationDetails />
    );
  }

  return (
    <div className="AppContainer">
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/profile" component={Profile} />
        <Route path="/kyc" component={Kyc} />
        <Route path="/yoc" component={Yoc} />
        <Route path="/organizations" component={Organizations} />
        <Route path="/offerings" component={Offerings} />
        <Route path="/dealrooms" component={DealRooms} />
        <Route path="/notifications" component={Notifications} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AppContainer;
