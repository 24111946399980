import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { TextInput } from 'shared/components';
import { IconCalendar } from 'shared/icons';
import 'react-datepicker/dist/react-datepicker.css';
import * as Styled from './styles';

const DatePicker = ({
  value,
  error,
  handleChange,
  id,
  label,
  wide,
  placeholder,
}) => (
  <Styled.DatePickerWrapper className="DatePickerWrapper">
    <ReactDatePicker
      calendarClassName="DatePickerCalendar"
      className="DatePicker"
      selected={value ? new Date(value) : null}
      onChange={handleChange}
      placeholderText={placeholder}
      id={id}
      dateFormat="dd/MM/yyyy"
      customInput={(
        <TextInput
          label={label}
          id={id}
          wide={wide}
          trailingIcon={<IconCalendar />}
          value={value}
          error={error}
        />
      )}
    />
  </Styled.DatePickerWrapper>
);

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  wide: PropTypes.bool,
  value: PropTypes.object,
  handleChange: PropTypes.func,
};

DatePicker.defaultProps = {
  value: '',
  placeholder: '',
  handleChange: () => {},
  label: '',
  error: '',
  wide: false,
};

export default DatePicker;
