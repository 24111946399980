import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';

const KycCard = () => {
  const history = useHistory();

  return (
    <Styled.KycCard type="button" onClick={() => history.push('/kyc')}>
      <Styled.Title>Proof of identity</Styled.Title>
      <Styled.Text>Complete the mandatory identity verification module to be able to use all the app features.</Styled.Text>
    </Styled.KycCard>
  );
};

export default KycCard;
