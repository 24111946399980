import PropTypes from 'prop-types';

const IconSecurity = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 20" fill="none" {...rest} className="IconSecurity">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5V8H15C16.6569 8 18 9.34315 18 11V17C18 18.6569 16.6569 20 15 20H3C1.34315 20 0 18.6569 0 17V11C0 9.34315 1.34315 8 3 8H4V5ZM3 10C2.44772 10 2 10.4477 2 11V17C2 17.5523 2.44772 18 3 18H15C15.5523 18 16 17.5523 16 17V11C16 10.4477 15.5523 10 15 10H3ZM9 12C9.55229 12 10 12.4477 10 13V15C10 15.5523 9.55229 16 9 16C8.44772 16 8 15.5523 8 15V13C8 12.4477 8.44772 12 9 12Z"
      fill={color}
    />
  </svg>
);

IconSecurity.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconSecurity.defaultProps = {
  color: '#012934',
  height: 20,
  width: 18,
};

export default IconSecurity;
