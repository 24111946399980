import React from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Styled from './styles';

const NotificationsCard = () => {
  const history = useHistory();
  const unread = useSelector(state => get(state, 'notifications.unreadNotifications'));
  const unreadCount = unread.length;

  // if (!unreadCount) {
  //   return null;
  // }

  return (
    <Styled.NotificationsCard type="button" onClick={() => history.push('/notifications')}>
      <Styled.Title>Notifications</Styled.Title>
      <Styled.Text>{`You have ${unreadCount} pending notifications.`}</Styled.Text>
    </Styled.NotificationsCard>
  );
};

export default NotificationsCard;
