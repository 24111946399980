import styled from 'styled-components';

export const ListHeader = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.shades.warm.dawn05};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 12px 8px;
  }
`;

export const ListSearch = styled.form`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    /* TODO return after filters are done */
    /* width: 80%; */
    width: 100%;

    .TextInput {
      max-width: 100%;
    }
  }
`;

export const ListPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding-bottom: 60px;
`;

export const ListAction = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 100%;
  }
`;

export const ActionText = styled.div`
  margin-left: 10px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const AddBtn = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
  border: none;
  margin: 0 0 0 14px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 0 0 24px;
  }
`;

export const List = styled.div`
  margin-top: 8px;
  padding-bottom: 20px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 288px);

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  }
`;
