const config = {
  admin_app_url: 'https://admin.dev.factoro.com',
  client_app_url: 'https://app.dev.factoro.com',
  website_url: 'https://dev.factoro.com',
  // admin_app_url: 'https://admin.kobofunds.com',
  // client_app_url: 'https://app.kobofunds.com',
  // website_url: 'https://kobofunds.com',
};

export default config;
