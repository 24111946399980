/* eslint-disable func-names */
import * as actionTypes from './actionTypes';

const checkInitialSidebarSize = () => {
  const config = window.localStorage.getItem('lcs-sidebar-width');
  if (!config) { return 'lg'; }
  return config;
};

const handleSidebarSize = (sidebarWidth) => {
  window.localStorage.setItem('lcs-sidebar-width', sidebarWidth);
  return sidebarWidth;
};

const initialState = {
  sidebarSize: checkInitialSidebarSize(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.changeSidebarSize: {
      return { ...state, sidebarSize: handleSidebarSize(action.sidebarSize) };
    }
    default:
      return Object.assign(state);
  }
}
