import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { IconChevronLeft, IconChevronRight, IconTripleDots } from 'shared/icons';
import * as Styled from './styles';

const Pagination = ({
  page,
  pageCount,
  marginPagesDisplayed,
  pageRangeDisplayed,
  handlePageClick,
}) => (
  <Styled.Pagination>
    <ReactPaginate
      forcePage={page}
      previousLabel={<IconChevronLeft />}
      nextLabel={<IconChevronRight />}
      breakLabel={<IconTripleDots />}
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={handlePageClick}
      containerClassName="Pagination"
      activeClassName="Pagination-active"
      breakClassName="Pagination-break"
    />
  </Styled.Pagination>
);

Pagination.propTypes = {
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  page: PropTypes.number,
};

Pagination.defaultProps = {
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 3,
  page: undefined,
};

export default Pagination;
