import PropTypes from 'prop-types';

const IconClients = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconClients">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99998 1.66683C3.89541 1.66683 2.99998 2.56226 2.99998 3.66683C2.99998 4.7714 3.89541 5.66683 4.99998 5.66683C5.59432 5.66683 6.12812 5.40758 6.49444 4.99599C6.49788 4.992 6.50137 4.98805 6.50492 4.98413C6.81316 4.63227 6.99998 4.17137 6.99998 3.66683C6.99998 3.16228 6.81315 2.70137 6.5049 2.34951C6.50136 2.3456 6.49789 2.34166 6.49446 2.33769C6.12814 1.92609 5.59433 1.66683 4.99998 1.66683ZM7.00005 0.999969C6.44293 0.581479 5.75041 0.333496 4.99998 0.333496C3.15903 0.333496 1.66665 1.82588 1.66665 3.66683C1.66665 5.50778 3.15903 7.00016 4.99998 7.00016C5.75041 7.00016 6.44293 6.75218 7.00005 6.33369C7.55688 6.75178 8.24966 7.00016 8.99998 7.00016C10.8409 7.00016 12.3333 5.50778 12.3333 3.66683C12.3333 1.82588 10.8409 0.333496 8.99998 0.333496C8.24966 0.333496 7.55688 0.581881 7.00005 0.999969ZM7.88917 2.00328C8.17168 2.49286 8.33331 3.06097 8.33331 3.66683C8.33331 4.27269 8.17168 4.84079 7.88917 5.33038C8.20708 5.5432 8.58889 5.66683 8.99998 5.66683C10.1046 5.66683 11 4.7714 11 3.66683C11 2.56226 10.1046 1.66683 8.99998 1.66683C8.58889 1.66683 8.20708 1.79046 7.88917 2.00328ZM4.99998 9.00016C3.15903 9.00016 1.66665 10.4925 1.66665 12.3335H8.33331C8.33331 10.4925 6.84093 9.00016 4.99998 9.00016ZM9.66665 12.3335H12.3333C12.3333 10.4925 10.8409 9.00016 8.99998 9.00016C8.7712 9.00016 8.54808 9.02313 8.33266 9.06685C9.15784 9.9086 9.66665 11.0616 9.66665 12.3335ZM7.00017 8.11601C6.39395 7.82799 5.71579 7.66683 4.99998 7.66683C2.42265 7.66683 0.333313 9.75617 0.333313 12.3335V13.0002C0.333313 13.3684 0.63179 13.6668 0.99998 13.6668H13C13.3682 13.6668 13.6666 13.3684 13.6666 13.0002V12.3335C13.6666 9.75617 11.5773 7.66683 8.99998 7.66683C8.28531 7.66683 7.60684 7.82791 7.00017 8.11601Z"
      fill={color}
    />
  </svg>
);

IconClients.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconClients.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconClients;
