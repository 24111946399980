/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.shades.neutral.white};
`;

export const Table = styled.table`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  border-collapse: collapse;
`;

export const Thead = styled.thead`
`;

export const TrHead = styled.tr`
  text-align: left;
`;

export const Th = styled.th`
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};
  min-height: 48px;
  padding: 12px 16px;
  color: ${props => props.theme.colors.shades.warm.dawn60};
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  word-break: break-all;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    min-height: 32px;
  }

  &.centered {
    justify-content: center;
  }
`;

export const ThSortIndicator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const Tbody = styled.tbody`

`;

export const TrBody = styled.tr`

  &:last-of-type {
    td {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: ${props => props.theme.colors.shades.warm.dawn05};
  }

  ${props => {
    if (props.isClickable) {
      return css`
        cursor: pointer;
      `;
    }
  }}
`;

export const Td = styled.td`
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};
  min-height: 64px;
  padding: 12px 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  line-height: 24px;
  font-weight: normal;
  word-break: break-all;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
    min-height: 48px;
  }

  &.notification {
    padding: 12px 4px;
    flex-shrink: 0;
  }
`;

export const NoData = styled.tbody`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  min-height: 100px;
  color: ${props => props.theme.colors.shades.warm.dawn80};
`;
