import PropTypes from 'prop-types';

const IconIdentification = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 34 34" fill="none" {...rest} className="IconIdentification">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.33333C12 2.5719 14.2386 0.333328 17 0.333328C19.7614 0.333328 22 2.5719 22 5.33333H28.6667C31.4281 5.33333 33.6667 7.5719 33.6667 10.3333V25.3333C33.6667 28.0947 31.4281 30.3333 28.6667 30.3333H5.33334C2.57191 30.3333 0.333336 28.0947 0.333336 25.3333V10.3333C0.333336 7.5719 2.57191 5.33333 5.33334 5.33333H12ZM12.2845 8.66666H5.33334C4.41286 8.66666 3.66667 9.41285 3.66667 10.3333V25.3333C3.66667 26.2538 4.41286 27 5.33334 27H28.6667C29.5871 27 30.3333 26.2538 30.3333 25.3333V10.3333C30.3333 9.41285 29.5871 8.66666 28.6667 8.66666H21.7155C21.0291 10.6086 19.177 12 17 12C14.823 12 12.9709 10.6086 12.2845 8.66666ZM17 3.66666C16.0795 3.66666 15.3333 4.41285 15.3333 5.33333V6.99999C15.3333 7.92047 16.0795 8.66666 17 8.66666C17.9205 8.66666 18.6667 7.92047 18.6667 6.99999V5.33333C18.6667 4.41285 17.9205 3.66666 17 3.66666ZM12 15.3333C11.0795 15.3333 10.3333 16.0795 10.3333 17C10.3333 17.9205 11.0795 18.6667 12 18.6667C12.9205 18.6667 13.6667 17.9205 13.6667 17C13.6667 16.0795 12.9205 15.3333 12 15.3333ZM16.0004 20C16.6281 19.1643 17 18.1256 17 17C17 14.2386 14.7614 12 12 12C9.23858 12 7 14.2386 7 17C7 18.1256 7.37194 19.1643 7.99961 20C6.95883 20.782 6.15475 21.8615 5.71303 23.1112C5.40628 23.9791 5.86116 24.9313 6.72902 25.2381C7.59688 25.5448 8.54908 25.0899 8.85583 24.2221C9.31421 22.9252 10.5512 22 12 22C13.4487 22 14.6856 22.9251 15.144 24.2221C15.4508 25.0899 16.403 25.5448 17.2708 25.2381C18.1387 24.9313 18.5936 23.9791 18.2868 23.1112C17.8451 21.8616 17.0411 20.7821 16.0004 20ZM20.3333 15.3333C20.3333 14.4129 21.0795 13.6667 22 13.6667H27C27.9205 13.6667 28.6667 14.4129 28.6667 15.3333C28.6667 16.2538 27.9205 17 27 17H22C21.0795 17 20.3333 16.2538 20.3333 15.3333ZM20.3333 22C20.3333 21.0795 21.0795 20.3333 22 20.3333H25.3333C26.2538 20.3333 27 21.0795 27 22C27 22.9205 26.2538 23.6667 25.3333 23.6667H22C21.0795 23.6667 20.3333 22.9205 20.3333 22Z"
      fill={color}
    />
  </svg>
);

IconIdentification.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconIdentification.defaultProps = {
  color: '#34545D',
  height: 34,
  width: 34,
};

export default IconIdentification;
