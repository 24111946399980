import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Button, RadioBtn } from 'shared/components';
import { IconFilter, IconClose } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const SearchTypeForm = ({
  isOpen,
  activeType,
  closeCb,
  confirmCb,
}) => {
  const [selectedType, setSelectedType] = useState(activeType);

  const handleSubmit = () => {
    confirmCb(selectedType);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="SearchTypeFormModal"
    >
      <Styled.SearchTypeForm>
        <Styled.Header>
          <IconFilter />
          <Styled.HeaderTitle>Set search type</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Types>
          <RadioBtn
            id="full_name"
            name="search_type"
            value="full_name"
            label="Search by name"
            onChange={() => setSelectedType('full_name')}
            checked={selectedType === 'full_name'}
          />
          <RadioBtn
            id="user_name"
            name="search_type"
            value="user_name"
            label="Search by email"
            onChange={() => setSelectedType('user_name')}
            checked={selectedType === 'user_name'}
          />
        </Styled.Types>
        <Button
          wide
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={() => closeCb()}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.SearchTypeForm>
    </ReactModal>
  );
};

SearchTypeForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  activeType: PropTypes.string.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
};

export default SearchTypeForm;
