import PropTypes from 'prop-types';

const IconSend = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest} className="IconSend">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C10.3788 0 10.725 0.214002 10.8944 0.552786L19.8944 18.5528C20.067 18.8978 20.0256 19.3113 19.7882 19.6154C19.5508 19.9195 19.1597 20.0599 18.7831 19.9762L10 18.0244L1.21693 19.9762C0.840344 20.0599 0.449204 19.9195 0.211794 19.6154C-0.0256151 19.3113 -0.0669503 18.8978 0.105573 18.5528L9.10557 0.552786C9.27497 0.214002 9.62123 0 10 0ZM11 16.1978L17.166 17.568L10 3.23607L2.83401 17.568L9 16.1978V9C9 8.44772 9.44772 8 10 8C10.5523 8 11 8.44772 11 9V16.1978Z"
      fill={color}
    />
  </svg>
);

IconSend.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconSend.defaultProps = {
  color: '#fff',
  height: 20,
  width: 20,
};

export default IconSend;
