import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReactModal from 'react-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  DatePicker,
  TextInput,
  TextArea,
  Selector,
} from 'shared/components';
import api from 'shared/api';
import { mapCountriesToSelector } from 'shared/helpers';
import { IconClose } from 'shared/icons';
import { getUserOrganizations } from 'redux/investing/actions';
import OrganizationLogoUpload from '../OrganizationLogoUpload';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const validationSchema = yup.object().shape({
  legal_name: yup
    .string()
    .required(),
  name: yup
    .string()
    .required(),
  incorporation_date: yup
    .string()
    .required(),
});

const OrganizationForm = ({
  isOpen,
  closeCb,
  organization,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const countries = useSelector(state => get(state, 'investing.countries'));
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const countriesOptions = mapCountriesToSelector(countries);
  const organizationResidence = countriesOptions.find(c => get(c, 'data.id') === get(organization, 'country_of_residence'));
  const initialResidence = !organizationResidence ? [] : [organizationResidence];
  const organizationIncorporation = countriesOptions.find(c => get(c, 'data.id') === get(organization, 'incorporation_country'));
  const initialIncorporation = !organizationIncorporation ? [] : [organizationIncorporation];

  const [isLoading, setLoading] = useState(false);
  const [countryOfResidence, setCountryOfResidence] = useState(initialResidence);
  const [countryOfResidenceError, setCountryOfResidenceError] = useState('');
  const [countryOfIncorporation, setCountryOfIncorporation] = useState(initialIncorporation);
  const [countryOfIncorporationError, setCountryOfIncorporationError] = useState('');

  const [selectedLogo, setSelectedLogo] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dirtyForm: '',
      legal_name: get(organization, 'legal_name') || '',
      name: get(organization, 'name') || '',
      address: get(organization, 'address') || '',
      bio: get(organization, 'bio') || '',
      email: get(organization, 'email') || '',
      phone: get(organization, 'phone') || '',
      website: get(organization, 'website') || '',
      linkedin_url: get(organization, 'linkedin_url') || '',
      facebook_url: get(organization, 'facebook_url') || '',
      twitter_url: get(organization, 'twitter_url') || '',
      incorporation_date: get(organization, 'incorporation_date') || '',
      registration_nr: get(organization, 'registration_nr') || '',
    },
  });

  const addLogo = (organizationID) => {
    if (!selectedLogo) {
      toast.success('Organization created succesfully');
      setLoading(false);
      dispatch(getUserOrganizations(userID));
      history.push(`/organizations/${organizationID}`);
    } else {
      const formData = new FormData();
      formData.append(
        'image',
        selectedLogo,
        get(selectedLogo, 'name') || 'logo',
      );
      formData.append('type', 'logo');

      api.post(`/api/user-management/business-entities/${organizationID}/branding`, formData)
        .then(() => {
          toast.success('Organization created succesfully');
          setLoading(false);
          dispatch(getUserOrganizations(userID));
          history.push(`/organizations/${organizationID}`);
        })
        .catch(() => {
          dispatch(getUserOrganizations(userID));
          history.push(`/organizations/${organizationID}`);
        });
    }
  };

  const createOrganization = (data) => {
    api.post('/api/user-management/business-entities', data)
      .then((res) => {
        const resData = get(res, 'data');
        const ID = get(resData, 'id');
        addLogo(ID);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const updateOrganization = (data) => {
    const organizationID = get(organization, 'id');
    api.patch(`/api/user-management/business-entities/${organizationID}`, data)
      .then(() => {
        toast.success('Organization updated succesfully');
        setLoading(false);
        dispatch(getUserOrganizations(userID));
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const validateCountries = () => {
    let isValid = true;
    if (!countryOfResidence || !countryOfResidence.length) {
      setCountryOfResidenceError('This field is required.');
      isValid = false;
    }

    if (!countryOfIncorporation || !countryOfIncorporation.length) {
      setCountryOfIncorporationError('This field is required.');
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = (data) => {
    if (!isDirty || isLoading) {
      return false;
    }

    const areCountriesValid = validateCountries();
    if (!areCountriesValid) {
      return false;
    }

    setLoading(true);
    const date = moment(get(data, 'incorporation_date')).format();
    const postData = {
      name: get(data, 'name'),
      legal_name: get(data, 'legal_name'),
      address: get(data, 'address'),
      bio: get(data, 'bio'),
      email: get(data, 'email'),
      phone: get(data, 'phone'),
      website: get(data, 'website'),
      linkedin_url: get(data, 'linkedin_url'),
      facebook_url: get(data, 'facebook_url'),
      twitter_url: get(data, 'twitter_url'),
      registration_nr: get(data, 'registration_nr') || '',
      country_of_residence_iso3: get(countryOfResidence, '[0].value'),
      incorporation_country_iso3: get(countryOfIncorporation, '[0].value'),
      incorporation_date: date,
    };

    if (!organization) {
      createOrganization(postData);
    } else {
      updateOrganization(postData);
    }
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="OrganizationFormModal"
    >
      <Styled.OrganizationForm>
        <Styled.Main>
          <Styled.Form>
            <Styled.Aside>
              <Styled.LogoContainer>
                <OrganizationLogoUpload
                  organization={{}}
                  handleFileChange={val => setSelectedLogo(val)}
                  showBtn={false}
                  showPreview={false}
                />
              </Styled.LogoContainer>
              <Styled.InputContainer>
                <Controller
                  name="legal_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="legal_name"
                      label="Legal name *"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.legal_name?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="name"
                      label="Display name *"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.name?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Button
                size="small"
                type="submit"
                handleClick={() => handleSubmit(onSubmit)()}
              >
                Save
              </Button>
            </Styled.Aside>
            <Styled.Data>
              <Styled.SectionLabel>
                Overview
              </Styled.SectionLabel>
              <Styled.InputContainer>
                <Controller
                  name="bio"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextArea
                      id="bio"
                      label="Description"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.bio?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="address"
                      label="Address"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.address?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="registration_nr"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="registration_nr"
                      label="Business registration number"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.registration_nr?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Selector
                  id="residence-country-input"
                  name="country_of_residence"
                  label="Country of residence *"
                  placeholder="Select country of residence"
                  options={countriesOptions}
                  onChange={(val) => {
                    setValue('dirtyForm', 'true', { shouldDirty: true });
                    setCountryOfResidence(val);
                    setCountryOfResidenceError('');
                  }}
                  values={countryOfResidence}
                  labelField="label"
                  valueField="value"
                  searchable
                  error={countryOfResidenceError}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Selector
                  id="incorporation-country-input"
                  name="country_of_incorporation"
                  label="Country of incorporation *"
                  placeholder="Select country of incorporation"
                  options={countriesOptions}
                  onChange={(val) => {
                    setValue('dirtyForm', 'true', { shouldDirty: true });
                    setCountryOfIncorporation(val);
                    setCountryOfIncorporationError('');
                  }}
                  values={countryOfIncorporation}
                  labelField="label"
                  valueField="value"
                  searchable
                  error={countryOfIncorporationError}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="incorporation_date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <DatePicker
                      id="incorporation_date"
                      name="incorporation_date"
                      label="Date of incorporation *"
                      placeholder="aaaa/mm/dd"
                      wide
                      disabled={isLoading}
                      handleChange={(date) => field.onChange(date)}
                      value={field.value ? new Date(field.value) : null}
                      error={errors.incorporation_date?.message}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="email"
                      label="Email"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.email?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="phone"
                      label="Phone"
                      type="phone"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.phone?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="website"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="website"
                      label="Website"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.website?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="linkedin_url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="linkedin_url"
                      label="Linkedin"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.linkedin_url?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="facebook_url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="facebook_url"
                      label="Facebook"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.facebook_url?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="twitter_url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="twitter_url"
                      label="Twitter"
                      placeholder=""
                      wide
                      disabled={isLoading}
                      error={errors.twitter_url?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
            </Styled.Data>
          </Styled.Form>
        </Styled.Main>
      </Styled.OrganizationForm>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

OrganizationForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  organization: PropTypes.object,
};

OrganizationForm.defaultProps = {
  organization: null,
};

export default OrganizationForm;
