import { get } from 'lodash';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// organizations actions
const initOrganizations = (actions) => () => ({
  type: actions.getOrganizationsInit,
});

const getOrganizationsError = (actions) => () => ({
  type: actions.getOrganizationsError,
});

const getOrganizationsSuccess = (actions) => (res) => ({
  type: actions.getOrganizationsSuccess,
  organizations: res,
});

export const getUserOrganizations = (userID) => (dispatch) => {
  dispatch(initOrganizations(actionTypes)());
  return api.get(`/api/user-management/users/${userID}/business-entities`).then(
    (res) => dispatch(getOrganizationsSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getOrganizationsError(actionTypes)()),
  );
};

// offerings actions
const initOfferings = (actions) => () => ({
  type: actions.getOfferingsInit,
});

const getOfferingsError = (actions) => () => ({
  type: actions.getOfferingsError,
});

const getOfferingsSuccess = (actions) => (res) => ({
  type: actions.getOfferingsSuccess,
  offerings: res,
});

export const getUserOfferings = (userID) => (dispatch) => {
  dispatch(initOfferings(actionTypes)());
  return api.get(`/api/investing/users/${userID}/offerings`).then(
    (res) => dispatch(getOfferingsSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getOfferingsError(actionTypes)()),
  );
};

// investments actions
const initInvestments = (actions) => () => ({
  type: actions.getInvestmentsInit,
});

const getInvestmentsError = (actions) => () => ({
  type: actions.getInvestmentsError,
});

const getInvestmentsSuccess = (actions) => (res) => ({
  type: actions.getInvestmentsSuccess,
  investments: res,
});

export const getUserInvestments = (userID) => (dispatch) => {
  dispatch(initInvestments(actionTypes)());
  return api.get(`/api/investing/users/${userID}/investments`).then(
    (res) => dispatch(getInvestmentsSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getInvestmentsError(actionTypes)()),
  );
};

// countries actions
const initCountries = (actions) => () => ({
  type: actions.getCountriesInit,
});

const getCountriesError = (actions) => () => ({
  type: actions.getCountriesError,
});

const getCountriesSuccess = (actions) => (res) => ({
  type: actions.getCountriesSuccess,
  countries: res,
});

export const getCountries = () => (dispatch) => {
  dispatch(initCountries(actionTypes)());
  return api.get('/api/user-management/countries').then(
    (res) => dispatch(getCountriesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getCountriesError(actionTypes)()),
  );
};

// currencies actions
const initCurrencies = (actions) => () => ({
  type: actions.getCurrenciesInit,
});

const getCurrenciesError = (actions) => () => ({
  type: actions.getCurrenciesError,
});

const getCurrenciesSuccess = (actions) => (res) => ({
  type: actions.getCurrenciesSuccess,
  currencies: res,
});

export const getCurrencies = () => (dispatch) => {
  dispatch(initCurrencies(actionTypes)());
  return api.get('/api/user-management/currencies').then(
    (res) => dispatch(getCurrenciesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getCurrenciesError(actionTypes)()),
  );
};
