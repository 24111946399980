import styled from 'styled-components';

export const Retry = styled.div`

  .Button {
    background-color: transparent;

    .IconRetry {
      flex-shrink: 0;
    }
  }
`;

export const TakeImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Button {
    border-radius: 50%;
    height: 48px;
    width: 48px;

    .IconCamera {
      flex-shrink: 0;
    }
  }
`;
