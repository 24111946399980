import PropTypes from 'prop-types';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import { IconSurvey, IconCheckmark } from 'shared/icons';
import * as Styled from './styles';

const SurveyCard = ({
  title,
  description,
  numOfQuestions,
  onClick,
  survey,
}) => {
  const questionLabel = numOfQuestions === 1 ? 'question' : 'questions';
  const isFinished = get(survey, 'user_questionnaire.finished');

  const handleClick = () => {
    if (isFinished) {
      return toast.error('This survey has been completed already');
    }
    return onClick();
  };

  return (
    <Styled.SurveyCard type="button" disabled={isFinished} onClick={handleClick}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      <Styled.MetaWrapper>
        <Styled.Questions>
          <IconSurvey />
          <Styled.Label>{`${numOfQuestions} ${questionLabel}`}</Styled.Label>
        </Styled.Questions>
        {isFinished && (
          <Styled.Finished>
            <IconCheckmark color="#A19E95" />
            <Styled.Label className="finished">Finished</Styled.Label>
          </Styled.Finished>
        )}
      </Styled.MetaWrapper>
    </Styled.SurveyCard>
  );
};

SurveyCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  numOfQuestions: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
};

SurveyCard.defaultProps = {
  title: '',
  description: '',
  numOfQuestions: 1,
};

export default SurveyCard;
