import PropTypes from 'prop-types';
import { Button, Page } from 'shared/components';
import { IconLock } from 'shared/icons';
import * as Styled from './styles';

const NotAuthorized = ({
  title,
  text,
  btnText,
  onBtnClick,
}) => (
  <Page type="not-auth" title="Not authorized">
    <Styled.NotAuth>
      <Styled.Container>
        <IconLock height={33} width={33} />
        {title && <Styled.NotAuthTitle>{title}</Styled.NotAuthTitle>}
        {text && <Styled.NotAuthText>{text}</Styled.NotAuthText>}
        {btnText && (
          <Button
            onClick={onBtnClick}
          >
            {btnText}
          </Button>
        )}
      </Styled.Container>
    </Styled.NotAuth>
  </Page>
);

NotAuthorized.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  onBtnClick: PropTypes.func,
};

NotAuthorized.defaultProps = {
  title: '', // You can’t access this page yet
  text: '', // Some app pages are not yet accessible to you beacause you have not verified your identity.
  btnText: '', // Verify your identity
  onBtnClick: () => {}, // history.push('/kyc');
};

export default NotAuthorized;
