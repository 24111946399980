import styled from 'styled-components';

export const NotAuth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    background-color: ${props => props.theme.colors.shades.neutral.white};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  max-width: 360px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    border: none;
  }

  .IconLock {
    margin-bottom: 34px;
  }
`;

export const NotAuthTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-bottom: 8px;
`;

export const NotAuthText = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-bottom: 32px;
`;
