// Fetching organizations
export const getOrganizationsInit = 'GET_ORGANIZATIONS_INIT';
export const getOrganizationsSuccess = 'GET_ORGANIZATIONS_SUCCESS';
export const getOrganizationsError = 'GET_ORGANIZATIONS_ERROR';

// Fetching offerings
export const getOfferingsInit = 'GET_OFFERINGS_INIT';
export const getOfferingsSuccess = 'GET_OFFERINGS_SUCCESS';
export const getOfferingsError = 'GET_OFFERINGS_ERROR';

// Fetching investments
export const getInvestmentsInit = 'GET_INVESTMENTS_INIT';
export const getInvestmentsSuccess = 'GET_INVESTMENTS_SUCCESS';
export const getInvestmentsError = 'GET_INVESTMENTS_ERROR';

// Fetching countries
export const getCountriesInit = 'GET_COUNTRIES__INIT';
export const getCountriesSuccess = 'GET_COUNTRIES__SUCCESS';
export const getCountriesError = 'GET_COUNTRIES__ERROR';

// Fetching currencies
export const getCurrenciesInit = 'GET_CURRENCIES_INIT';
export const getCurrenciesSuccess = 'GET_CURRENCIES_SUCCESS';
export const getCurrenciesError = 'GET_CURRENCIES_ERROR';
