import PropTypes from 'prop-types';
import { cameraTypes } from 'shared/constants';

const CameraTitle = ({
  activeStep,
  cameraType,
  type,
  numOfSteps,
}) => {
  const isSelfie = cameraType === cameraTypes.selfie;
  const isFront = cameraType === cameraTypes.front;
  const isBack = cameraType === cameraTypes.back;

  const uploadType = type.split('_').join(' ');

  if (isSelfie) {
    return `${activeStep}/${numOfSteps}: Upload selfie`;
  }

  if (isFront) {
    return `${activeStep}/${numOfSteps}: Upload ${uploadType.toLowerCase()} front side`;
  }

  if (isBack) {
    return `${activeStep}/${numOfSteps}: Upload ${uploadType.toLowerCase()} back side`;
  }

  return null;
};

CameraTitle.propTypes = {
  activeStep: PropTypes.number.isRequired,
  cameraType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  numOfSteps: PropTypes.number.isRequired,
};

export default CameraTitle;
