import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { Button, Loader } from 'shared/components';
import { OrganizationCard } from 'Organizations/components';
import api from 'shared/api';
import * as Styled from './styles';

const OrganizationMemberNotification = ({
  organization,
  // eslint-disable-next-line no-unused-vars
  notification,
}) => {
  const history = useHistory();
  const organizationID = get(organization, 'BusinessEntity.id') || '';
  const organizationName = get(organization, 'BusinessEntity.name') || '';

  const [isLoading, setLoading] = useState(true);
  const [organizationData, setOrganizationData] = useState(null);

  const getOrganization = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}`)
      .then((res) => {
        setOrganizationData(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [organizationID]);

  useEffect(() => {
    getOrganization();
  }, [getOrganization]);

  if (isLoading) {
    return (
      <Styled.OrganizationMemberNotification>
        <Loader color="#616E7F" width={20} height={20} />
      </Styled.OrganizationMemberNotification>
    );
  }

  return (
    <Styled.OrganizationMemberNotification>
      <Styled.Text>{`You have been added as a member of the ${organizationName}.`}</Styled.Text>
      <Styled.Organization>
        <span>Organization:</span>
        <OrganizationCard organization={organizationData} onClick={() => history.push(`/organizations/${organizationID}`)} />
      </Styled.Organization>
      <Button
        size="large"
        handleClick={() => history.push(`/organizations/${organizationID}`)}
      >
        Go to organization page
      </Button>
    </Styled.OrganizationMemberNotification>
  );
};

OrganizationMemberNotification.propTypes = {
  organization: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
};

export default OrganizationMemberNotification;
