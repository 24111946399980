import PropTypes from 'prop-types';
import * as Styled from './styles';

const Banner = ({
  title,
  text,
}) => (
  <Styled.Banner>
    {title && <Styled.BannerTitle>{title}</Styled.BannerTitle>}
    {text && <Styled.BannerText>{text}</Styled.BannerText>}
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

Banner.defaultProps = {
  title: '',
  text: '',
};

export default Banner;
