import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { IconAlert } from 'shared/icons';
import { Button } from 'shared/components';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const Alert = ({
  isOpen,
  title,
  text,
  hasPrimaryBtn,
  primaryBtnText,
  onPrimaryBtnClick,
  secondaryBtnText,
  onSecondaryBtnClick,
}) => (
  <ReactModal
    isOpen={isOpen}
    style={styles}
    onRequestClose={() => {}}
    shouldCloseOnOverlayClick={false}
    closeTimeoutMS={150}
    className="AlertModal"
  >
    <Styled.Alert>
      <Styled.AlertHeader>
        <IconAlert />
        <Styled.AlertTitle>{title}</Styled.AlertTitle>
      </Styled.AlertHeader>
      <Styled.AlertMain>
        {text ? (
          <Styled.AlertText>{text}</Styled.AlertText>
        ) : null}
        <Styled.AlertActions>
          {hasPrimaryBtn && (
            <Button wide handleClick={onPrimaryBtnClick}>
              {primaryBtnText}
            </Button>
          )}
          {(secondaryBtnText && onSecondaryBtnClick) ? (
            <Button wide variant="outlined" handleClick={onSecondaryBtnClick}>
              {secondaryBtnText}
            </Button>
          ) : null}
        </Styled.AlertActions>
      </Styled.AlertMain>
    </Styled.Alert>
  </ReactModal>
);

Alert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  hasPrimaryBtn: PropTypes.bool,
  primaryBtnText: PropTypes.string.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  secondaryBtnText: PropTypes.string,
  onSecondaryBtnClick: PropTypes.func,
};

Alert.defaultProps = {
  title: 'Alert',
  text: '',
  hasPrimaryBtn: true,
  secondaryBtnText: null,
  onSecondaryBtnClick: null,
};

export default Alert;
