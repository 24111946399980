import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  AddEntityBtn,
  Loader,
  TextInput,
} from 'shared/components';
import {
  IconSearch,
  IconPlus,
} from 'shared/icons';
import { checkKycStatus } from 'shared/helpers';
import { OfferingCard } from '../../components';
import * as Styled from './styles';

const MyOfferings = ({
  onAddBtnClick,
  user,
  isAllowedToCreate,
}) => {
  const history = useHistory();
  const kYCStatus = checkKycStatus(get(user, 'status'));

  const offerings = useSelector(state => get(state, 'investing.offerings'));
  const offeringsLoading = useSelector(state => get(state, 'investing.offeringsLoading'));

  const [list, setList] = useState(offerings);

  const onSearchSubmit = (val) => {
    const { search } = val;
    if (!search) {
      return setList(offerings);
    }
    const filteredList = offerings.filter((i) => {
      const name = i.offering.name.toLowerCase();
      const doesInclude = name.includes(search);
      return doesInclude;
    });
    return setList(filteredList);
  };

  const {
    control,
    handleSubmit,
  } = useForm();

  if (offeringsLoading) {
    return <Loader color="#616E7F" />;
  }

  return (
    <>
      <Styled.ListHeader>
        <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
          <Controller
            name="search"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                id="offerings-search"
                name="search"
                placeholder="Search by name"
                size="small"
                button={<IconSearch />}
                onBtnClick={handleSubmit(onSearchSubmit)}
                {...field}
              />
            )}
          />
        </Styled.ListSearch>
        <Styled.ListAction>
          {isAllowedToCreate && (
            <Styled.AddBtn type="button" onClick={onAddBtnClick}>
              <IconPlus />
            </Styled.AddBtn>
          )}
        </Styled.ListAction>
      </Styled.ListHeader>
      {!offerings.length && isAllowedToCreate && (
        <AddEntityBtn
          text="Create your first offering"
          description={!kYCStatus.isValidated ? 'Complete the mandatory identity verification module to be able to use this feature.' : ''}
          onClick={onAddBtnClick}
        />
      )}
      {!offerings.length && !isAllowedToCreate && (
        <AddEntityBtn
          text="You didn't expressed interest or invested in any offering yet."
          description="Invest in deals vetted by our Venture Partners network"
          onClick={() => { }}
        />
      )}
      <Styled.List>
        {list.map(o => (
          <OfferingCard offering={o} handleCardClick={() => history.push(`/offerings/${get(o, 'offering.id')}/`)} />
        ))}
      </Styled.List>
    </>
  );
};

MyOfferings.propTypes = {
  onAddBtnClick: PropTypes.func,
  user: PropTypes.object.isRequired,
  isAllowedToCreate: PropTypes.bool.isRequired,
};

MyOfferings.defaultProps = {
  onAddBtnClick: () => { },
};

export default MyOfferings;
