import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Loader,
  TextInput,
  Table,
} from 'shared/components';
import { formatDate } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import { useWindowDimensions } from 'shared/HOCs';
import {
  IconSearch,
} from 'shared/icons';
import * as Styled from './styles';

const MyInvestments = () => {
  const { isMobile } = useWindowDimensions();
  const history = useHistory();

  const offerings = useSelector(state => get(state, 'investing.offerings'));
  const offeringsLoading = useSelector(state => get(state, 'investing.offeringsLoading'));
  const investments = useSelector(state => get(state, 'investing.investments'));
  const investmentsLoading = useSelector(state => get(state, 'investing.investmentsLoading'));
  const currencies = useSelector(state => get(state, 'investing.currencies'));

  const mappedList = investments.map((i) => {
    const offering = offerings.find(o => o.offering.id === i.offering_id);
    return { ...i, offering_name: get(offering, 'offering.name'), offering: get(offering, 'offering') };
  });

  const [list, setList] = useState(mappedList);

  const onSearchSubmit = (val) => {
    const { search } = val;
    if (!search) {
      return setList(mappedList);
    }
    const filteredList = mappedList.filter((i) => {
      const name = get(i, 'offering_name') || '';
      const lowercaseName = name.toLowerCase();
      const doesInclude = lowercaseName.includes(search);
      return doesInclude;
    });
    return setList(filteredList);
  };

  const {
    control,
    handleSubmit,
  } = useForm();

  const tableData = useMemo(() => list, [list]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Offering name',
      accessor: 'offering_id',
      Cell: (cell) => {
        const offering = offerings.find(o => get(o, 'offering.id') === cell.value);
        return get(offering, 'offering.name') || '';
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (cell) => {
        const amount = get(cell, 'value');
        const currencyID = get(cell, 'cell.row.original.currency_id');
        const currency = currencies.find(c => get(c, 'id') === currencyID);
        return `${amount} ${get(currency, 'symbol')}`;
      },
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: cell => formatDate(get(cell, 'value'), defaultDateFormat),
      show: !isMobile,
    },
  ], [list]);

  const handleRowClick = row => {
    history.push(`/offerings/${get(row, 'offering_id')}`);
  };

  if (investmentsLoading || offeringsLoading) {
    return <Loader color="#616E7F" />;
  }

  return (
    <>
      <Styled.ListHeader>
        <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
          <Controller
            name="search"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                id="investments-search"
                name="search"
                placeholder="Search by offering name"
                size="small"
                button={<IconSearch />}
                onBtnClick={handleSubmit(onSearchSubmit)}
                {...field}
              />
            )}
          />
        </Styled.ListSearch>
      </Styled.ListHeader>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onRowClick={handleRowClick}
        rowClassName="InvestmentListRow"
      />
    </>
  );
};

export default MyInvestments;
