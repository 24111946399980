import styled from 'styled-components';
import background from 'shared/assets/auth_background.png';

export const Login = styled.div`
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: ${props => props.theme.colors.primary.daintree.daintree05};
`;

export const LoginBackground = styled.div`
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-image: url(${background});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 450px;
  flex-shrink: 0;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }

  .TextInput {
    width: 100%;
    max-width: unset;

    &:hover {
      border-color: ${props => props.theme.colors.shades.warm.dawn30};
    }
  }
`;

export const LoginLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.primary.daintree.daintree40};
`;

export const LoginMain = styled.form`
  display: flex;
  flex-direction: column;
  margin: auto 48px 48px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xxs}) {
    margin: auto 20px 20px;
  }
`;

export const Heading = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin: 8px 0;
`;

export const Description = styled.p`
  line-height: 24px;
`;

export const Title = styled.h2`
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
  margin: 16px 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ForgotPassword = styled.div`
  display: flex;
  font-size: 13px;
  margin: 0 0 20px;

  a {
    color: ${props => props.theme.colors.primary.daintree.daintree05};
  }
`;
