import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  Selector,
} from 'shared/components';
import api from 'shared/api';
import { appName } from 'shared/constants';
import { getErrMsg } from 'shared/helpers';
import { IconUserAddFill, IconClose } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
});

const AddTeamMemberForm = ({
  isOpen,
  closeCb,
  refetchMembers,
  organization,
}) => {
  const organizationID = get(organization, 'id');
  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
    },
  });

  const addTeamMember = useCallback((postData) => {
    api.post(`/api/user-management/business-entities/${organizationID}/members`, postData)
      .then(() => {
        toast.success('Organization member added succesfully');
        setLoading(false);
        refetchMembers();
        closeCb();
      })
      .catch((err) => {
        const errMsg = getErrMsg(err);
        toast.error(errMsg || 'Error occured');
        setLoading(false);
      });
  }, [organizationID]);

  const onSubmit = (data) => {
    if (isLoading) {
      return false;
    }
    if (!selectedUser || !selectedUser.length) {
      toast.error('Please select user');
      return false;
    }

    setLoading(true);
    const postData = {
      user_id: get(selectedUser, '[0].user_id'),
      title: get(data, 'title'),
    };

    addTeamMember(postData);
    return true;
  };

  const getInitialUsers = useCallback(() => {
    api.get(`/api/user-management/user-profiles?page=1&per_page=10&app_name=${appName}&excluded_statuses=external`)
      .then((res) => {
        const list = get(res, 'data.records') || [];
        const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
        setUsers(mappedList);
      });
  }, []);

  useEffect(() => {
    getInitialUsers();
  }, [getInitialUsers]);

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="AddTeamMemberFormModal"
    >
      <Styled.AddTeamMemberForm>
        <Styled.Header>
          <IconUserAddFill />
          <Styled.HeaderTitle>Add team member</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.InputWrapper className="bulk-search-input">
            <Selector
              id="user-input"
              name="selected-user"
              label="Search user"
              options={users}
              onChange={(val) => {
                setSelectedUser(val);
              }}
              values={selectedUser}
              labelField="label"
              valueField="value"
              placeholder="Search..."
              searchable
              searchFn={({ state, methods }) => {
                if (state.search !== query) {
                  setQuery(state.search);
                  api.get(`/api/user-management/user-profiles?page=1&full_name=${methods.safeString(state.search)}&per_page=1000&app_name=${appName}&excluded_statuses=external`)
                    .then((res) => {
                      const list = get(res, 'data.records') || [];
                      const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
                      setUsers(mappedList);
                    })
                    .catch(() => []);
                }
              }}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Controller
              name="title"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="title-input"
                  label="Title"
                  placeholder="Enter title"
                  error={errors.title?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.ButtonContainer>
            <Button type="submit" wide disabled={isLoading}>
              Save
            </Button>
          </Styled.ButtonContainer>
        </Styled.Form>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.AddTeamMemberForm>
    </ReactModal>
  );
};

AddTeamMemberForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchMembers: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default AddTeamMemberForm;
