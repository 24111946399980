import PropTypes from 'prop-types';

const IconGlobe = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" {...rest} className="IconGlobe">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16592 14H8.05726C8.23567 10.9072 8.82455 8.05815 9.73288 5.76456C6.83503 7.5427 4.75026 10.517 4.16592 14ZM16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16 4C15.8479 4 15.5357 4.06345 15.0694 4.52368C14.5945 4.9924 14.075 5.76981 13.5956 6.88834C12.8183 8.70221 12.2498 11.1712 12.0647 14H19.9353C19.7502 11.1712 19.1817 8.70221 18.4044 6.88834C17.925 5.76981 17.4055 4.9924 16.9306 4.52368C16.4643 4.06345 16.1521 4 16 4ZM23.9427 14C23.7643 10.9072 23.1754 8.05815 22.2671 5.76456C25.165 7.54269 27.2497 10.517 27.8341 14H23.9427ZM19.9353 18H12.0647C12.2498 20.8288 12.8183 23.2978 13.5956 25.1117C14.075 26.2302 14.5945 27.0076 15.0694 27.4763C15.5357 27.9366 15.8479 28 16 28C16.1521 28 16.4643 27.9366 16.9306 27.4763C17.4055 27.0076 17.925 26.2302 18.4044 25.1117C19.1817 23.2978 19.7502 20.8288 19.9353 18ZM22.2671 26.2354C23.1754 23.9419 23.7643 21.0928 23.9427 18H27.8341C27.2497 21.483 25.165 24.4573 22.2671 26.2354ZM9.73288 26.2354C8.82456 23.9419 8.23567 21.0928 8.05726 18H4.16592C4.75026 21.483 6.83503 24.4573 9.73288 26.2354Z"
      fill={color}
    />
  </svg>
);

IconGlobe.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconGlobe.defaultProps = {
  color: '#34545D',
  height: 32,
  width: 32,
};

export default IconGlobe;
