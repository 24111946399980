import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  AddEntityBtn,
  Page,
  TextInput,
} from 'shared/components';
import {
  IconOrganization,
  IconSearch,
  IconPlus,
} from 'shared/icons';
import {
  canCreateAsset,
} from 'shared/helpers';
import { OrganizationCard, OrganizationForm } from '../components';
import * as Styled from './styles';

const OrganizationsList = () => {
  const history = useHistory();

  const organizations = useSelector(state => get(state, 'investing.organizations'));
  const user = useSelector(state => get(state, 'account.user'));
  const isAllowedToCreate = canCreateAsset(user, false);

  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [list, setList] = useState(organizations);

  const onSearchSubmit = (val) => {
    const { search } = val;
    if (!search) {
      return setList(organizations);
    }
    const filteredList = organizations.filter((i) => {
      const name = i.name.toLowerCase();
      const doesInclude = name.includes(search);
      return doesInclude;
    });
    return setList(filteredList);
  };

  const handleAddOrganization = () => {
    // if (!validated) {
    //   toast.error('Complete the mandatory identity verification module to be able to use this feature.');
    //   return false;
    // }
    setFormDisplay(true);
    return true;
  };

  const {
    control,
    handleSubmit,
  } = useForm();

  return (
    <Page type="organizations" title="Organizations">
      <Styled.OrganizationsWrapper>
        <Styled.OrganizationsList>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconOrganization /></Styled.TitleIcon>
              <Styled.TitleText>Organizations</Styled.TitleText>
            </Styled.ListTitle>
            <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
              <Controller
                name="search"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="organizations-search"
                    name="search"
                    placeholder="Search..."
                    size="small"
                    button={<IconSearch />}
                    onBtnClick={handleSubmit(onSearchSubmit)}
                    {...field}
                  />
                )}
              />
            </Styled.ListSearch>
            {isAllowedToCreate && (
              <Styled.ListAction>
                <Styled.AddBtn type="button" onClick={handleAddOrganization}>
                  <IconPlus />
                </Styled.AddBtn>
              </Styled.ListAction>
            )}
          </Styled.ListHeader>
          <Styled.ListWrapper>
            {!organizations.length && isAllowedToCreate && (
              <AddEntityBtn
                text="Create your first organization"
                onClick={handleAddOrganization}
              />
            )}
            {list.map(o => (
              <OrganizationCard
                key={o.id}
                onClick={() => history.push(`/organizations/${o.id}`)}
                organization={{
                  logo: get(o, 'logo'),
                  name: get(o, 'name'),
                  bio: get(o, 'bio'),
                }}
              />
            ))}
          </Styled.ListWrapper>
        </Styled.OrganizationsList>
        {isFormDisplayed && (
          <OrganizationForm
            isOpen={isFormDisplayed}
            closeCb={() => setFormDisplay(false)}
            organization={null}
          />
        )}
      </Styled.OrganizationsWrapper>
    </Page>
  );
};

export default OrganizationsList;
