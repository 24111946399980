import styled from 'styled-components';
import background from 'shared/assets/auth_background.png';

export const InvitationDetails = styled.div`
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: ${props => props.theme.colors.primary.daintree.daintree05};
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  flex-shrink: 0;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-color: ${props => props.theme.colors.shades.warm.dawn90};

  .TextInput {
    width: 100%;
    max-width: unset;

    &:hover {
      border-color: ${props => props.theme.colors.shades.warm.dawn30};
    }
  }
`;

export const Form = styled.div`
  padding: 48px;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  min-width: 500px;
  max-width: 500px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    min-width: 100%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xxxs}) {
    padding: 24px;
  }
`;

export const Heading = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin: 8px 0;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
`;

export const Description = styled.p`
  line-height: 24px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
`;

export const ProfileCell = styled.div`
  margin: 20px 0;
`;

export const Title = styled.h2`
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
  margin: 16px 0;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const SliderLabel = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Button {
    margin: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
