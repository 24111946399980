import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import api from 'shared/api';
import {
  Button,
  Page,
  PageLoader,
  Section,
} from 'shared/components';
import { canCreateAsset } from 'shared/helpers';
import { getUserOrganizations } from 'redux/investing/actions';
import {
  KybForm,
  KybNotice,
  OrganizationForm,
  OrganizationLogoUpload,
  OrganizationOfferings,
  OrganizationOverview,
  OrganizationTeam,
} from '../components';
import * as Styled from './styles';

// status: `pending`, `submitted`, `processing`, `validated`, `failed`, `published`

const OrganizationPage = ({
  isAuth,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { organizationID } = params;
  const user = useSelector(state => get(state, 'account.user'));
  const isAllowedToCreate = canCreateAsset(user, false);
  const userID = get(user, 'user_id');
  const userOrganizations = useSelector(state => get(state, 'investing.organizations'));
  const isUserOrganization = userOrganizations.find(uo => uo.id === Number(organizationID));

  const [isLoading, setLoading] = useState(true);
  const [organizationData, setOrganizationData] = useState(null);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [isKYBDisplayed, setKYBDisplay] = useState(false);
  // organization offerings
  const [offerings, setOfferings] = useState([]);
  const [offeringsLoading, setOfferingsLoading] = useState(true);

  const publishOrganization = useCallback(() => {
    const data = { status: 'published' };
    api.patch(`/api/user-management/business-entities/${organizationID}`, data)
      .then((res) => {
        setOrganizationData(get(res, 'data'));
        dispatch(getUserOrganizations(userID));
        toast.success('Organization published succesfully');
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [organizationID]);

  const getOrganizationOfferings = useCallback((ID) => {
    api.get(`/api/investing/business-entities/${ID}/offerings`)
      .then((res) => {
        setOfferings(get(res, 'data'));
        setOfferingsLoading(false);
      })
      .catch(() => {
        setOfferingsLoading(false);
      });
  }, []);

  const getOrganization = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}`)
      .then((res) => {
        setOrganizationData(get(res, 'data'));
        getOrganizationOfferings(get(res, 'data.id'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [organizationID]);

  useEffect(() => {
    getOrganization();
  }, [getOrganization]);

  if (isLoading) {
    return <Page showBack type="yoc_user" title="Your own circle"><PageLoader /></Page>;
  }

  const status = get(organizationData, 'status');
  const canEdit = (isAuth || isUserOrganization) && isAllowedToCreate;

  return (
    <Page type="organizations" title="Organization">
      <Styled.OrganizationPage>
        <Styled.Main>
          <Styled.Page>
            <Styled.Aside>
              <Styled.LogoContainer>
                {canEdit ? (
                  <OrganizationLogoUpload organization={organizationData} />
                ) : (
                  <Styled.Logo src={get(organizationData, 'logo') || noImagePlaceholder} alt="organization logo" />
                )}
              </Styled.LogoContainer>
              <Styled.Title>{get(organizationData, 'name')}</Styled.Title>
              {canEdit && (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    type="submit"
                    handleClick={() => setFormDisplay(true)}
                  >
                    Edit
                  </Button>
                  {status !== 'published' ? (
                    <Button size="small" handleClick={publishOrganization} disabled={status !== 'validated'}>
                      Publish
                    </Button>
                  ) : null}
                </>
              )}
            </Styled.Aside>
            <Styled.Data>
              <KybNotice status={status} handleClick={() => setKYBDisplay(true)} />
              <Section
                title="Overview"
                content={<OrganizationOverview organization={organizationData} />}
              />
              <OrganizationTeam
                organization={organizationData}
              />
              <OrganizationOfferings
                offerings={offerings}
                loading={offeringsLoading}
                isAuth={isAuth || !!isUserOrganization}
                status={status}
              />
            </Styled.Data>
          </Styled.Page>
        </Styled.Main>
        {isFormDisplayed && (
          <OrganizationForm
            isOpen={isFormDisplayed}
            closeCb={() => {
              getOrganization();
              setFormDisplay(false);
            }}
            organization={organizationData}
          />
        )}
        {isKYBDisplayed && (
          <KybForm
            isOpen={isKYBDisplayed}
            closeCb={() => setKYBDisplay(false)}
            organization={organizationData}
            refetchOrganization={getOrganization}
          />
        )}
      </Styled.OrganizationPage>
    </Page>
  );
};

OrganizationPage.propTypes = {
  isAuth: PropTypes.bool,
};

OrganizationPage.defaultProps = {
  isAuth: false,
};

export default OrganizationPage;
