import styled from 'styled-components';

export const DatePickerWrapper = styled.div`

  .react-datepicker-wrapper {
    width: 100%;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  }

  .DatePickerCalendar {
    border-radius: 0;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.shades.warm.dawn20};

    .react-datepicker__header  {
      border-radius: 0;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation-icon::before, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
      border-width: 2px 2px 0 0;
    }

    .react-datepicker__current-month {
      color: ${props => props.theme.colors.primary.daintree.daintree50};
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }

  /* .react-datepicker__triangle {
    &::before {
      border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20} !important;
    }
  } */

  .react-datepicker__header  {
    background-color: ${props => props.theme.colors.shades.neutral.white} !important;
  }

  .react-datepicker-popper {
    z-index: 999;
    padding-top: 0;
  }
`;
