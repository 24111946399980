import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Page } from 'shared/components';
import { IconAlert } from 'shared/icons';
import * as Styled from './styles';

const NotFound = ({
  isPage,
}) => {
  const history = useHistory();

  if (isPage) {
    return (
      <Page type="404" title="Not found">
        <Styled.NotFound>
          <Styled.Container>
            <IconAlert color="#34545D" height={33} width={33} />
            <Styled.NotFoundTitle>Page not found</Styled.NotFoundTitle>
            <Styled.NotFoundText>The page you are looking for doesn’t exist.</Styled.NotFoundText>
            <Button
              onClick={() => {
                history.push('/');
              }}
            >
              Back to dashboard
            </Button>
          </Styled.Container>
        </Styled.NotFound>
      </Page>
    );
  }

  return (
    <Styled.NotFound>
      <Styled.Container>
        <IconAlert color="#34545D" height={33} width={33} />
        <Styled.NotFoundTitle>Page not found</Styled.NotFoundTitle>
        <Styled.NotFoundText>The page you are looking for doesn’t exist.</Styled.NotFoundText>
        <Button
          onClick={() => {
            history.push('/');
          }}
        >
          Back to dashboard
        </Button>
      </Styled.Container>
    </Styled.NotFound>
  );
};

NotFound.propTypes = {
  isPage: PropTypes.bool,
};

NotFound.defaultProps = {
  isPage: true,
};

export default NotFound;
