import PropTypes from 'prop-types';
import { Loader } from 'shared/components';
import * as Styled from './styles';

const Button = ({
  children,
  disabled,
  isLoading,
  handleClick,
  size,
  variant,
  wide,
  loaderColor,
  ...rest
}) => (
  <Styled.Button
    className="Button"
    size={size}
    variant={variant}
    wide={wide}
    type="button"
    disabled={disabled}
    onClick={handleClick}
    {...rest}
  >
    <>
      {isLoading && <Loader height="18" width="18" color={loaderColor} />}
      {children}
    </>
  </Styled.Button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  wide: PropTypes.bool,
  handleClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large']),
  variant: PropTypes.oneOf(['contained', 'warning', 'outlined', 'text']),
  loaderColor: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  isLoading: false,
  wide: false,
  handleClick: () => {},
  size: 'large',
  loaderColor: '#A3A3A3',
  variant: 'contained',
};

export default Button;
