import PropTypes from 'prop-types';

const EmojiSlightlyFrowning = ({
  height,
  width,
  background,
  strokes,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" {...rest} className="EmojiSlightlyFrowning">
    <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill={background} />
    <path d="M25.4851 27.379C25.4401 27.2 24.3171 23 18.0001 23C11.6821 23 10.5601 27.2 10.5151 27.379C10.4601 27.596 10.5581 27.821 10.7521 27.933C10.9471 28.044 11.1911 28.011 11.3521 27.856C11.3711 27.837 13.3061 26 18.0001 26C22.6941 26 24.6301 27.837 24.6481 27.855C24.7441 27.95 24.8721 28 25.0001 28C25.0841 28 25.1691 27.979 25.2461 27.936C25.4421 27.824 25.5401 27.597 25.4851 27.379Z" fill="#664500" />
    <path d="M12 17C13.3807 17 14.5 15.433 14.5 13.5C14.5 11.567 13.3807 10 12 10C10.6193 10 9.5 11.567 9.5 13.5C9.5 15.433 10.6193 17 12 17Z" fill={strokes} />
    <path d="M24 17C25.3807 17 26.5 15.433 26.5 13.5C26.5 11.567 25.3807 10 24 10C22.6193 10 21.5 11.567 21.5 13.5C21.5 15.433 22.6193 17 24 17Z" fill={strokes} />
  </svg>
);

EmojiSlightlyFrowning.propTypes = {
  background: PropTypes.string,
  strokes: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EmojiSlightlyFrowning.defaultProps = {
  background: '#FFCC4D',
  strokes: '#664500',
  height: 26,
  width: 26,
};

export default EmojiSlightlyFrowning;
