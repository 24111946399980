import PropTypes from 'prop-types';
import * as Styled from './styles';

const PriorityCell = ({
  isLite,
  value,
  text,
}) => (
  <Styled.Cell status={value} isLite={isLite} className="PriorityCell">
    <Styled.Circle status={value} />
    <Styled.Text status={value} isLite={isLite}>{text || value}</Styled.Text>
  </Styled.Cell>
);

PriorityCell.propTypes = {
  isLite: PropTypes.bool,
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
};

PriorityCell.defaultProps = {
  isLite: false,
  text: '',
};

export default PriorityCell;
