import * as actionTypes from './actionTypes';

const initialState = {
  organizationsLoading: true,
  organizations: [],
  offeringsLoading: true,
  offerings: [],
  investmentsLoading: true,
  investments: [],
  countries: [],
  countriesLoading: true,
  currencies: [],
  currenciesLoading: true,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getOrganizationsInit:
      return { ...state, organizationsLoading: true };
    case actionTypes.getOrganizationsSuccess:
      return {
        ...state,
        organizationsLoading: false,
        organizations: action.organizations,
        error: undefined,
      };
    case actionTypes.getOrganizationsError:
      return { ...state, organizationsLoading: false };
    case actionTypes.getOfferingsInit:
      return { ...state, offeringsLoading: true };
    case actionTypes.getOfferingsSuccess:
      return {
        ...state,
        offeringsLoading: false,
        offerings: action.offerings,
        error: undefined,
      };
    case actionTypes.getOfferingsError:
      return { ...state, offeringsLoading: false };
    case actionTypes.getInvestmentsInit:
      return { ...state, investmentsLoading: true };
    case actionTypes.getInvestmentsSuccess:
      return {
        ...state,
        investmentsLoading: false,
        investments: action.investments,
        error: undefined,
      };
    case actionTypes.getInvestmentsError:
      return { ...state, investmentsLoading: false };
    case actionTypes.getCountriesInit:
      return { ...state, countriesLoading: true };
    case actionTypes.getCountriesSuccess:
      return {
        ...state,
        countries: action.countries,
        countriesLoading: false,
        error: undefined,
      };
    case actionTypes.getCountriesError:
      return { ...state, countries: [] };
    case actionTypes.getCurrenciesInit:
      return { ...state, currenciesLoading: true };
    case actionTypes.getCurrenciesSuccess:
      return {
        ...state,
        currencies: action.currencies,
        currenciesLoading: false,
        error: undefined,
      };
    case actionTypes.getCurrenciesError:
      return { ...state, currencies: [] };
    default:
      return { ...state };
  }
}
