import PropTypes from 'prop-types';

const IconOffering = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...rest} className="IconOffering">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66667 2.66634C7.48257 2.66634 7.33333 2.81558 7.33333 2.99967V7.33301C7.33333 7.7012 7.03486 7.99967 6.66667 7.99967C6.29848 7.99967 6 7.7012 6 7.33301V3.66634C6 3.48225 5.85076 3.33301 5.66667 3.33301C5.48257 3.33301 5.33333 3.48225 5.33333 3.66634V9.33301C5.33333 9.7012 5.03486 9.99967 4.66667 9.99967C4.29848 9.99967 4 9.7012 4 9.33301V7.66634C4 7.48225 3.85076 7.33301 3.66667 7.33301C3.48257 7.33301 3.33333 7.48225 3.33333 7.66634V8.99967C3.33333 11.3929 5.27343 13.333 7.66667 13.333C10.0599 13.333 12 11.3929 12 8.99967V5.66634C12 5.48225 11.8508 5.33301 11.6667 5.33301C11.4826 5.33301 11.3333 5.48225 11.3333 5.66634V7.33301C11.3333 7.7012 11.0349 7.99967 10.6667 7.99967C10.2985 7.99967 10 7.7012 10 7.33301V3.66634C10 3.48225 9.85076 3.33301 9.66667 3.33301C9.48257 3.33301 9.33333 3.48225 9.33333 3.66634V7.33301C9.33333 7.7012 9.03486 7.99967 8.66667 7.99967C8.29848 7.99967 8 7.7012 8 7.33301V2.99967C8 2.81558 7.85076 2.66634 7.66667 2.66634ZM9.07487 2.1078C9.25878 2.03793 9.45825 1.99967 9.66667 1.99967C10.5871 1.99967 11.3333 2.74587 11.3333 3.66634V4.03301C11.441 4.01115 11.5525 3.99967 11.6667 3.99967C12.5871 3.99967 13.3333 4.74587 13.3333 5.66634V8.99967C13.3333 12.1293 10.7963 14.6663 7.66667 14.6663C4.53705 14.6663 2 12.1293 2 8.99967V7.66634C2 6.74587 2.74619 5.99967 3.66667 5.99967C3.78082 5.99967 3.8923 6.01115 4 6.03301V3.66634C4 2.74587 4.74619 1.99967 5.66667 1.99967C5.87508 1.99967 6.07456 2.03793 6.25846 2.1078C6.55403 1.64209 7.07425 1.33301 7.66667 1.33301C8.25908 1.33301 8.7793 1.64209 9.07487 2.1078Z"
      fill={color}
    />
  </svg>
);

IconOffering.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconOffering.defaultProps = {
  color: '#E6EAEB',
  height: 16,
  width: 16,
};

export default IconOffering;
