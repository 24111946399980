import PropTypes from 'prop-types';

const IconUpload = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...rest} className="IconUpload">
    <path
      d="M2 14C2 15.1046 2.89543 16 4 16L14 16C15.1046 16 16 15.1046 16 14L16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13L18 14C18 16.2091 16.2091 18 14 18L4 18C1.79086 18 -7.8281e-08 16.2091 -1.74846e-07 14L-2.18557e-07 13C-2.42698e-07 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13L2 14ZM4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289L8.29289 0.292892C8.48043 0.105356 8.73478 -3.81809e-07 9 -3.93402e-07C9.26522 -4.04995e-07 9.51957 0.105356 9.70711 0.292892L13.7071 4.29289C14.0976 4.68342 14.0976 5.31658 13.7071 5.70711C13.3166 6.09763 12.6834 6.09763 12.2929 5.70711L10 3.41421L10 13C10 13.5523 9.55228 14 9 14C8.44771 14 8 13.5523 8 13L8 3.41421L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
    />
  </svg>
);

IconUpload.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUpload.defaultProps = {
  color: '#012934',
  height: 18,
  width: 18,
};

export default IconUpload;
