import styled from 'styled-components';

export const OrganizationMemberNotification = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .Button {
    margin-top: auto;
  }
`;

export const Text = styled.div``;

export const Organization = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    color: ${props => props.theme.colors.shades.warm.dawn60};
    text-transform: uppercase;
  }

  button {
    margin-top: 5px;
  }
`;
