import styled from 'styled-components';

export const Section = styled.div`
  width: 100%;
  padding: 16px 24px 24px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-bottom: 8px;
  margin-right: 40px;
`;

export const Actions = styled.div`
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;
