import styled from 'styled-components';

export const OrganizationTeam = styled.div``;

export const ActionBtnText = styled.span`
  margin-left: 10px;
`;

export const MembersList = styled.div`
  margin-top: 8px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 190px);

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  }
`;

export const Member = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 150px;
  width: 190px;
  padding: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
`;

export const Picture = styled.img`
  width: 100%;
  height: 100%;
  max-height: 48px;
  max-width: 48px;
  object-fit: cover;
  border-radius: 50%;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-top: 12px;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const OwnerLabel = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${props => props.theme.colors.success.success50};
`;

export const DeleteBtn = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    flex-shrink: 0;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.shades.warm.dawn10};
  }
`;
