import * as actionTypes from './actionTypes';

const initialState = {
  entityLoading: true,
  details: {},
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getBusinessEntityInit:
      return { ...state, entityLoading: true };
    case actionTypes.getBusinessEntitySuccess:
      return {
        ...state,
        entityLoading: false,
        details: action.entity,
        error: undefined,
      };
    case actionTypes.refreshBusinessEntity:
      return {
        ...state,
        details: { ...state.details, ...action.entity },
      };
    case actionTypes.refreshBusinessEntityLogo:
      return ({
        ...state,
        details: { ...state.details, logoURL: action.logo },
      });
    case actionTypes.getBusinessEntityError:
      return { ...state, entityLoading: false };
    default:
      return { ...state };
  }
}
