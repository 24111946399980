import PropTypes from 'prop-types';

const IconUpwardArrow = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 12" fill="none" {...rest} className="IconUpwardArrow">
    <path
      d="M1.6129 11.7903L1.70711 11.7071L8 5.415L11.2929 8.70711C11.6534 9.06759 12.2206 9.09532 12.6129 8.7903L12.7071 8.70711L17 4.415L18.2929 5.70711C18.8955 6.30968 19.9072 5.92767 19.994 5.11363L20 5L19.9983 0.940615L19.9893 0.85305L19.9643 0.73416L19.9401 0.658334L19.9063 0.576778L19.854 0.479291L19.7871 0.382978L19.7071 0.292859C19.6717 0.257499 19.6343 0.225313 19.5953 0.196335L19.4841 0.124671L19.3713 0.0712255L19.266 0.0358451L19.1485 0.0110178L19.0893 0.00396641L19 0H15C14.1478 0 13.7026 0.985514 14.2168 1.62253L14.2929 1.70711L15.585 3L12 6.585L8.70711 3.29289C8.34662 2.93241 7.77939 2.90468 7.3871 3.2097L7.29289 3.29289L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.653377 12.0676 1.22061 12.0953 1.6129 11.7903Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
    />
  </svg>
);

IconUpwardArrow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUpwardArrow.defaultProps = {
  color: '#fff',
  height: 12,
  width: 20,
};

export default IconUpwardArrow;
