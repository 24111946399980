import PropTypes from 'prop-types';

const IconOrganization = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...rest} className="IconOrganization">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33329 3.16699C2.33329 1.78628 3.45258 0.666992 4.83329 0.666992H13.1666C14.5473 0.666992 15.6666 1.78628 15.6666 3.16699V15.667L16.4999 15.667C16.9602 15.667 17.3333 16.0401 17.3333 16.5003C17.3333 16.9605 16.9602 17.3336 16.5 17.3336H1.49995C1.03971 17.3336 0.66662 16.9605 0.666626 16.5003C0.666632 16.0401 1.03973 15.667 1.49997 15.667L2.33329 15.667V3.16699ZM3.99996 15.667H6.49996V12.3336C6.49996 11.4132 7.24615 10.667 8.16663 10.667H9.83329C10.7538 10.667 11.5 11.4132 11.5 12.3336V15.667H14V3.16699C14 2.70675 13.6269 2.33366 13.1666 2.33366H4.83329C4.37306 2.33366 3.99996 2.70676 3.99996 3.16699V15.667ZM9.83329 15.667V12.3336H8.16663V15.667H9.83329ZM5.66663 4.83364C5.66663 4.3734 6.03972 4.00031 6.49996 4.00031H7.33329C7.79353 4.00031 8.16663 4.3734 8.16663 4.83364C8.16663 5.29388 7.79353 5.66697 7.33329 5.66697H6.49996C6.03972 5.66697 5.66663 5.29388 5.66663 4.83364ZM9.83329 4.83364C9.83329 4.3734 10.2064 4.00031 10.6666 4.00031H11.5C11.9602 4.00031 12.3333 4.3734 12.3333 4.83364C12.3333 5.29388 11.9602 5.66697 11.5 5.66697H10.6666C10.2064 5.66697 9.83329 5.29388 9.83329 4.83364ZM5.66663 8.16697C5.66663 7.70674 6.03972 7.33364 6.49996 7.33364H7.33329C7.79353 7.33364 8.16663 7.70674 8.16663 8.16697C8.16663 8.62721 7.79353 9.00031 7.33329 9.00031H6.49996C6.03972 9.00031 5.66663 8.62721 5.66663 8.16697ZM9.83329 8.16697C9.83329 7.70674 10.2064 7.33364 10.6666 7.33364H11.5C11.9602 7.33364 12.3333 7.70674 12.3333 8.16697C12.3333 8.62721 11.9602 9.00031 11.5 9.00031H10.6666C10.2064 9.00031 9.83329 8.62721 9.83329 8.16697Z"
      fill={color}
    />
  </svg>
);

IconOrganization.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconOrganization.defaultProps = {
  color: '#7A7A7A',
  height: 18,
  width: 18,
};

export default IconOrganization;
