import PropTypes from 'prop-types';

const IconYOC = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconYOC">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00016 1.66671C4.05464 1.66671 1.66683 4.05452 1.66683 7.00004C1.66683 9.94556 4.05464 12.3334 7.00016 12.3334C9.94568 12.3334 12.3335 9.94556 12.3335 7.00004C12.3335 4.05452 9.94568 1.66671 7.00016 1.66671ZM0.333496 7.00004C0.333496 3.31814 3.31826 0.333374 7.00016 0.333374C10.6821 0.333374 13.6668 3.31814 13.6668 7.00004C13.6668 10.6819 10.6821 13.6667 7.00016 13.6667C3.31826 13.6667 0.333496 10.6819 0.333496 7.00004Z"
      fill={color}
    />
  </svg>
);

IconYOC.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconYOC.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconYOC;
