import * as actionTypes from './actionTypes';

const initialState = {
  surveysLoading: true,
  userSurveysList: [],
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getUserSurveysInit:
      return { ...state, surveysLoading: true };
    case actionTypes.getUserSurveysSuccess:
      return {
        ...state,
        surveysLoading: false,
        userSurveysList: action.list,
        error: undefined,
      };
    case actionTypes.getUserSurveysError:
      return { ...state, surveysLoading: false };
    default:
      return { ...state };
  }
}
