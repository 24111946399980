import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  SidePanel,
  Selector,
} from 'shared/components';
import {
  appName,
  clientRoleOptions,
} from 'shared/constants';
import {
  IconClose,
  IconUserAdd,
} from 'shared/icons';
import { mapTopologyTypesToSelector } from 'shared/helpers';
import api from 'shared/api';
import CircleSelect from '../CircleSelect';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(),
  email: yup
    .string()
    .email()
    .required(),
});

const AddUserForm = ({
  isOpen,
  onClose,
  userToAdd,
}) => {
  const topologies = useSelector(state => get(state, 'topology.types'));
  const topologyTypes = mapTopologyTypesToSelector(topologies);
  const currentUser = useSelector(state => get(state, 'account.user'));
  const userName = get(currentUser, 'user_name');

  const [isLoading, setLoading] = useState(false);
  const [topologyType, setTopologyType] = useState(topologyTypes[0]);
  const [roleNames, setRoleNames] = useState([clientRoleOptions[0]]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: get(userToAdd, 'full_name') || '',
      email: get(userToAdd, 'user_name') || get(userToAdd, 'email') || '',
    },
    resolver: yupResolver(validationSchema),
  });

  const isRoleSelected = () => {
    const hasLength = roleNames.length;
    if (!hasLength) {
      toast.error('Role is a required field');
      return false;
    }
    return true;
  };

  const getRoleNames = () => roleNames.map(r => r.value);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading || !isRoleSelected()) {
      return false;
    }

    setLoading(true);
    const formData = {
      inviter: userName,
      invitee: get(userToAdd, 'user_name') || get(data, 'email') || get(data, 'email.value'),
      topo_type: get(topologyType, 'name'),
      role_names: userToAdd ? undefined : getRoleNames(),
      app_name: appName,
      invitee_full_name: get(data, 'name.value') || get(data, 'name'),
    };

    api.post('/api/yoc/invitations', formData)
      .then(() => {
        setLoading(false);
        toast.success(`Invitation sent to ${formData.invitee_full_name}`);
        onClose();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <SidePanel isOpen={isOpen} close={onClose}>
      <Styled.AddUserForm>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconUserAdd />
            <Styled.HeaderTitleText>{userToAdd ? 'Connect to user' : 'Invite user'}</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={onClose}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          {!userToAdd && (
            <Styled.DetailsRow>
              <Styled.InputContainer>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="email-input"
                      label="Email"
                      placeholder="Enter email"
                      type="email"
                      error={errors.email?.message}
                      disabled={!!userToAdd || isLoading}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
            </Styled.DetailsRow>
          )}
          <Styled.DetailsRow>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="name"
                  label="Name"
                  placeholder="e.g. John Smith"
                  wide
                  disabled={!!userToAdd || isLoading}
                  error={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Styled.DetailsRow>
          {userToAdd ? null : (
            <Styled.DetailsRow>
              <Selector
                id="role_name-input"
                name="role_name"
                label="Suggested roles"
                placeholder="Select role name"
                options={clientRoleOptions}
                onChange={(val) => setRoleNames(val)}
                values={roleNames}
                labelField="label"
                valueField="value"
                multi
                disabled={!!userToAdd || isLoading}
              />
            </Styled.DetailsRow>
          )}
          <Styled.DetailsRow>
            <Styled.SliderLabel>How close are you?</Styled.SliderLabel>
            <CircleSelect onAnswerSelect={val => setTopologyType(val)} />
          </Styled.DetailsRow>
          <Button
            size="large"
            type="submit"
            isLoading={isLoading}
            loaderColor="#fff"
          >
            Submit
          </Button>
        </Styled.Form>
      </Styled.AddUserForm>
    </SidePanel>
  );
};

AddUserForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userToAdd: PropTypes.object,
};

AddUserForm.defaultProps = {
  userToAdd: null,
};

export default AddUserForm;
