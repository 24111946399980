import * as actionTypes from './actionTypes';

const refreshNotificationsList = list => ({
  type: actionTypes.refreshUnreadNotifications,
  list,
});

export const refreshUnreadNotifications = (list) => (dispatch) => {
  dispatch(refreshNotificationsList(list));
};
