import PropTypes from 'prop-types';
import { get } from 'lodash';
import * as Styled from './styles';

const UserRoleNotification = ({
  userRole,
}) => {
  const status = get(userRole, 'UserRole.role.status') || 'pending';

  return (
    <Styled.UserRoleNotification>
      <Styled.Text>{`Your user role has been ${status}.`}</Styled.Text>
    </Styled.UserRoleNotification>
  );
};

UserRoleNotification.propTypes = {
  userRole: PropTypes.object.isRequired,
};

export default UserRoleNotification;
