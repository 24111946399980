import styled from 'styled-components';

export const DealRoomFiles = styled.section`
  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 8px;
  }
`;

export const TableBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .Loader {
    position: initial;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${props => props.theme.colors.shades.warm.dawn90};
      }
    }
  }

  .Button {
    padding: 0 20px;

    svg {
      margin: 0;
    }
  }
`;
