import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  Selector,
} from 'shared/components';
import { IconInvest, IconClose } from 'shared/icons';
import { getUserRoles } from 'shared/helpers';
import api from 'shared/api';
import config from 'config';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const validationSchema = yup.object().shape({
  min_investment: yup
    .number()
    .required(),
  max_investment: yup
    .number()
    .required(),
});

const InterestForm = ({
  isOpen,
  closeCb,
  offering,
  confirmCb,
  currencySymbol,
}) => {
  const user = useSelector(state => get(state, 'account.user'));
  const userRoles = getUserRoles(user);
  const investorRole = userRoles.find(ur => ur.value === 'maker');
  const organizations = useSelector(state => get(state, 'investing.organizations'));
  const offeringID = get(offering, 'id');

  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  // const [selectedRole, setSelectedRole] = useState([get(userRoles, '[0]')]);
  const [selectedOrganization, setSelectedOrganization] = useState([get(organizations, '[0]')]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dirtyForm: '',
      min: '',
      max: '',
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    setLoading(true);

    const postData = {
      min_investment: get(data, 'min_investment'),
      max_investment: get(data, 'max_investment'),
      offering_name: get(offering, 'name'),
      user_role_id: get(investorRole, 'id'),
      business_entity_id: get(selectedOrganization, '[0].id') || null,
    };
    api.post(`/api/investing/offerings/${offeringID}/members`, postData)
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Error occured');
      });
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  const getContent = () => {
    if (!investorRole) {
      return (
        <Styled.SubmitMessage>
          <div>You do not have a role of investor!</div>
          <div>Please contact support if you want to change this.</div>
          <Styled.ButtonContainer>
            <Button
              wide
              disabled={isLoading}
              handleClick={confirmCb}
            >
              Dismiss
            </Button>
          </Styled.ButtonContainer>
        </Styled.SubmitMessage>
      );
    }
    if (isSubmitted) {
      return (
        <Styled.SubmitMessage>
          <div>Thank you!</div>
          <div>Your expression of interest was sent to the VP. They will review your application and invite you to the deal room soon.</div>
          <Styled.ButtonContainer>
            <Button
              wide
              disabled={isLoading}
              handleClick={confirmCb}
            >
              Dismiss
            </Button>
          </Styled.ButtonContainer>
        </Styled.SubmitMessage>
      );
    }

    return (
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.FormTitle>This is a non-binding action: how much would you like to invest?</Styled.FormTitle>
        <Styled.DualInput>
          {organizations.length ? (
            <Styled.InputContainer>
              <Selector
                id="organization-input"
                name="selected-organization"
                label="Your organization"
                options={organizations}
                onChange={(val) => {
                  setSelectedOrganization(val);
                }}
                values={selectedOrganization}
                labelField="name"
                valueField="id"
              />
            </Styled.InputContainer>
          ) : null}
          <Styled.InputContainer>
            <Controller
              name="min_investment"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="min_investment"
                  label="Min investment"
                  type="number"
                  placeholder={currencySymbol}
                  wide
                  disabled={isLoading}
                  error={errors.min_investment?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Controller
              name="max_investment"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="max_investment"
                  label="Max investment"
                  type="number"
                  placeholder={currencySymbol}
                  wide
                  disabled={isLoading}
                  error={errors.max_investment?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputContainer>
        </Styled.DualInput>
        <Styled.ButtonContainer>
          <Button
            type="submit"
            wide
            disabled={isLoading}
          >
            Submit
          </Button>
        </Styled.ButtonContainer>
        <Styled.Acknowledge>
          <p>
            By clicking Submit you agree to Kobo Funds platform
            {' '}
            <a href={`${config.website_url}/terms`} target="_blank" rel="noopener noreferrer" data-scroll>Terms & Conditions</a>
            ,
            {' '}
            <a href={`${config.website_url}/privacy`} target="_blank" rel="noopener noreferrer" data-scroll>Privacy policy</a>
            {' '}
            and
            {' '}
            <a href={`${config.website_url}/nda`} target="_blank" rel="noopener noreferrer" data-scroll>Non disclosure agreement</a>
            {' '}
            of all the Confidential Information received through the platform.
          </p>
        </Styled.Acknowledge>
      </Styled.Form>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="InterestFormModal"
    >
      <Styled.InterestForm>
        <Styled.Main>
          <Styled.Header>
            <IconInvest color="#20201E" />
            <Styled.HeaderTitle>Investment range</Styled.HeaderTitle>
          </Styled.Header>
          {getContent()}
        </Styled.Main>
      </Styled.InterestForm>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

InterestForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string,
};

InterestForm.defaultProps = {
  currencySymbol: '$',
};

export default InterestForm;
