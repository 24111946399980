import styled from 'styled-components';

export const OrganizationCard = styled.button`
  appearance: none;
  border: none;
  background: ${props => props.theme.colors.shades.neutral.white};
  cursor: pointer;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  width: 100%;
  min-height: 100px;
  margin-top: 16px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 16px;
  width: 80px;
  height: 80px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-height: 80px;
  object-fit: contain;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Industry = styled.div`
  text-align: left;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Description = styled.div`
  text-align: left;
  margin-top: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;
