import * as Styled from './styles';

const IdGuidelines = () => (
  <Styled.IdGuidelines>
    <svg width="245" height="394" viewBox="0 0 245 394" fill="none">
      <g clipPath="url(#clip0)">
        <circle cx="125.642" cy="309.167" r="19.8333" transform="rotate(90 125.642 309.167)" stroke="white" strokeWidth="2" />
        <circle cx="37.3085" cy="309.166" r="56.5" transform="rotate(90 37.3085 309.166)" stroke="white" strokeWidth="2" />
      </g>
      {/* <rect x="175.475" y="347.333" width="106.333" height="76.3333" transform="rotate(-180 175.475 347.333)" stroke="white" strokeWidth="2" /> */}
      <rect x="244" y="392.333" width="243" height="391.333" rx="6.50958" transform="rotate(-180 244 392.333)" stroke="white" strokeWidth="2" />
      <defs>
        <clipPath id="clip0" />
      </defs>
    </svg>
  </Styled.IdGuidelines>
);

export default IdGuidelines;
