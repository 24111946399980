import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  ConfirmationPopup,
  FileUploader,
} from 'shared/components';
import { IconKYB, IconClose } from 'shared/icons';
import { getFileExtension } from 'shared/helpers';
import api from 'shared/api';
import { getUserOrganizations } from 'redux/investing/actions';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const KybForm = ({
  isOpen,
  closeCb,
  organization,
  refetchOrganization,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const organizationID = get(organization, 'id');
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [files, setFiles] = useState([]);

  const handleSubmitBtnClick = () => {
    if (!files.length) {
      toast.error('KYB form files have not been added');
      return false;
    }
    setSubmitted(true);
    return true;
  };

  const updateOrganizationStatus = useCallback(() => {
    const data = { status: 'submitted' };
    api.patch(`/api/user-management/business-entities/${organizationID}`, data)
      .then(() => {
        toast.success('KYB form submitted succesfully');
        refetchOrganization();
        dispatch(getUserOrganizations(userID));
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [organizationID]);

  const uploadFiles = useCallback((data) => {
    const formData = new FormData();
    formData.append(
      'content',
      data.file,
      get(data, 'file.name') || 'file',
    );
    formData.append('name', get(data, 'file.name'));
    formData.append('extension', getFileExtension(get(data, 'file.name')));
    return api.post(`/api/user-management/business-entities/${organizationID}/files`, formData);
  }, [organizationID]);

  const submitAllFiles = () => Promise.all(files.map(uploadFiles));

  const handleSubmit = () => {
    setLoading(true);
    submitAllFiles()
      .then(() => {
        updateOrganizationStatus();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="KybFormModal"
    >
      <Styled.KybForm>
        <Styled.Main>
          <Styled.Header>
            <IconKYB />
            <Styled.HeaderTitle>KYB Form</Styled.HeaderTitle>
          </Styled.Header>
          <Styled.FileUploaderLabel>
            Upload a corporate presentation or a full investor deck as well as any other documents that can help us evaluate your business.
          </Styled.FileUploaderLabel>
          <Styled.InputContainer>
            <FileUploader
              maxFiles={20}
              onUploadSuccess={(meta, allFiles) => {
                setFiles(allFiles);
              }}
              onItemRemove={(meta) => {
                const newFiles = files.filter(f => f.meta.id !== meta.id);
                setFiles(newFiles);
              }}
              validFileTypes=".pdf,.doc,.docx,.xls,.xlsx"
              disabled={isLoading}
            />
          </Styled.InputContainer>

          <Styled.ButtonContainer>
            <Button
              type="submit"
              wide
              handleClick={handleSubmitBtnClick}
              disabled={isLoading}
            >
              Submit
            </Button>
          </Styled.ButtonContainer>
        </Styled.Main>
      </Styled.KybForm>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
      {isSubmitted && (
        <ConfirmationPopup
          closeCb={() => setSubmitted(false)}
          confirmCb={handleSubmit}
          title="Submit KYB Info?"
          confirmText="Submit"
          disabled={isLoading}
        >
          Submission is final. You won’t be able to edit or integrate your application after submitting. Do you want to proceed?
        </ConfirmationPopup>
      )}
    </ReactModal>
  );
};

KybForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default KybForm;
