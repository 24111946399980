import PropTypes from 'prop-types';

const IconPasswordHide = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29265 0.292893C1.68317 -0.0976311 2.31634 -0.0976311 2.70686 0.292893L5.74716 3.33319C7.30872 2.483 9.0992 2 11.0002 2C15.9271 2 20.0951 5.23846 21.4965 9.70039C21.5577 9.89546 21.5577 10.1046 21.4965 10.2997C20.8437 12.3778 19.5922 14.1885 17.9449 15.531L20.7069 18.2929C21.0974 18.6834 21.0974 19.3166 20.7069 19.7071C20.3163 20.0976 19.6832 20.0976 19.2926 19.7071L1.29265 1.70711C0.902125 1.31658 0.902125 0.683418 1.29265 0.292893ZM7.2362 4.82223L8.96794 6.55397C9.56338 6.20229 10.2584 6 10.9998 6C13.2089 6 14.9998 7.79086 14.9998 10C14.9998 10.7414 14.7975 11.4364 14.4458 12.0318L16.5218 14.1078C17.8687 13.0598 18.9094 11.6379 19.4884 10C18.2527 6.50345 14.9175 4 11.0002 4C9.65524 4 8.38077 4.29444 7.2362 4.82223ZM12.9322 10.5182C12.9763 10.353 12.9998 10.1793 12.9998 10C12.9998 8.89543 12.1043 8 10.9998 8C10.8204 8 10.6467 8.02347 10.4816 8.06758L12.9322 10.5182ZM3.62464 6.17378C4.06503 6.50706 4.15187 7.13424 3.81859 7.57463C3.266 8.30483 2.82258 9.12134 2.51203 9.99997C3.74775 13.4965 7.08286 16 11.0002 16C11.578 16 12.1423 15.9457 12.6883 15.8421C13.2309 15.7392 13.7542 16.0957 13.8571 16.6383C13.96 17.1809 13.6035 17.7042 13.0609 17.8071C12.3927 17.9338 11.7037 18 11.0002 18C6.07329 18 1.90535 14.7615 0.503957 10.2996C0.442691 10.1045 0.442691 9.89538 0.50396 9.70031C0.885871 8.48437 1.47286 7.36002 2.22379 6.36773C2.55706 5.92734 3.18425 5.8405 3.62464 6.17378Z"
      fill={color}
    />
  </svg>
);

IconPasswordHide.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconPasswordHide.defaultProps = {
  color: '#615F59',
  height: 20,
  width: 22,
};

export default IconPasswordHide;
