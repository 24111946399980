/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const Page = styled.main`
  display: flex;
  position: relative;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }

  ${props => {
    if (props.isMobile) {
      return css`
        padding-top: 40px;
      `;
    }
  }}
`;

export const PageContent = styled.div`
  display: flex;
  /* padding: 0 30px; */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-y: scroll;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    /* height: calc(100vh - 40px); */
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100 - 40px);
    padding: 0;
  }
`;
