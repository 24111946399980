import styled from 'styled-components';

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.danger.danger05};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.danger.danger50};
  max-width: 300px;
  width: 100%;
`;

export const AlertHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.danger.danger50};
  min-height: 56px;

  .IconAlert {
    margin-right: 10px;
  }
`;

export const AlertTitle = styled.div`
  font-weight: bold;
  line-height: 24px;
  color: ${props => props.theme.colors.danger.danger50};
`;

export const AlertMain = styled.div`
  padding: 16px;
`;

export const AlertText = styled.div`
  color: ${props => props.theme.colors.shades.warm.dawn90};
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 14px;
`;

export const AlertActions = styled.div`
  display: flex;
  flex-direction: column;
`;
