import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Button, Loader, Section } from 'shared/components';
import { IconUserAddFill, IconNoProfilePicture, IconDelete } from 'shared/icons';
import api from 'shared/api';
import AddTeamMemberForm from '../AddTeamMemberForm';
import * as Styled from './styles';

const OrganizationTeam = ({
  isAuth,
  organization,
}) => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const organizationID = get(organization, 'id');
  const [isFormDisplayed, setFormDisplayed] = useState(false);
  // organization members
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState(true);
  // organization owners
  const [owners, setOwners] = useState([]);
  const [ownersLoading, setOwnersLoading] = useState(true);

  const getOrganizationMembers = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}/members`)
      .then((res) => {
        setMembers(get(res, 'data'));
        setMembersLoading(false);
      })
      .catch(() => {
        setMembersLoading(false);
      });
  }, [organizationID]);

  const getOrganizationOwners = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}/owners`)
      .then((res) => {
        const data = get(res, 'data') || [];
        const expandedOwnerItems = data.map(i => ({ ...i, is_owner: true }));
        setOwners(expandedOwnerItems);
        setOwnersLoading(false);
      })
      .catch(() => {
        setOwnersLoading(false);
      });
  }, [organizationID]);

  const deleteTeamMember = useCallback((member) => {
    const isOwner = get(member, 'is_owner');
    if (isOwner) {
      toast.error('Organization owner cannot be deleted');
      return false;
    }
    const memberID = get(member, 'member.id');
    api.delete(`/api/user-management/business-entities/members/${memberID}`)
      .then(() => {
        toast.success('Organization member deleted succesfully');
        getOrganizationMembers();
      })
      .catch(() => {
        toast.error('Error occured');
      });
    return true;
  }, []);

  useEffect(() => {
    getOrganizationOwners();
    getOrganizationMembers();
  }, [getOrganizationMembers, getOrganizationOwners]);

  const team = [...members, ...owners];
  const isLoading = membersLoading && ownersLoading;
  const isOwner = owners.some(o => get(o, 'owner.user_id') === userID);

  return (
    <>
      <Section
        title="Team"
        actions={(isAuth || isOwner) ? (
          <Button
            size="small"
            variant="outlined"
            handleClick={() => setFormDisplayed(true)}
          >
            <>
              <IconUserAddFill />
              <Styled.ActionBtnText>Add team member</Styled.ActionBtnText>
            </>
          </Button>
        ) : null}
        content={(
          <Styled.OrganizationTeam>
            {isLoading ? <Loader /> : (
              <div>
                {!team.length ? <div>Organization has no team members</div> : (
                  <Styled.MembersList>
                    {team.map((tm) => (
                      <Styled.Member key={get(tm, 'profile.user_id')} type="button" onClick={() => history.push(`/yoc/${get(tm, 'profile.user_id')}`)}>
                        {get(tm, 'profile.profile_picture') ? (
                          <Styled.Picture src={get(tm, 'profile.profile_picture')} alt="profile picture" />
                        ) : (
                          <IconNoProfilePicture width={48} height={48} />
                        )}
                        <Styled.Name>{get(tm, 'profile.full_name')}</Styled.Name>
                        <Styled.Title>{get(tm, 'member.title') || get(tm, 'owner.title') || 'Title N/A'}</Styled.Title>
                        {get(tm, 'is_owner') && (
                          <Styled.OwnerLabel>OWNER</Styled.OwnerLabel>
                        )}
                        {isAuth && !get(tm, 'is_owner') && (
                          <Styled.DeleteBtn
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              deleteTeamMember(tm);
                            }}
                          >
                            <IconDelete color="#CC3429" />
                          </Styled.DeleteBtn>
                        )}
                      </Styled.Member>
                    ))}
                  </Styled.MembersList>
                )}
              </div>
            )}
          </Styled.OrganizationTeam>
        )}
      />
      {isFormDisplayed && (
        <AddTeamMemberForm
          isOpen={isFormDisplayed}
          closeCb={() => setFormDisplayed(false)}
          refetchMembers={getOrganizationMembers}
          organization={organization}
        />
      )}
    </>
  );
};

OrganizationTeam.propTypes = {
  isAuth: PropTypes.bool,
  organization: PropTypes.object.isRequired,
};

OrganizationTeam.defaultProps = {
  isAuth: false,
};

export default OrganizationTeam;
