import PropTypes from 'prop-types';

const IconHome = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconHome">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.52858 0.528758C6.78892 0.268409 7.21103 0.268409 7.47138 0.528758L13.4714 6.52876C13.7317 6.78911 13.7317 7.21122 13.4714 7.47157C13.211 7.73192 12.7889 7.73192 12.5286 7.47157L12.3333 7.27631V12.3335C12.3333 13.0699 11.7364 13.6668 11 13.6668H2.99998C2.2636 13.6668 1.66665 13.0699 1.66665 12.3335V7.27631L1.47138 7.47157C1.21103 7.73192 0.788925 7.73192 0.528575 7.47157C0.268226 7.21122 0.268226 6.78911 0.528575 6.52876L6.52858 0.528758ZM2.99998 5.94297V12.3335H4.99998V9.66683C4.99998 8.93045 5.59693 8.3335 6.33331 8.3335H7.66665C8.40303 8.3335 8.99998 8.93045 8.99998 9.66683V12.3335H11V5.94297L6.99998 1.94297L2.99998 5.94297ZM7.66665 12.3335V9.66683H6.33331V12.3335H7.66665Z"
      fill={color}
    />
  </svg>
);

IconHome.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconHome.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconHome;
