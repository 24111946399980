import styled from 'styled-components';

export const IdGuidelines = styled.div`
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);

  @media screen and (max-width: 380px) {
    top: 48%;
    
    svg {
      height: 300px;
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xxxs}) {
    top: 43%;
    
    svg {
      height: 220px;
    }
  }

  @media screen and (max-width: 280px) {
    top: 43%;
    
    svg {
      height: 220px;
    }
  }
`;
