import styled from 'styled-components';

export const ProfilePage = styled.div`
  display: flex;
  padding: 24px;
  width: 100%;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    padding: 0;
  }
`;

export const ProfileExtra = styled.div`
  width: 100%;
  max-width: 684px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 100%;
  }
`;
