import PropTypes from 'prop-types';

const IconAdjustments = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...rest} className="IconAdjustments">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C3.55228 0 4 0.447715 4 1V10.1707C5.16519 10.5825 6 11.6938 6 13C6 14.3062 5.16519 15.4175 4 15.8293V17C4 17.5523 3.55228 18 3 18C2.44772 18 2 17.5523 2 17V15.8293C0.834808 15.4175 0 14.3062 0 13C0 11.6938 0.834808 10.5825 2 10.1707V1C2 0.447715 2.44772 0 3 0ZM9 0C9.55229 0 10 0.447715 10 1V2.17071C11.1652 2.58254 12 3.69378 12 5C12 6.30622 11.1652 7.41746 10 7.82929V17C10 17.5523 9.55229 18 9 18C8.44772 18 8 17.5523 8 17V7.82929C6.83481 7.41746 6 6.30622 6 5C6 3.69378 6.83481 2.58254 8 2.17071V1C8 0.447715 8.44772 0 9 0ZM15 0C15.5523 0 16 0.447715 16 1V10.1707C17.1652 10.5825 18 11.6938 18 13C18 14.3062 17.1652 15.4175 16 15.8293V17C16 17.5523 15.5523 18 15 18C14.4477 18 14 17.5523 14 17V15.8293C12.8348 15.4175 12 14.3062 12 13C12 11.6938 12.8348 10.5825 14 10.1707V1C14 0.447715 14.4477 0 15 0ZM9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6C9.55229 6 10 5.55228 10 5C10 4.44772 9.55229 4 9 4ZM3 12C2.44772 12 2 12.4477 2 13C2 13.5523 2.44772 14 3 14C3.55228 14 4 13.5523 4 13C4 12.4477 3.55228 12 3 12ZM15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12Z"
      fill={color}
    />
  </svg>
);

IconAdjustments.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconAdjustments.defaultProps = {
  color: '#012934',
  height: 18,
  width: 18,
};

export default IconAdjustments;
