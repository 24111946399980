import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  TextInput,
} from 'shared/components';
import {
  login,
  loginError,
  loginSuccess,
} from 'redux/account/actions';
import { appName } from 'shared/constants';
import { ReactComponent as Logo } from 'shared/assets/logo_long_white.svg';
import { IconPasswordHide, IconPasswordShow } from 'shared/icons';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(),
  email: yup
    .string()
    .required(),
});

const Login = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => get(state, 'account.checkingUser'));
  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
  }, [currentUserID, history]);

  const [displayPassword, setPasswordDisplay] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    const loginData = {
      user_name: data.email.trim(),
      password: data.password,
      app_name: appName,
    };

    dispatch(login(loginData))
      .then((res) => {
        const userData = get(res, 'data');
        dispatch(loginSuccess(userData));
        history.push('/');
      })
      .catch((err) => {
        dispatch(loginError(err));
        toast.error('You provided wrong credentials');
      });
    return true;
  };

  return (
    <Styled.Login>
      <Styled.LoginForm>
        <Styled.LoginLogo>
          <Logo height={40} width={200} />
        </Styled.LoginLogo>
        <Styled.LoginMain onSubmit={handleSubmit(onSubmit)}>
          <Styled.Heading>Welcome back.</Styled.Heading>
          <Styled.Description>Input your email and password below to login.</Styled.Description>
          <Styled.Title>Login</Styled.Title>
          <Styled.InputWrapper>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="email"
                  label="Email"
                  placeholder="Enter email"
                  error={errors.email?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="password"
                  label="Password"
                  placeholder="Your password"
                  error={errors.password?.message}
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.ForgotPassword>
            <Link to="/forgot-password">Forgot password?</Link>
          </Styled.ForgotPassword>
          <Button
            size="large"
            type="submit"
            wide
            isLoading={isLoading}
            loaderColor="#fff"
          >
            {t('Login')}
          </Button>
        </Styled.LoginMain>
      </Styled.LoginForm>
      <Styled.LoginBackground />
    </Styled.Login>
  );
};

export default Login;
