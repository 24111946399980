import { Route, Switch, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { checkKycStatus } from 'shared/helpers';
import { NotAuthorized } from 'shared/components';
import OfferingsList from './OfferingsList';
import OfferingPage from './OfferingPage';
import OfferingDataroom from './OfferingDataroom';

const Offerings = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const kYCStatus = checkKycStatus(get(user, 'status'));

  if (kYCStatus.isExternal) {
    return (
      <NotAuthorized
        title="You can’t access this page yet"
        text="Platform admin needs to verify you before you can use the app."
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  return (
    <Switch>
      <Route exact path="/offerings" component={OfferingsList} />
      <Route exact path="/offerings/:offeringID" component={OfferingPage} />
      <Route exact path="/offerings/:offeringID/dataroom" component={OfferingDataroom} />
    </Switch>
  );
};

export default Offerings;
