import { get } from 'lodash';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// user actions
const initTopologyTypes = (actions) => () => ({
  type: actions.getTopologyTypesInit,
});

const getTopologyTypesError = (actions) => () => ({
  type: actions.getTopologyTypesError,
});

const getTopologyTypesSuccess = (actions) => (res) => ({
  type: actions.getTopologyTypesSuccess,
  types: res,
});

export const getTopologyTypes = () => (dispatch) => {
  dispatch(initTopologyTypes(actionTypes)());
  return api.get('/api/yoc/topology-types').then(
    (res) => dispatch(getTopologyTypesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getTopologyTypesError(actionTypes)()),
  );
};
