import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { mapTopologyTypesToSelector } from 'shared/helpers';
import Slider from 'rc-slider';
import * as Styled from './styles';
import 'rc-slider/assets/index.css';

const CircleSelect = ({
  onAnswerSelect,
}) => {
  const topologies = useSelector(state => get(state, 'topology.types'));
  const options = mapTopologyTypesToSelector(topologies);
  const [value, setValue] = useState(options[0].index);

  const showMark = (markIndex) => {
    if (markIndex === 0 || markIndex === options.length - 1) {
      return 'flex';
    }
    return 'none';
  };

  const showLabel = (markIndex) => {
    if (markIndex === 0) {
      return 'Just acquaintances';
    }
    if (markIndex === options.length - 1) {
      return 'Very close';
    }
    return '';
  };

  const getMarks = () => {
    const marks = {};
    // eslint-disable-next-line no-unused-vars
    options.forEach((a, i) => {
      // marks[a.id] = a.title;
      marks[a.index] = {
        style: {
          display: showMark(i),
          // display: 'none',
          flexShrink: 0,
        },
        label: showLabel(i),
      };
    });
    return marks;
  };

  const onSliderChange = (val) => {
    setValue(val);
  };

  const onAfterChange = (val) => {
    const topology = options.find(t => t.index === val);
    onAnswerSelect(topology);
  };

  return (
    <Styled.CircleSelect>
      <Slider
        className="CircleSlider"
        marks={getMarks()}
        min={options[0].index}
        max={options[options.length - 1].index}
        defaultValue={options[0].index}
        value={value}
        onChange={onSliderChange}
        onAfterChange={onAfterChange}
      />
    </Styled.CircleSelect>
  );
};

CircleSelect.propTypes = {
  onAnswerSelect: PropTypes.func.isRequired,
};

export default CircleSelect;
