import PropTypes from 'prop-types';

const IconInvest = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 14" fill="none" {...rest} className="IconInvest">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 10H12V11C12 11.5523 11.5523 12 11 12H3C2.44772 12 2 11.5523 2 11V3C2 2.44772 2.44772 2 3 2H11C11.5523 2 12 2.44772 12 3V4H14V3C14 1.34315 12.6569 0 11 0H3C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V10ZM11 4H14C15.1046 4 16 4.89543 16 6V8C16 9.10457 15.1046 10 14 10H11C9.89543 10 9 9.10457 9 8V6C9 4.89543 9.89543 4 11 4ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
      fill={color}
    />
  </svg>
);

IconInvest.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconInvest.defaultProps = {
  color: '#20201E',
  height: 14,
  width: 16,
};

export default IconInvest;
