import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  TextInput,
} from 'shared/components';
import api from 'shared/api';
import { getUUID } from 'shared/helpers';
import { IconPasswordHide, IconPasswordShow } from 'shared/icons';
import { ReactComponent as Logo } from 'shared/assets/logo_long_white.svg';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'must contain 8 characters, one uppercase, one lowercase, one number and one special case character',
    ),
});

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const hasUUID = search.includes('uuid');
  const uuid = hasUUID ? getUUID(search) : '';

  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [displayPassword, setPasswordDisplay] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  useEffect(() => {
    if (currentUserID || !hasUUID) {
      history.push('/');
    }
  }, [currentUserID, history, hasUUID]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    setLoading(true);
    const formData = {
      new_password: data.password,
    };
    api.post(`/api/user-management/reset-password/${uuid}`, formData)
      .then(() => {
        setSubmitted(true);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  if (isSubmitted) {
    return (
      <Styled.ResetPassword>
        <Styled.ResetPasswordForm>
          <Styled.ResetPasswordLogo>
            <Logo height={40} width={200} />
          </Styled.ResetPasswordLogo>
          <Styled.ResetPasswordMainSubmitted onSubmit={handleSubmit(onSubmit)}>
            <Styled.Heading>Password has been reset</Styled.Heading>
            <Styled.BackToLogin className="BackToLogin">
              <Link to="/login">Go to login</Link>
            </Styled.BackToLogin>
          </Styled.ResetPasswordMainSubmitted>
        </Styled.ResetPasswordForm>
        <Styled.ResetPasswordBackground />
      </Styled.ResetPassword>
    );
  }

  return (
    <Styled.ResetPassword>
      <Styled.ResetPasswordForm>
        <Styled.ResetPasswordLogo>
          <Logo height={40} width={200} />
        </Styled.ResetPasswordLogo>
        <Styled.ResetPasswordMain onSubmit={handleSubmit(onSubmit)}>
          <Styled.Heading>Reset password</Styled.Heading>
          <Styled.Description>Enter your new password</Styled.Description>
          <Styled.Title>Password</Styled.Title>
          <Styled.InputWrapper>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="password-input"
                  label="Password"
                  placeholder="Enter password"
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  error={errors.password?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Button
            size="large"
            type="submit"
            handleClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Styled.ResetPasswordMain>
        <Styled.BackToLogin>
          <Link to="/login">Back to login</Link>
        </Styled.BackToLogin>
      </Styled.ResetPasswordForm>
      <Styled.ResetPasswordBackground />
    </Styled.ResetPassword>
  );
};

export default ResetPassword;
