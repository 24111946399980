import styled from 'styled-components';

export const SurveyCard = styled.button`
  appearance: none;
  cursor: pointer;
  width: 100%;
  text-align: start;
  padding: 8px 16px;
  background: ${props => props.theme.colors.shades.neutral.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  margin-bottom: 10px;

  &:disabled {
    background: ${props => props.theme.colors.shades.neutral.gray30};
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Description = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  max-width: 250px;
`;

export const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const Questions = styled.div`
  display: flex;
  align-items: center;
  margin-right: 18px;
`;

export const Finished = styled.div`
  display: flex;
  align-items: center;
  margin-right: 18px;

  .finished {
    color: ${props => props.theme.colors.success.success50};
    font-weight: 600;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
  margin-left: 6px;
`;
