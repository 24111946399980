import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { IconNoProfilePicture, IconOrganization } from 'shared/icons';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import { ProgressBar } from 'shared/components';
import * as Styled from './styles';

const OfferingCard = ({
  handleCardClick,
  offering,
}) => {
  const currencies = useSelector(state => get(state, 'investing.currencies'));
  const backgroundImg = get(offering, 'offering.logo') || noImagePlaceholder;
  const profilePicture = get(offering, 'offering.profile_picture') || '';
  const vpPicture = get(offering, 'vp.picture') || '';
  const percentage = (get(offering, 'offering.amount_raised') / get(offering, 'target')) * 100;
  const usd = currencies.find(c => c.code === 'USD');
  const offeringCurrency = currencies.find(c => get(c, 'id') === get(offering, 'currency_id'));
  const initialCurrency = offeringCurrency || usd;
  const { symbol } = initialCurrency;

  const organizationName = get(offering, 'business_entity_owners.[0].name');

  return (
    <Styled.OfferingCard onClick={handleCardClick} type="button">
      <Styled.Banner>
        {backgroundImg ? (
          <Styled.BannerImg src={backgroundImg} alt="organization banner" />
        ) : (
          <Styled.BannerImgDefault />
        )}
      </Styled.Banner>
      {false && (
        <Styled.LogoWrapper>
          {profilePicture ? (
            <Styled.Logo src={profilePicture} alt="organization logo" />
          ) : (
            <IconOrganization color="#fff" width={32} height={32} />
          )}
        </Styled.LogoWrapper>
      )}
      <Styled.CardInner>
        <Styled.Name>
          {get(offering, 'offering.name')}
        </Styled.Name>
        <Styled.Organization>
          {organizationName}
        </Styled.Organization>
        <Styled.Tagline>
          {get(offering, 'offering.description') || 'Description N/A'}
        </Styled.Tagline>
        {false && (
          <Styled.VPContainer>
            <Styled.VPImageWrapper>
              {vpPicture ? (
                <Styled.VPImage src={vpPicture} alt="vp profile picture" />
              ) : (
                <IconNoProfilePicture width={64} height={64} />
              )}
            </Styled.VPImageWrapper>
            <Styled.VPLabel>VP</Styled.VPLabel>
            <Styled.VPName>{get(offering, 'vp.name')}</Styled.VPName>
          </Styled.VPContainer>
        )}
        <ProgressBar percentage={percentage} />
        <Styled.FundingContainer>
          <Styled.AmountRaised>{`${symbol} ${Number(get(offering, 'offering.amount_raised')).toLocaleString() || '-'} Funded`}</Styled.AmountRaised>
          <Styled.AmountSeparator>/</Styled.AmountSeparator>
          <Styled.AmountGoal>{`${symbol} ${Number(get(offering, 'offering.target')).toLocaleString() || '-'} Goal`}</Styled.AmountGoal>
        </Styled.FundingContainer>
      </Styled.CardInner>
    </Styled.OfferingCard>
  );
};

OfferingCard.propTypes = {
  offering: PropTypes.object.isRequired,
  handleCardClick: PropTypes.func,
};

OfferingCard.defaultProps = {
  handleCardClick: () => { },
};

export default OfferingCard;
