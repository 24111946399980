/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import {
  Button,
  Table,
  Loader,
  Pagination,
  TextInput,
  UserInfoCell,
} from 'shared/components';
import {
  IconAdjustments,
  IconPlus,
  IconSearch,
} from 'shared/icons';
import api from 'shared/api';
import { useWindowDimensions } from 'shared/HOCs';
import { appName } from 'shared/constants';
import { AddUserForm, SearchTypeForm } from '../components';
import * as Styled from './styles';

const filterLoggedInUserOut = (userID, list) => list.filter(l => l.user_id !== userID);

const UsersList = () => {
  const history = useHistory();
  const { isMobile } = useWindowDimensions();
  const countries = useSelector(state => get(state, 'investing.countries'));
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activeSearch, setActiveSearch] = useState('');
  const [searchType, setSearchType] = useState('full_name');
  const [showSearchTypeForm, setSearchTypeForm] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [isAddUserFormDisplayed, setAddUserFormDisplay] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [userToAdd, setUserToAdd] = useState(null);

  const getUsersList = useCallback((
    searchQuery = undefined,
    page = 0,
  ) => {
    const searchParam = searchQuery ? `&${searchType}=${encodeURIComponent(searchQuery)}` : '';
    api.get(`/api/user-management/user-profiles?page=${page + 1}${searchParam}&per_page=10&app_name=${appName}&excluded_statuses=external`)
      .then((res) => {
        const yocData = get(res, 'data');
        setPageCount(get(yocData, 'count_pages'));
        setActivePage(page);
        setData(filterLoggedInUserOut(userID, get(yocData, 'records')));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [searchType]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const onSearchSubmit = (val) => {
    const { search } = val;
    setActiveSearch(search);
    getUsersList(search, 0);
  };

  const changeListPage = (val) => {
    const { selected } = val;
    if (selected !== activePage) {
      setActivePage(selected);
      getUsersList(activeSearch, selected);
    }
  };

  const {
    control,
    handleSubmit,
  } = useForm();

  const handleRowClick = row => {
    const { user_id } = row;
    history.push(`/yoc/${user_id}`);
  };

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'full_name',
      Cell: (cell) => {
        const country = get(cell, 'row.original.country_of_residence') || '';
        const countryOfResidence = countries.find(c => get(c, 'id') === country);
        const city = get(cell, 'row.original.city') || '';
        const address = `${city}${country ? `, ${get(countryOfResidence, 'name')}` : ''}`;

        return (
          <UserInfoCell
            profileImg={get(cell, 'row.original.profile_picture')}
            name={cell.value || ''}
            metaFirst={address || ''}
          // metaSecond={get(cell, 'row.original.title') || ''}
          />
        );
      },
    },
    {
      Header: 'Tagline',
      accessor: 'title',
      Cell: cell => get(cell, 'value') || 'N/A',
      show: !isMobile,
    },
  ], [data]);

  if (isLoading) {
    return <Loader color="#616E7F" />;
  }

  const searchPlaceholder = searchType === 'full_name' ? 'Search by name' : 'Search by email';

  return (
    <>
      <Styled.ListHeader>
        <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
          <Button size="small" variant="outlined" handleClick={() => setSearchTypeForm(true)}>
            <IconAdjustments />
          </Button>
          <Controller
            name="search"
            control={control}
            defaultValue={activeSearch}
            render={({ field }) => (
              <TextInput
                id="users-search"
                name="search"
                placeholder={searchPlaceholder}
                size="small"
                button={<IconSearch />}
                onBtnClick={handleSubmit(onSearchSubmit)}
                {...field}
              />
            )}
          />
        </Styled.ListSearch>
        <Styled.ListAction>
          <Styled.AddBtn type="button" onClick={() => setAddUserFormDisplay(true)}>
            <IconPlus color="#fff" />
          </Styled.AddBtn>
        </Styled.ListAction>
      </Styled.ListHeader>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onRowClick={handleRowClick}
        rowClassName="UsersListRow"
      />
      <Styled.ListPagination>
        <Pagination
          page={activePage}
          pageCount={pageCount}
          handlePageClick={changeListPage}
          pageRangeDisplayed={isMobile ? 1 : 3}
        />
      </Styled.ListPagination>
      {isAddUserFormDisplayed && (
        <AddUserForm
          isOpen={isAddUserFormDisplayed}
          onClose={() => {
            setAddUserFormDisplay(false);
          }}
          userToAdd={userToAdd}
        />
      )}
      {showSearchTypeForm && (
        <SearchTypeForm
          isOpen={showSearchTypeForm}
          closeCb={() => setSearchTypeForm(false)}
          confirmCb={(val) => {
            setSearchType(val);
            setSearchTypeForm(false);
          }}
          activeType={searchType}
        />
      )}
    </>
  );
};

export default UsersList;
