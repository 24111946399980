import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as Ts } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import * as Styled from './styles';

const Tabs = ({ children, onSelect, ...rest }) => (
  <Styled.Tabs>
    <Ts className="Tabs" onSelect={onSelect} {...rest}>
      {children}
    </Ts>
  </Styled.Tabs>
);

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
};

Tabs.defaultProps = {
  onSelect: undefined,
};

export default Tabs;
