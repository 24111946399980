/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const RadioBtn = styled.div`
  display: inline-flex;

  ${props => {
    if (props.disabled) {
      return css`
        cursor: not-allowed;

        .radio-btn-label {
          cursor: not-allowed;
        }
      `;
    }
  }}

  &:hover {
    .radio-btn-dot {
      opacity: 0.8;
    }
  }
`;

export const RadioBtnLabel = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const LabelText = styled.span`
  margin-left: 10px;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ .radio-btn-dot {
    border-color: ${props => props.theme.colors.primary.daintree.daintree50};

    &::after {
      background-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }
  }

  &:checked ~ .radio-btn-dot {
    
    &::after {
      display: block;
    }
  }

  &:disabled {

    &:checked ~ .radio-btn-dot {
      background-color: ${props => props.theme.colors.shades.neutral.white};
      border-color: ${props => props.theme.colors.primary.daintree.daintree40};


      &::after {
        background-color: ${props => props.theme.colors.primary.daintree.daintree40};
      }
    }

    &:not(:checked) ~ .radio-btn-dot {
      background-color: ${props => props.theme.colors.shades.warm.dawn10};
      border-color: ${props => props.theme.colors.shades.warm.dawn30} !important;


      &::after {
        background-color: ${props => props.theme.colors.shades.warm.dawn10};
      }
    }
  }
`;

export const Dot = styled.span`
  height: 20px;
  width: 20px;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn60};

  &::after {
    content: "";
    position: absolute;
    display: none;
    height: 14px;
    width: 14px;
    top: 4px;
    left: 3px;
    border-radius: 50%;
  }
`;
