import PropTypes from 'prop-types';

const IconFacebook = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 10 16" fill="none" {...rest} className="IconFacebook">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.17424 2.65625H10V0.1125C9.68561 0.078125 8.60227 0 7.34091 0C4.70833 0 2.9053 1.325 2.9053 3.75938V6H0V8.84375H2.9053V16H6.46591V8.84375H9.25379L9.69697 6H6.46591V4.04063C6.46591 3.21875 6.74242 2.65625 8.17424 2.65625Z"
      fill={color}
    />
  </svg>
);

IconFacebook.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconFacebook.defaultProps = {
  color: '#012934',
  height: 16,
  width: 10,
};

export default IconFacebook;
