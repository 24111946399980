import PropTypes from 'prop-types';
import * as Styled from './styles';

const Section = ({
  actions,
  title,
  content,
}) => (
  <Styled.Section className="Section">
    <Styled.Header>
      {title && <Styled.Title>{title}</Styled.Title>}
      {actions && <Styled.Actions>{actions}</Styled.Actions>}
    </Styled.Header>
    {content && <Styled.Content>{content}</Styled.Content>}
  </Styled.Section>
);

Section.propTypes = {
  actions: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Section.defaultProps = {
  actions: null,
  title: '',
  content: '',
};

export default Section;
