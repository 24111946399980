import styled from 'styled-components';

export const ListHeader = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.shades.warm.dawn05};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 12px 8px;
  }
`;

export const ListSearch = styled.form`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    /* TODO return after filters are done */
    /* width: 80%; */
    width: 100%;

    .TextInput {
      max-width: 100%;
    }
  }
`;
