import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import {
  Button,
  Section,
} from 'shared/components';
import api from 'shared/api';
import { IconClose } from 'shared/icons';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import OrganizationOfferings from '../OrganizationOfferings';
import OrganizationOverview from '../OrganizationOverview';
import OrganizationTeam from '../OrganizationTeam';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const OrganizationDetails = ({
  isOpen,
  closeCb,
  organization,
}) => {
  const organizationID = get(organization, 'id');
  const status = get(organization, 'status');
  // organization offerings
  const [offerings, setOfferings] = useState([]);
  const [offeringsLoading, setOfferingsLoading] = useState(true);

  const getOrganizationOfferings = useCallback(() => {
    api.get(`/api/investing/business-entities/${organizationID}/offerings`)
      .then((res) => {
        setOfferings(get(res, 'data'));
        setOfferingsLoading(false);
      })
      .catch(() => {
        setOfferingsLoading(false);
      });
  }, [organizationID]);

  useEffect(() => {
    getOrganizationOfferings();
  }, [getOrganizationOfferings]);

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="OrganizationDetailsModal"
    >
      <Styled.OrganizationPage>
        <Styled.Main>
          <Styled.Page>
            <Styled.Aside>
              <Styled.LogoContainer>
                <Styled.Logo src={get(organization, 'logo') || noImagePlaceholder} alt="organization logo" />
              </Styled.LogoContainer>
              <Styled.Title>{get(organization, 'name')}</Styled.Title>
            </Styled.Aside>
            <Styled.Data>
              <Section
                title="Overview"
                content={<OrganizationOverview organization={organization} />}
              />
              <OrganizationTeam
                organization={organization}
                isAuth={false}
              />
              <OrganizationOfferings
                offerings={offerings}
                loading={offeringsLoading}
                isAuth={false}
                status={status}
              />
            </Styled.Data>
          </Styled.Page>
        </Styled.Main>
      </Styled.OrganizationPage>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

OrganizationDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  organization: PropTypes.object,
};

OrganizationDetails.defaultProps = {
  organization: null,
};

export default OrganizationDetails;
