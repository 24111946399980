import styled from 'styled-components';

export const UserInfoCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  background: ${props => props.theme.colors.shades.warm.dawn40};
  border-radius: 50%;
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const MetaContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Meta = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
  word-break: break-word;
`;

export const MetaDivider = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${props => props.theme.colors.shades.warm.dawn70};
  margin: 0 8px;
`;
