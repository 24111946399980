import PropTypes from 'prop-types';

const IconLock = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 30 34" fill="none" {...rest} className="IconLock">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 8.66666C6.66667 4.06429 10.3976 0.333328 15 0.333328C19.6024 0.333328 23.3333 4.06429 23.3333 8.66666V13.6667H25C27.7614 13.6667 30 15.9052 30 18.6667V28.6667C30 31.4281 27.7614 33.6667 25 33.6667H5C2.23858 33.6667 0 31.4281 0 28.6667V18.6667C0 15.9052 2.23858 13.6667 5 13.6667H6.66667V8.66666ZM10 13.6667H20V8.66666C20 5.90524 17.7614 3.66666 15 3.66666C12.2386 3.66666 10 5.90524 10 8.66666V13.6667ZM5 17C4.07953 17 3.33333 17.7462 3.33333 18.6667V28.6667C3.33333 29.5871 4.07953 30.3333 5 30.3333H25C25.9205 30.3333 26.6667 29.5871 26.6667 28.6667V18.6667C26.6667 17.7462 25.9205 17 25 17H5ZM15 20.3333C15.9205 20.3333 16.6667 21.0795 16.6667 22V25.3333C16.6667 26.2538 15.9205 27 15 27C14.0795 27 13.3333 26.2538 13.3333 25.3333V22C13.3333 21.0795 14.0795 20.3333 15 20.3333Z"
      fill={color}
    />
  </svg>
);

IconLock.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconLock.defaultProps = {
  color: '#34545D',
  height: 34,
  width: 30,
};

export default IconLock;
