import { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { canCreateAsset } from 'shared/helpers';
import { Button, Loader, Section } from 'shared/components';
import { IconPlus } from 'shared/icons';
import { OfferingCard, OfferingForm } from 'Offerings/components';
import * as Styled from './styles';

const OrganizationOfferings = ({
  offerings,
  isAuth,
  loading,
  status,
}) => {
  const params = useParams();
  const history = useHistory();
  const { organizationID } = params;
  const user = useSelector(state => get(state, 'account.user'));
  const isAllowedToCreate = canCreateAsset(user, false);
  const [isFormDisplayed, setFormDisplayed] = useState(false);

  const isPublished = status === 'published';
  return (
    <>
      <Section
        title="Offerings"
        actions={(isAuth && isAllowedToCreate) ? (
          <Button
            size="small"
            variant="outlined"
            handleClick={() => {
              if (!isPublished) {
                toast.error('Organization needs to be published before creating offerings.');
                return false;
              }
              setFormDisplayed(true);
              return true;
            }}
          >
            <>
              <IconPlus color="#191919" width={14} height={14} />
              <Styled.ActionBtnText>Create new</Styled.ActionBtnText>
            </>
          </Button>
        ) : null}
        content={(
          <Styled.OrganizationOfferings>
            <>
              {loading ? <Loader /> : (
                <div>
                  {!offerings.length ? 'Organization has no offerings' : (
                    <Styled.OfferingsList>
                      {offerings.map(o => (
                        <OfferingCard
                          key={get(o, 'offering.id')}
                          offering={o}
                          handleCardClick={() => history.push(`/offerings/${get(o, 'offering.id')}`)}
                        />
                      ))}
                    </Styled.OfferingsList>
                  )}
                </div>
              )}
            </>
            {isFormDisplayed && (
              <OfferingForm
                isOpen={isFormDisplayed}
                closeCb={() => setFormDisplayed(false)}
                offering={null}
                organizationID={Number(organizationID)}
              />
            )}
          </Styled.OrganizationOfferings>
        )}
      />
    </>
  );
};

OrganizationOfferings.propTypes = {
  offerings: PropTypes.array,
  isAuth: PropTypes.bool,
  loading: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

OrganizationOfferings.defaultProps = {
  offerings: [],
  isAuth: false,
  loading: true,
};

export default OrganizationOfferings;
