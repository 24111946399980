import { Loader } from 'shared/components';
import * as Styled from './styles';

const PageLoader = () => (
  <Styled.LoaderWrapper>
    <Loader color="#616E7F" />
  </Styled.LoaderWrapper>
);

export default PageLoader;
