import styled from 'styled-components';

export const NotificationDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 12px 24px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const HeaderTitleText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  font-weight: bold;
`;

export const HeaderClose = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailsInner = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.neutral.gray50};
  padding: 0 24px 12px 24px;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const RowLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn60};
`;

export const RowValue = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  height: 100%;
`;
