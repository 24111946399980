import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Button } from 'shared/components';
import { IconClose } from 'shared/icons';
import * as Styled from './styles';

const ConfirmationPopup = ({
  confirmText,
  disabled,
  title,
  variant,
  children,
  confirmCb,
  cancelText,
  closeCb,
}) => createPortal(
  <Styled.ConfirmationPopup>
    <Styled.PopupInner>
      <Styled.PopupHeader>{title}</Styled.PopupHeader>
      {children && <Styled.PopupBody>{children}</Styled.PopupBody>}
      <Styled.PopupFooter>
        <Button
          onClick={confirmCb}
          variant={variant}
          disabled={disabled}
        >
          {confirmText}
        </Button>
        <Button
          onClick={closeCb}
          variant="text"
          disabled={disabled}
        >
          {cancelText}
        </Button>
        <Styled.PopupDismiss
          type="button"
          className="ConfirmationPopup-dismiss"
          onClick={closeCb}
          disabled={disabled}
        >
          <IconClose height={16} width={16} color="#012934" />
        </Styled.PopupDismiss>
      </Styled.PopupFooter>
    </Styled.PopupInner>
  </Styled.ConfirmationPopup>,
  document.body,
);

ConfirmationPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  title: PropTypes.string,
};

ConfirmationPopup.defaultProps = {
  children: '',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  disabled: false,
  variant: 'contained',
  title: 'Are you sure?',
};

export default ConfirmationPopup;
