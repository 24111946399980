import React, { useState, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  TextInput,
} from 'shared/components';
import { IconPasswordHide, IconPasswordShow } from 'shared/icons';
import api from 'shared/api';
import {
  logout,
  logoutError,
  logoutSuccess,
  refreshUser,
} from 'redux/account/actions';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(),
  new_password: yup
    .string()
    .required(),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
});

const InitialPasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const password = useRef({});
  const [isLoading, setLoading] = useState(false);
  const [displayPassword, setPasswordDisplay] = useState(false);

  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  password.current = watch('password', '');

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        toast.success('Logout successful');
        history.push('/login');
      })
      .catch((err) => {
        dispatch(logoutError(err));
        toast.error('Unable to logout');
      });
  };

  const updateUserStatus = useCallback(() => {
    api.patch(`/api/user-management/users/${currentUserID}/status`, { status: 'pending' })
      .then((res) => {
        const userSession = get(res, 'data.user_session');
        dispatch(refreshUser(userSession));
        // TODO check response first
        toast.success('Password changed succesfully.');
        history.push('/');
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [currentUserID]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    setLoading(true);

    const passwordData = {
      user_id: currentUserID,
      old_password: data.current_password,
      new_password: data.new_password,
    };
    api.post('/api/user-management/change-password', passwordData)
      .then(() => {
        updateUserStatus();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <Styled.InitialPasswordReset>
      <Styled.Main>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Heading>Welcome!</Styled.Heading>
          <Styled.Description>Please reset your initial password.</Styled.Description>
          <Styled.Title>Set password</Styled.Title>
          <Styled.InputWrapper>
            <Controller
              name="current_password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="current_password"
                  label="Current password"
                  placeholder="Your current password"
                  error={errors.current_password?.message}
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Controller
              name="new_password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="new_password"
                  label="Password"
                  placeholder="Your new password"
                  error={errors.new_password?.message}
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Controller
              name="passwordConfirmation"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="passwordConfirmation"
                  label="Confirm password"
                  placeholder="Repeat new password"
                  error={errors.passwordConfirmation?.message}
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Button
            size="large"
            type="submit"
            wide
            isLoading={isLoading}
            loaderColor="#fff"
          >
            Submit
          </Button>
          <Styled.LogoutLink>
            <Button disabled={isLoading} variant="text" type="button" handleClick={handleLogout}>
              Logout
            </Button>
          </Styled.LogoutLink>
        </Styled.Form>
      </Styled.Main>
    </Styled.InitialPasswordReset>
  );
};

export default InitialPasswordReset;
