import styled from 'styled-components';

export const YocConnections = styled.div`
`;

export const ListPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;
