import PropTypes from 'prop-types';

const IconDropdownHandle = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none" {...rest} className="IconDropdownHandle">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 9L18.5 9L12.5 15L6.5 9Z"
      fill={color}
    />
  </svg>
);

IconDropdownHandle.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconDropdownHandle.defaultProps = {
  color: '#012934',
  height: 24,
  width: 25,
};

export default IconDropdownHandle;
