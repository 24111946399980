import PropTypes from 'prop-types';
import * as Styled from './styles';

const Label = ({
  text,
}) => (
  <Styled.Label className="Label">
    {text}
  </Styled.Label>
);

Label.propTypes = {
  text: PropTypes.string,
};

Label.defaultProps = {
  text: '',
};

export default Label;
