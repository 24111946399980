/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const Cell = styled.div`
  text-transform: capitalize;

  ${props => {
    if (props.status === 'validated') {
      return css`
        color: ${() => props.theme.colors.success.success50};
      `;
    }
  }}
`;
