import styled from 'styled-components';

export const ProfileCard = styled.div`
  margin-right: 24px;
  width: 100%;
  min-width: 210px;
  max-width: 240px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    margin-right: 0;
    max-width: 100%;
  }
`;

export const ProfileCardInner = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    border: none;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 70px;
`;

export const BannerImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 70px;
  object-fit: cover;
`;

export const BannerImgDefault = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
`;

export const EditBtn = styled.button`
  position: absolute;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  border: none;
  background: rgb(255 255 255 / 50%);
`;

export const ProfilePictureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-top: -40px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn40};
  position: relative;

  &:hover {
    .EditBtn {
      display: flex;
    }
  }
`;

export const ProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  max-height: 80px;
  object-fit: cover;
  border-radius: 50%;
`;

export const CardInner = styled.div`
  padding: 8px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Button {
    width: 100%;
  }
`;

export const Name = styled.div`
  font-weight: bold;
  word-break: break-all;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.shades.warm.dawn90};

  a {
    color: ${props => props.theme.colors.shades.warm.dawn90};
  }
`;

export const Title = styled.div`
  text-align: center;
  word-break: break-all;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
  color: ${props => props.theme.colors.shades.warm.dawn80};
`;

export const Address = styled.div`
  display: flex;
  word-break: break-all;
  align-items: baseline;
  justify-content: center;
  margin-top: 4px;

  svg {
    margin-right: 2px;
    flex-shrink: 0;
  }
`;

export const AddressText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
  text-align: center;
`;

export const Connection = styled.div`
  margin: 8px 8px 0;
  width: 100%;
  background-color: ${props => props.theme.colors.shades.warm.dawn10};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 20px;
  border-radius: 5000px;
  min-height: 48px;
`;

export const CircleName = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const ConnectBtnInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;
