import PropTypes from 'prop-types';

const IconPlus = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 19 18" fill="none" {...rest} className="IconPlus">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 0C10.0523 0 10.5 0.447715 10.5 1V8H17.5C18.0523 8 18.5 8.44772 18.5 9C18.5 9.55229 18.0523 10 17.5 10H10.5V17C10.5 17.5523 10.0523 18 9.5 18C8.94772 18 8.5 17.5523 8.5 17V10H1.5C0.947715 10 0.5 9.55228 0.5 9C0.5 8.44772 0.947715 8 1.5 8H8.5V1C8.5 0.447715 8.94772 0 9.5 0Z"
      fill={color}
    />
  </svg>
);

IconPlus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconPlus.defaultProps = {
  color: '#fff',
  height: 18,
  width: 19,
};

export default IconPlus;
