import React from 'react';
import * as Styled from './styles';

const ExternalCard = () => (
  <Styled.ExternalCard>
    <Styled.Title>You have not been verified</Styled.Title>
    <Styled.Text>Platform admin needs to verify you before you can use the app.</Styled.Text>
  </Styled.ExternalCard>
);

export default ExternalCard;
