import PropTypes from 'prop-types';

const IconDelete = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 15 16" fill="none" {...rest} className="IconDelete">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 0C6.12123 0 5.77497 0.214002 5.60557 0.552786L4.88197 2H1.5C0.947715 2 0.5 2.44772 0.5 3C0.5 3.55228 0.947716 4 1.5 4L1.5 14C1.5 15.1046 2.39543 16 3.5 16H11.5C12.6046 16 13.5 15.1046 13.5 14V4C14.0523 4 14.5 3.55228 14.5 3C14.5 2.44772 14.0523 2 13.5 2H10.118L9.39443 0.552786C9.22504 0.214002 8.87877 0 8.5 0H6.5ZM4.5 6C4.5 5.44772 4.94772 5 5.5 5C6.05228 5 6.5 5.44772 6.5 6V12C6.5 12.5523 6.05228 13 5.5 13C4.94772 13 4.5 12.5523 4.5 12V6ZM9.5 5C8.94771 5 8.5 5.44772 8.5 6V12C8.5 12.5523 8.94771 13 9.5 13C10.0523 13 10.5 12.5523 10.5 12V6C10.5 5.44772 10.0523 5 9.5 5Z"
      fill={color}
    />
  </svg>
);

IconDelete.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconDelete.defaultProps = {
  color: '#012934',
  height: 16,
  width: 15,
};

export default IconDelete;
