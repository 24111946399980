import styled from 'styled-components';

export const OfferingOwnerNotification = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Text = styled.div``;

export const Owner = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.shades.warm.dawn60};
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    display: flex;
  }
`;

export const Offering = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.shades.warm.dawn60};
    text-transform: uppercase;
  }
`;
