import styled from 'styled-components';

export const OrganizationLogoUpload = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

export const LogoPreview = styled.div`
  background-color: ${props => props.theme.colors.shades.warm.dawn05};
  /* padding: 10px; */
  height: 200px;
  width: 100%;
  max-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoPreviewImg = styled.img`
  width: 100%;
  max-height: 200px;
  height: auto;
  object-fit: cover;
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  max-width: 400px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 500px;
  }
`;

export const Input = styled.input`
  position: absolute;
  left: -99999px;
`;

export const UploadData = styled.div`
  width: 100%;
  padding: 10px;
`;

export const UploadPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  .Button {
    .Loader {
      left: 2px;
    }
  }
`;

export const UploadPreview = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 10px;
`;

export const ChangeLogo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  font-weight: bold;
  font-size: 15px;

  .IconUpload {
    margin-right: 10px;
  }
`;

export const Uploader = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
  width: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  background-color: ${props => props.theme.colors.shades.warm.dawn05};
`;

export const Left = styled.div``;

export const UploaderLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Desc = styled.div`
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const Right = styled.div`
  padding-left: 15px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-height: 240px;
  object-fit: cover;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    max-height: 150px;
  }
`;
