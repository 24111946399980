import PropTypes from 'prop-types';

const IconArrowLeft = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 10" fill="none" {...rest} className="IconArrowLeft">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L3.41421 4L19 4C19.5523 4 20 4.44771 20 5C20 5.55228 19.5523 6 19 6L3.41421 6L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z"
      fill={color}
    />
  </svg>
);

IconArrowLeft.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconArrowLeft.defaultProps = {
  color: '#817E77',
  height: 17,
  width: 14,
};

export default IconArrowLeft;
