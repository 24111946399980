import styled from 'styled-components';

export const ConfirmationPopup = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99999;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  overflow-y: auto;
  box-sizing: border-box;
  padding: 50px 0;
`;

export const PopupInner = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  max-width: 500px;
  min-width: 400px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    max-width: unset;
  }
`;

export const PopupHeader = styled.div`
  padding: 20px 30px 20px 20px;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
`;

export const PopupBody = styled.div`
  padding: 0 20px 20px;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  line-height: 1.4;
  font-size: 15px;
`;

export const PopupFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 20px;

  button {
    margin-right: 10px;
  }
`;

export const PopupDismiss = styled.button`
  position: absolute;
  top: 10px;
  right: 0;
  background: transparent;
  border: none;
  font-size: 15px;
  cursor: pointer;
  opacity: 0.5;
  font-weight: 600;
  outline: 0;
  margin-right: 0 !important;
`;
