import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import {
  Button,
  Checkbox,
  Notice,
  Loader,
  UserInfoCell,
} from 'shared/components';
import { formatDate } from 'shared/helpers';
import api from 'shared/api';
import { CircleSelect } from 'Yoc/components';
import * as Styled from './styles';

const InvitationNotification = ({
  closeCb,
  invitation,
  notification,
}) => {
  const history = useHistory();
  const invitationID = get(invitation, 'Invitation.id') || '';
  const inviterName = get(invitation, 'Invitation.inviter_full_name') || '';
  const inviteeName = get(invitation, 'Invitation.invitee_full_name') || get(invitation, 'Invitation.invitee') || '';
  const inviterID = get(invitation, 'Invitation.inviter_id') || '';
  const inviteeID = get(invitation, 'Invitation.invitee_id') || '';
  const expiresAt = get(invitation, 'Invitation.expires_at') || '';
  const isValid = moment.utc().isBefore(expiresAt);
  const status = get(notification, 'object.status');
  const isConfirmed = get(invitation, 'Invitation.confirmed');
  const isApproved = status === 'approved';

  const [isLoading, setLoading] = useState(false);
  const [isProfileLoading, setProfileLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [connectBack, setConnectBack] = useState(true);
  const [connectBackTopology, setConnectBackTopology] = useState(null);

  const invitationActorID = isApproved ? inviteeID : inviterID;

  const acceptInvitation = useCallback(() => {
    setLoading(true);
    api.post(`/api/yoc/invitations/${invitationID}`)
      .then(() => {
        const data = { user_id: inviterID, type: get(connectBackTopology, 'name') };
        return api.post(`/api/yoc/users/${inviteeID}/connections`, data);
      })
      .then(() => {
        toast.success('Invitation accepted');
        setLoading(false);
        history.push(`/yoc/${invitationActorID}`);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [invitationID, invitationActorID, connectBackTopology]);

  const getProfile = useCallback(() => {
    api.get(`/api/user-management/user-profiles/${invitationActorID}`)
      .then((res) => {
        setProfileData(get(res, 'data.user'));
        setProfileLoading(false);
      })
      .catch(() => {
        setProfileLoading(false);
      });
  }, [invitationActorID]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const getNotificationText = () => {
    switch (status) {
      case 'requested':
        return `${inviterName} wants to connect with you. This invitation will expire on ${formatDate(expiresAt)}.`;
      case 'approved':
        return `You have been connected with ${inviteeName}.`;
      default:
        return `Invitation has been ${status}`;
    }
  };

  if (isProfileLoading) {
    return (
      <Styled.InvitationNotification>
        <Loader color="#616E7F" width={20} height={20} />
      </Styled.InvitationNotification>
    );
  }

  const showActions = isValid && !isApproved && !isConfirmed;

  return (
    <Styled.InvitationNotification>
      <Styled.Text>{getNotificationText()}</Styled.Text>
      <Styled.Inviter>
        {/* THIS needs to go to /yoc on client and /users on admin app */}
        <Link to={`/yoc/${invitationActorID}`}>
          <UserInfoCell
            profileImg={get(profileData, 'profile_picture')}
            name={get(profileData, 'full_name')}
            metaFirst={get(profileData, 'title')}
          />
        </Link>
      </Styled.Inviter>
      {showActions && (
        <Styled.ConnectBackContainer>
          <Checkbox
            id="connectBack"
            value={connectBack}
            label="Connect back?"
            onChange={(e) => setConnectBack(e.target.checked)}
          />
          {connectBack && (
            <>
              <Styled.SliderLabel>How close are you?</Styled.SliderLabel>
              <CircleSelect onAnswerSelect={val => setConnectBackTopology(val)} />
            </>
          )}
        </Styled.ConnectBackContainer>
      )}
      {showActions && (
        <Styled.ButtonsContainer>
          <Button disabled={isLoading} handleClick={acceptInvitation}>Accept</Button>
          <Button disabled={isLoading} handleClick={closeCb} variant="outlined">Ignore</Button>
        </Styled.ButtonsContainer>
      )}
      {!isValid && !isConfirmed && (
        <Styled.Expired>
          <Notice
            title="Invitation request has expired."
            type="error"
          />
        </Styled.Expired>
      )}
      {isConfirmed && (
        <Styled.Expired>
          <Notice
            title="Invitation request has been confirmed."
            type="success"
          />
        </Styled.Expired>
      )}
    </Styled.InvitationNotification>
  );
};

InvitationNotification.propTypes = {
  invitation: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default InvitationNotification;
