/* eslint-disable func-names */
import * as actionTypes from './actionTypes';

const expandList = (list, item) => {
  const doesExist = list.find(li => li.user_id === item.user_id);
  if (doesExist) {
    return list;
  }
  return [...list, item];
};

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.addToNavigationHistory: {
      return { ...state, list: expandList(state.list, action.value) };
    }
    case actionTypes.clearNavigationHistory: {
      return { ...state, list: [] };
    }
    default:
      return Object.assign(state);
  }
}
