import PropTypes from 'prop-types';
import { Button } from 'shared/components';
import { IconCamera } from 'shared/icons';
import * as Styled from './styles';

const SnapId = ({
  onImageTake,
  type,
}) => (
  <Styled.TakeImage>
    <div style={{ width: '48px', height: '48px' }} />
    <Button handleClick={() => onImageTake(type)}><IconCamera /></Button>
    <div style={{ width: '48px', height: '48px' }} />
  </Styled.TakeImage>
);

SnapId.propTypes = {
  onImageTake: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default SnapId;
