import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import {
  logout,
  logoutError,
  logoutSuccess,
} from 'redux/account/actions';
import {
  IconAlert,
  IconBell,
  IconClose,
  IconHamburger,
  IconOffering,
  IconOrganization,
  IconHome,
  IconLogout,
  IconKey,
  IconKyc,
  IconYOC,
  IconSurvey,
  IconUserOutline,
  IconSupport,
} from 'shared/icons';
import { checkKycStatus, canCreateAsset } from 'shared/helpers';
import { ReactComponent as LongLogo } from 'shared/assets/logo_long_white.svg';
import version from 'version';
import * as Styled from './styles';

const getIcon = (type) => {
  switch (type) {
    case 'dashboard':
      return <IconHome />;
    case 'support':
      return <IconSupport />;
    case 'yoc':
      return <IconYOC />;
    case 'kyc':
      return <IconKyc height={14} width={14} color="#E6EAEB" />;
    case 'offerings':
      return <IconOffering />;
    case 'dealrooms':
      return <IconKey />;
    case 'organizations':
      return <IconOrganization height={14} width={14} color="#E6EAEB" />;
    case '404':
      return <IconAlert height={14} width={14} color="#E6EAEB" />;
    case 'surveys':
      return <IconSurvey height={14} width={14} color="#E6EAEB" />;
    case 'profile':
      return <IconUserOutline height={14} width={14} color="#E6EAEB" />;
    case 'notifications':
      return <IconBell height={14} width={14} color="#E6EAEB" />;
    default:
      return null;
  }
};

const MobileMenu = ({
  title,
  type,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const kYCStatus = checkKycStatus(get(user, 'status'));
  const external = kYCStatus.isExternal;
  const validated = kYCStatus.isValidated;
  const isAllowedToCreate = canCreateAsset(user, false);

  const organizations = useSelector(state => get(state, 'investing.organizations'));
  // notifications
  const unreadNotifications = useSelector(state => get(state, 'notifications.unreadNotifications'));
  const unreadNotificationsCount = unreadNotifications.length;

  const [menuDisplayed, setMenuDisplayed] = useState(false);

  const handleMenuToggle = (show) => {
    const $body = document.querySelector('body');

    if (!show) {
      setMenuDisplayed(false);
      $body.classList.remove('noscroll');
    } else {
      setMenuDisplayed(true);
      $body.classList.add('noscroll');
    }
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        toast.success('Logout successful');
        history.push('/login');
      })
      .catch((err) => {
        dispatch(logoutError(err));
        toast.error('Unable to logout');
      });
  };

  return (
    <Styled.MobileMenu>
      <Styled.Heading>
        <Styled.TitleIcon>{getIcon(type)}</Styled.TitleIcon>
        <Styled.Title>{title}</Styled.Title>
      </Styled.Heading>
      <Styled.ToggleBtn type="button" onClick={() => handleMenuToggle(!menuDisplayed)}>
        {menuDisplayed ? <IconClose /> : <IconHamburger />}
      </Styled.ToggleBtn>
      <Styled.Menu style={menuDisplayed ? { display: 'flex' } : { display: 'none' }}>
        <Styled.Nav>
          <Styled.EntityDetails className="EntityDetails">
            <LongLogo width={120} />
          </Styled.EntityDetails>
          <Styled.NavItem>
            <NavLink to="/" exact className="SidebarLink">
              <IconHome />
              <Styled.NavItemText className="NavItemText">Dashboard</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
          {!external && !validated && (
            <Styled.NavItem>
              <NavLink to="/kyc" exact className="SidebarLink">
                <IconKyc height={14} width={14} />
                <Styled.NavItemText className="NavItemText">Proof of identity</Styled.NavItemText>
              </NavLink>
            </Styled.NavItem>
          )}
          {false && (
            <Styled.NavItem>
              <NavLink to="/surveys" exact className="SidebarLink">
                <IconSurvey height={14} width={14} color="#E6EAEB" />
                <Styled.NavItemText className="NavItemText">Surveys</Styled.NavItemText>
              </NavLink>
            </Styled.NavItem>
          )}
          {!external && (
            <Styled.NavItem>
              <NavLink to="/yoc" className="SidebarLink">
                <IconYOC />
                <Styled.NavItemText className="NavItemText">Your Own Circle</Styled.NavItemText>
              </NavLink>
            </Styled.NavItem>
          )}
          {!external && (isAllowedToCreate || !!organizations.length) && (
            <Styled.NavItem>
              <NavLink to="/organizations" className="SidebarLink">
                <IconOrganization height={14} width={14} color="#E6EAEB" />
                <Styled.NavItemText className="NavItemText">Your organizations</Styled.NavItemText>
              </NavLink>
            </Styled.NavItem>
          )}
          {!external && (
            <Styled.NavItem>
              <NavLink to="/offerings" className="SidebarLink">
                <IconOffering />
                <Styled.NavItemText className="NavItemText">Offerings</Styled.NavItemText>
              </NavLink>
            </Styled.NavItem>
          )}
          {!external && (
            <Styled.NavItem>
              <NavLink to="/dealrooms" className="SidebarLink">
                <IconKey />
                <Styled.NavItemText className="NavItemText">Dealrooms</Styled.NavItemText>
              </NavLink>
            </Styled.NavItem>
          )}
          <Styled.NavItem>
            <NavLink to="/support" className="SidebarLink">
              <IconSupport />
              <Styled.NavItemText className="NavItemText">Support</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
          <Styled.NavItem>
            <NavLink to="/profile" className="SidebarLink">
              <IconUserOutline height={14} width={14} color="#E6EAEB" />
              <Styled.NavItemText className="NavItemText">Profile</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        </Styled.Nav>
        <Styled.Footer>
          <Styled.NavItem>
            <NavLink to="/notifications" className="SidebarLink">
              <IconBell height={14} width={14} color="#E6EAEB" />
              <Styled.NavItemText className="NavItemText">
                <span className="text">Notifications</span>
                {!!unreadNotificationsCount && <Styled.UnreadCount>{unreadNotificationsCount}</Styled.UnreadCount>}
              </Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
          <Styled.FooterItem>
            <Styled.LogoutBtn type="button" onClick={handleLogout}>
              <IconLogout />
              <Styled.LogoutBtnText className="FooterItemText">Log out</Styled.LogoutBtnText>
            </Styled.LogoutBtn>
          </Styled.FooterItem>
          <Styled.Version>
            {version}
          </Styled.Version>
        </Styled.Footer>
      </Styled.Menu>
    </Styled.MobileMenu>
  );
};

MobileMenu.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
};

MobileMenu.defaultProps = {
  title: '',
  type: '',
};

export default MobileMenu;
