/* eslint-disable camelcase */
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import {
  Button,
  Table,
  Loader,
  Pagination,
  TextInput,
  UserInfoCell,
} from 'shared/components';
import {
  IconAdjustments,
  IconPlus,
  IconSearch,
} from 'shared/icons';
import api from 'shared/api';
import { useWindowDimensions } from 'shared/HOCs';
// import { capitalizeFirstLetter } from 'shared/helpers';
import { AddUserForm, SearchTypeForm } from '../components';
import * as Styled from './styles';

const YocList = () => {
  const history = useHistory();
  const { isMobile } = useWindowDimensions();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [activeSearch, setActiveSearch] = useState('');
  const [searchType, setSearchType] = useState('full_name');
  const [showSearchTypeForm, setSearchTypeForm] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [isAddUserFormDisplayed, setAddUserFormDisplay] = useState(false);

  const getUserYOC = useCallback((
    searchQuery = undefined,
    page = 0,
  ) => {
    const searchParam = searchQuery ? `&${searchType}=${encodeURIComponent(searchQuery)}` : '';
    api.get(`/api/yoc/users/${userID}/connections?page=${page + 1}${searchParam}&per_page=10&mode=inviter`)
      .then((res) => {
        const yocData = get(res, 'data');
        setPageCount(get(yocData, 'count_pages'));
        setActivePage(page);
        setData(get(yocData, 'records'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userID, searchType]);

  useEffect(() => {
    getUserYOC();
  }, [getUserYOC]);

  const onSearchSubmit = (val) => {
    const { search } = val;
    setActiveSearch(search);
    getUserYOC(search, 0);
  };

  const changeListPage = (val) => {
    const { selected } = val;
    if (selected !== activePage) {
      setActivePage(selected);
      getUserYOC(activeSearch, selected);
    }
  };

  const {
    control,
    handleSubmit,
  } = useForm();

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'display_name',
      Cell: cell => (
        <UserInfoCell
          profileImg={get(cell, 'row.original.profile_picture')}
          name={get(cell, 'value')}
          metaFirst={get(cell, 'row.original.user_name')}
          metaSecond={`${get(cell, 'row.original.num_connections')} connections`}
        />
      ),
      minWidth: 250,
    },
  ], [data]);

  if (isLoading) {
    return <Loader color="#616E7F" />;
  }

  const handleRowClick = row => {
    const { user_id } = row;
    history.push(`/yoc/${user_id}`);
  };

  const searchPlaceholder = searchType === 'full_name' ? 'Search by name' : 'Search by email';

  return (
    <>
      <Styled.ListHeader>
        <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
          <Button size="small" variant="outlined" handleClick={() => setSearchTypeForm(true)}>
            <IconAdjustments />
          </Button>
          <Controller
            name="search"
            control={control}
            defaultValue={activeSearch}
            render={({ field }) => (
              <TextInput
                id="yoc-search"
                name="search"
                placeholder={searchPlaceholder}
                size="small"
                button={<IconSearch />}
                onBtnClick={handleSubmit(onSearchSubmit)}
                {...field}
              />
            )}
          />
        </Styled.ListSearch>
        <Styled.ListAction>
          <Styled.AddBtn type="button" onClick={() => setAddUserFormDisplay(true)}>
            <IconPlus />
          </Styled.AddBtn>
        </Styled.ListAction>
      </Styled.ListHeader>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onFetchData={() => { }}
        onRowClick={handleRowClick}
        rowClassName="YocListRow"
      />
      <Styled.ListPagination>
        <Pagination
          page={activePage}
          pageCount={pageCount}
          handlePageClick={changeListPage}
          pageRangeDisplayed={isMobile ? 1 : 3}
        />
      </Styled.ListPagination>
      {isAddUserFormDisplayed && (
        <AddUserForm
          isOpen={isAddUserFormDisplayed}
          onClose={() => {
            setAddUserFormDisplay(false);
          }}
        />
      )}
      {showSearchTypeForm && (
        <SearchTypeForm
          isOpen={showSearchTypeForm}
          closeCb={() => setSearchTypeForm(false)}
          confirmCb={(val) => {
            setSearchType(val);
            setSearchTypeForm(false);
          }}
          activeType={searchType}
        />
      )}
    </>
  );
};

export default YocList;
