import PropTypes from 'prop-types';

const IconClipboardCheck = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 28 34" fill="none" {...rest} className="IconClipboardCheck">
    <path
      d="M7.6178 3.66665C8.3042 1.72466 10.1563 0.333313 12.3333 0.333313H15.6666C17.8437 0.333313 19.6957 1.72466 20.3821 3.66665H22.3333C25.0947 3.66665 27.3333 5.90522 27.3333 8.66665V28.6666C27.3333 31.4281 25.0947 33.6666 22.3333 33.6666H5.66663C2.9052 33.6666 0.666626 31.4281 0.666626 28.6666V8.66665C0.666626 5.90522 2.9052 3.66665 5.66663 3.66665H7.6178ZM7.6178 6.99998H5.66663C4.74615 6.99998 3.99996 7.74617 3.99996 8.66665V28.6666C3.99996 29.5871 4.74615 30.3333 5.66663 30.3333H22.3333C23.2538 30.3333 24 29.5871 24 28.6666V8.66665C24 7.74617 23.2538 6.99998 22.3333 6.99998H20.3821C19.6957 8.94197 17.8437 10.3333 15.6666 10.3333H12.3333C10.1563 10.3333 8.3042 8.94197 7.6178 6.99998ZM12.3333 3.66665C11.4128 3.66665 10.6666 4.41284 10.6666 5.33331C10.6666 6.25379 11.4128 6.99998 12.3333 6.99998H15.6666C16.5871 6.99998 17.3333 6.25379 17.3333 5.33331C17.3333 4.41284 16.5871 3.66665 15.6666 3.66665H12.3333ZM20.1785 15.8215C20.8293 16.4723 20.8293 17.5276 20.1785 18.1785L13.5118 24.8452C12.8609 25.496 11.8057 25.496 11.1548 24.8452L7.82145 21.5118C7.17057 20.861 7.17057 19.8057 7.82145 19.1548C8.47232 18.5039 9.5276 18.5039 10.1785 19.1548L12.3333 21.3096L17.8214 15.8215C18.4723 15.1706 19.5276 15.1706 20.1785 15.8215Z"
      fill={color}
    />
  </svg>
);

IconClipboardCheck.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconClipboardCheck.defaultProps = {
  color: '#34545D',
  height: 34,
  width: 28,
};

export default IconClipboardCheck;
