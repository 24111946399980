import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const TextArea = React.forwardRef(({
  button,
  disabled,
  error,
  helperText,
  id,
  isRequired,
  label,
  leadingIcon,
  onBtnClick,
  onChange,
  placeholder,
  trailingIcon,
  type,
  value,
  size,
  wide,
  ...rest
}, ref) => (
  <>
    <Styled.TextArea disabled={disabled} hasError={error} size={size} wide={wide} className="TextArea">
      <>{leadingIcon ? <Styled.IconLeading>{leadingIcon}</Styled.IconLeading> : null}</>
      <Styled.TextAreaMain>
        {label && (
          <Styled.LabelContainer>
            <Styled.Label className="Label" htmlFor={id}>{label}</Styled.Label>
            {isRequired && <Styled.LabelRequired className="LabelRequired">(Required)</Styled.LabelRequired>}
          </Styled.LabelContainer>
        )}
        <Styled.Input
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          required={isRequired}
          type={type}
          ref={ref}
          {...rest}
        />
      </Styled.TextAreaMain>
      <>{(trailingIcon && !button) ? <Styled.IconTrailing>{trailingIcon}</Styled.IconTrailing> : null}</>
      <>{button ? <Styled.InputButton type="button" onClick={onBtnClick}>{button}</Styled.InputButton> : null}</>
    </Styled.TextArea>
    <>{helperText && <Styled.HelperText>{helperText}</Styled.HelperText>}</>
    <>{error && <Styled.ErrorText>{error}</Styled.ErrorText>}</>
  </>
));

TextArea.propTypes = {
  button: PropTypes.element,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  wide: PropTypes.bool,
  label: PropTypes.string,
  leadingIcon: PropTypes.element,
  onBtnClick: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  trailingIcon: PropTypes.element,
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextArea.defaultProps = {
  button: null,
  disabled: false,
  wide: false,
  error: '',
  helperText: '',
  isRequired: false,
  label: '',
  leadingIcon: null,
  onBtnClick: () => {},
  onChange: () => {},
  placeholder: '',
  trailingIcon: null,
  type: 'text',
  size: 'large',
  value: '',
};

export default TextArea;
