import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import api from 'shared/api';
import toast from 'react-hot-toast';
import {
  Button,
  PageLoader,
  Page,
  Pagination,
  TextInput,
} from 'shared/components';
import {
  IconSupportFill,
  IconFilter,
  IconFilterOutline,
  IconSearch,
  IconPlus,
} from 'shared/icons';
import { useWindowDimensions } from 'shared/HOCs';
import { getStatusFilterParam, getPriorityFilterParam } from 'shared/helpers';
import TicketsList from './TicketsList';
import { AddTicketForm, TicketFiltersForm } from './components';
import * as Styled from './styles';

const SupportTickets = () => {
  const { isMobile, isTablet } = useWindowDimensions();
  const initialFilters = { status: '', priority: '' };

  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [activeSearch, setActiveSearch] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [showFiltersForm, setFiltersForm] = useState(false);

  const [newTicketDisplayed, setNewTicketDisplay] = useState(false);

  const {
    control,
    handleSubmit,
  } = useForm();

  const getTicketsList = useCallback((
    searchQuery = undefined,
    newFilters = undefined,
    page = 0,
  ) => {
    const searchParam = searchQuery ? `&text=${searchQuery}` : '';
    const statusFilter = getStatusFilterParam(get(newFilters, 'status'));
    const priorityFilter = getPriorityFilterParam(get(newFilters, 'priority'));
    api.get(`/api/user-management/users/${userID}/tickets?page=${page + 1}${searchParam}${statusFilter}${priorityFilter}&per_page=10`)
      .then((res) => {
        setTickets(get(res, 'data.tickets.records'));
        setPageCount(get(res, 'data.tickets.count_pages'));
        setActivePage(page);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [userID]);

  const onSearchSubmit = (data) => {
    const { search } = data;
    setActiveSearch(search);
    getTicketsList(search, filters, 0);
  };

  const handleFiltersChange = (newFilters) => {
    if (!newFilters) {
      setFiltersForm(false);
    } else {
      setFiltersForm(false);
      setFilters(newFilters);
    }
    getTicketsList(activeSearch, newFilters, activePage);
  };

  const changeListPage = (data) => {
    const { selected } = data;
    if (selected !== activePage) {
      setActivePage(selected);
      getTicketsList(activeSearch, filters, selected);
    }
  };

  useEffect(() => {
    getTicketsList();
  }, [getTicketsList]);

  const getFilterNotification = () => {
    if (!filters.status && !filters.priority) return null;
    let value;
    if (filters.status && filters.priority) {
      value = 2;
    } else {
      value = 1;
    }

    return <Styled.FilterNotification>{value}</Styled.FilterNotification>;
  };

  if (isLoading) {
    return (
      <Page type="support" title="Support tickets"><PageLoader /></Page>
    );
  }

  return (
    <Page type="support" title="Support tickets">
      <Styled.TicketsWrapper>
        <Styled.TicketsList>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconSupportFill /></Styled.TitleIcon>
              <Styled.TitleText>Support tickets</Styled.TitleText>
            </Styled.ListTitle>
            <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
              <Controller
                name="search"
                control={control}
                defaultValue={activeSearch}
                render={({ field }) => (
                  <TextInput
                    id="tickets-search"
                    name="search"
                    placeholder="Search by ticket text"
                    size="small"
                    button={<IconSearch />}
                    onBtnClick={handleSubmit(onSearchSubmit)}
                    {...field}
                  />
                )}
              />
            </Styled.ListSearch>
            <Styled.ListAction>
              <Styled.FilterBtn type="button" onClick={() => setFiltersForm(true)}>
                {isTablet ? <IconFilterOutline /> : <IconFilter />}
                <Styled.FilterBtnText>Filters</Styled.FilterBtnText>
                {getFilterNotification()}
              </Styled.FilterBtn>
            </Styled.ListAction>
          </Styled.ListHeader>
          <TicketsList data={tickets} refreshTickets={getTicketsList} />
          <Styled.ListPagination>
            <Pagination
              page={activePage}
              pageCount={pageCount}
              handlePageClick={changeListPage}
              pageRangeDisplayed={isMobile ? 1 : 3}
            />
          </Styled.ListPagination>
        </Styled.TicketsList>
        <Styled.AddTicketBtn>
          <Button
            wide
            handleClick={() => setNewTicketDisplay(true)}
          >
            <Styled.ButtonInner>
              <IconPlus />
              New request
            </Styled.ButtonInner>
          </Button>
        </Styled.AddTicketBtn>
        {newTicketDisplayed && (
          <AddTicketForm
            isOpen={newTicketDisplayed}
            refreshTickets={getTicketsList}
            onClose={() => setNewTicketDisplay(false)}
          />
        )}
        {showFiltersForm && (
          <TicketFiltersForm
            isOpen={showFiltersForm}
            closeCb={handleFiltersChange}
            filters={filters}
          />
        )}
      </Styled.TicketsWrapper>
    </Page>
  );
};

export default SupportTickets;
