import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Page,
  PageLoader,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { IconKey, IconUpwardArrow } from 'shared/icons';
import { checkKycStatus } from 'shared/helpers';
import api from 'shared/api';
import DealRoomFiles from '../components/DealRoomFiles';
import DealRoomMembers from '../components/DealRoomMembers';
import InvestForm from '../components/InvestForm';
import * as Styled from './styles';

const DealRoomPage = () => {
  const params = useParams();
  const { dealRoomID } = params;
  const user = useSelector(state => get(state, 'account.user'));
  const kYCStatus = checkKycStatus(get(user, 'status'));
  const isUserValidated = kYCStatus.isValidated;

  const [isLoading, setLoading] = useState(true);
  const [dealRoomData, setDealRoomData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [offeringData, setOfferingData] = useState(null);
  // members
  const [dealRoomMembers, setDealRoomMembers] = useState([]);
  const [dealRoomMembersLoading, setDealRoomMembersLoading] = useState(false);
  // owners
  const [offeringOwners, setOfferingOwners] = useState([]);
  const [offeringOwnersLoading, setOfferingOwnersLoading] = useState(false);
  // files
  const [dealRoomFiles, setDealRoomFiles] = useState([]);
  const [dealRoomFilesLoading, setDealRoomFilesLoading] = useState(false);
  // investment
  const [isInvestPopupDisplayed, setInvestPopupDisplay] = useState(false);

  const geOfferingOwners = useCallback((offeringID) => {
    api.get(`/api/investing/offerings/${offeringID}/owners`)
      .then((res) => {
        setOfferingOwners(get(res, 'data'));
        setOfferingOwnersLoading(false);
      })
      .catch(() => {
        setOfferingOwnersLoading(false);
      });
  }, [dealRoomID]);

  const getOffering = useCallback((offeringID) => {
    api.get(`/api/investing/offerings/${offeringID}`)
      .then((res) => {
        setOfferingData(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const getDealRoom = useCallback(() => {
    api.get(`/api/investing/deal-rooms/${dealRoomID}`)
      .then((res) => {
        const offeringID = get(res, 'data.offering_id');
        setDealRoomData(get(res, 'data'));
        geOfferingOwners(offeringID);
        getOffering(offeringID);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dealRoomID]);

  const getDealRoomMembers = useCallback(() => {
    api.get(`/api/investing/deal-rooms/${dealRoomID}/members`)
      .then((res) => {
        setDealRoomMembers(get(res, 'data'));
        setDealRoomMembersLoading(false);
      })
      .catch(() => {
        setDealRoomMembersLoading(false);
      });
  }, [dealRoomID]);

  const getDealRoomFiles = useCallback(() => {
    api.get(`/api/investing/deal-rooms/${dealRoomID}/files`)
      .then((res) => {
        setDealRoomFiles(get(res, 'data'));
        setDealRoomFilesLoading(false);
      })
      .catch(() => {
        setDealRoomFilesLoading(false);
      });
  }, [dealRoomID]);

  useEffect(() => {
    getDealRoom();
    getDealRoomMembers();
    getDealRoomFiles();
  }, [getDealRoom, getDealRoomMembers, getDealRoomFiles]);

  const handleInvestClick = () => {
    if (!isUserValidated) {
      toast.error('Complete the mandatory identity verification module so you can complete the investment.');
      return false;
    }
    setInvestPopupDisplay(true);
    return true;
  };

  if (isLoading) {
    return <Page showBack type="dealrooms" title="Dealrooms"><PageLoader /></Page>;
  }

  const dataLoading = dealRoomMembersLoading || offeringOwnersLoading || dealRoomFilesLoading;
  const isUserDealroomMember = dealRoomMembers.find(o => get(o, 'profile.user_id') === get(user, 'user_id'));
  const isUserDealroomOwner = get(isUserDealroomMember, 'member.status') === 'owner';

  return (
    <Page type="dealrooms" title="Dealroom">
      <Styled.Dataroom>
        <Styled.DataroomTabs>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconKey color="#7A7A7A" height={18} width={18} /></Styled.TitleIcon>
              <Styled.TitleText>
                {get(dealRoomData, 'name')}
              </Styled.TitleText>
            </Styled.ListTitle>
            <Styled.ListAction>
              <Button handleClick={handleInvestClick}>
                <IconUpwardArrow />
                <span>Invest</span>
              </Button>
            </Styled.ListAction>
          </Styled.ListHeader>
          <Tabs>
            <TabsHeader>
              <Tab>People</Tab>
              <Tab>Dealroom files</Tab>
            </TabsHeader>
            <TabContent>
              <DealRoomMembers
                dealRoom={dealRoomData}
                members={dealRoomMembers}
                owners={offeringOwners}
                loading={dataLoading}
                refetchMembers={getDealRoomMembers}
                isUserDealroomOwner={isUserDealroomOwner}
                isUserDealroomMember={isUserDealroomMember}
              />
            </TabContent>
            <TabContent>
              <DealRoomFiles
                dealRoom={dealRoomData}
                files={dealRoomFiles}
                members={dealRoomMembers}
                owners={offeringOwners}
                loading={dataLoading}
                refetchFiles={getDealRoomFiles}
              />
            </TabContent>
          </Tabs>
        </Styled.DataroomTabs>
        {isInvestPopupDisplayed && (
          <InvestForm
            offering={offeringData}
            closeCb={() => setInvestPopupDisplay(false)}
            confirmCb={() => {}}
            isOpen={isInvestPopupDisplayed}
          />
        )}
      </Styled.Dataroom>
    </Page>
  );
};

export default DealRoomPage;
