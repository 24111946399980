import styled from 'styled-components';

export const OrganizationPage = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 920px;
  width: 100%;
`;

export const Page = styled.div`
  display: flex;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 240px;
  margin-right: 24px;

  .TextInput {
    min-width: unset;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    max-width: unset;
    margin-right: 0;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 8px;
  width: 240px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-height: 240px;
  object-fit: cover;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    max-height: 150;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Data = styled.div`
  width: 100%;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  button {
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.colors.shades.neutral.white};

    &:hover {
      background-color: ${props => props.theme.colors.shades.neutral.white};
    }
  }
`;
