/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const IndicatorDot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};

  ${props => {
    if (props.isActive) {
      return css`
        background-color: ${() => props.theme.colors.shades.neutral.white};
        border-width: 1px;
        border-style: solid;
        border-color: ${() => props.theme.colors.primary.daintree.daintree50};
      `;
    }
  }}
`;
