import { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Alert,
  Button,
  Loader,
  TextInput,
} from 'shared/components';
import { getUUID } from 'shared/helpers';
import api from 'shared/api';
import {
  invitation,
  invitationError,
  invitationSuccess,
  saveInvitation,
} from 'redux/account/actions';
import { ReactComponent as Logo } from 'shared/assets/logo_long_white.svg';
import { IconPasswordHide, IconPasswordShow } from 'shared/icons';
import config from 'config';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(),
  invitee_full_name: yup
    .string()
    .required(),
});

const Register = () => {
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [invitationDetails, setInvitationDetails] = useState(null);

  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const { search } = location;
  const hasUUID = search.includes('uuid');
  const uuid = hasUUID ? getUUID(search) : '';

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const getInvitationDetails = () => {
    api.get(`/api/yoc/invitations/${uuid}`)
      .then((res) => {
        const data = get(res, 'data');
        const isConfirmed = get(data, 'confirmed');

        if (isConfirmed) {
          history.push('/login');
        } else {
          setInvitationDetails(data);
          dispatch(saveInvitation(data));
          reset(data);
          setLoading(false);
        }
      })
      .catch(() => {
        history.push('/login');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
    if (!hasUUID) {
      history.push('/login');
    }
    getInvitationDetails();
  }, [currentUserID, history]);

  const [displayPassword, setPasswordDisplay] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    const registerData = {
      password: data.password,
      invitee_full_name: data.invitee_full_name,
    };
    setLoading(true);

    invitation(uuid, registerData)
      .then((res) => {
        const userData = get(res, 'data');
        dispatch(invitationSuccess(userData));
        history.push('/');
      })
      .catch((err) => {
        setLoading(false);
        dispatch(invitationError(err));
        toast.error('Unable to finish invitation process');
      });
    return true;
  };

  if (!invitationDetails) {
    return (
      <Styled.Register>
        <Styled.RegisterForm>
          <Styled.RegisterLogo>
            <Logo height={40} width={200} />
          </Styled.RegisterLogo>
          <Loader />
        </Styled.RegisterForm>
      </Styled.Register>
    );
  }

  const isExpired = moment(get(invitationDetails, 'expires_at')).isBefore();

  if (isExpired) {
    return (
      <Styled.Register>
        <Styled.RegisterForm>
          <Styled.RegisterLogo>
            <Logo height={40} width={200} />
          </Styled.RegisterLogo>
          <Alert
            isOpen
            primaryBtnText="Go to login"
            onPrimaryBtnClick={() => history.push('/login')}
            title="Invitation has expired"
            text="This invitation has expired. Please contact support to request a new one."
          />
        </Styled.RegisterForm>
      </Styled.Register>
    );
  }

  return (
    <Styled.Register>
      <Styled.RegisterForm>
        <Styled.RegisterLogo>
          <Logo height={40} width={200} />
        </Styled.RegisterLogo>
        <Styled.RegisterMain onSubmit={handleSubmit(onSubmit)}>
          <Styled.Heading>Welcome</Styled.Heading>
          <Styled.Description>First let’s create a password to protect your account.</Styled.Description>
          <Styled.Title>Complete Signup</Styled.Title>
          <Styled.InputWrapper>
            <TextInput
              id="invitee_email"
              label="Your email"
              placeholder=""
              value={get(invitationDetails, 'invitee')}
              error=""
              type="email"
              disabled
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Controller
              name="invitee_full_name"
              control={control}
              defaultValue={get(invitationDetails, 'invitee_full_name') || ''}
              render={({ field }) => (
                <TextInput
                  id="invitee_full_name"
                  label="Your name"
                  placeholder=""
                  error={errors.invitee_full_name?.message}
                  type="text"
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="password"
                  label="Create password"
                  placeholder="Your password"
                  error={errors.password?.message}
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.LoginReroute>
            <span>{t('Already have an account?')}</span>
            <Link href="/login">{t('Login')}</Link>
          </Styled.LoginReroute>
          <Button
            size="large"
            type="submit"
            isLoading={isLoading}
            loaderColor="#fff"
          >
            {t('Register')}
          </Button>
          <Styled.Acknowledge>
            <p>
              By clicking Register you agree to Kobo Funds platform
              {' '}
              <a href={`${config.website_url}/terms`} target="_blank" rel="noopener noreferrer" data-scroll>Terms & Conditions</a>
              ,
              {' '}
              <a href={`${config.website_url}/privacy`} target="_blank" rel="noopener noreferrer" data-scroll>Privacy policy</a>
              {' '}
              and
              {' '}
              <a href={`${config.website_url}/nda`} target="_blank" rel="noopener noreferrer" data-scroll>Non disclosure agreement</a>
              {' '}
              of all the Confidential Information received through the platform.
            </p>
          </Styled.Acknowledge>
        </Styled.RegisterMain>
      </Styled.RegisterForm>
      <Styled.RegisterBackground />
    </Styled.Register>
  );
};

export default Register;
