import PropTypes from 'prop-types';

const IconNoProfilePicture20 = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} className="IconNoProfilePicture20">
    <rect width={width} height={height} rx={width / 2} fill="#B4B1AA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 5.55545C9.07963 5.55545 8.33344 6.30164 8.33344 7.22211C8.33344 8.14259 9.07963 8.88878 10.0001 8.88878C10.9206 8.88878 11.6668 8.14259 11.6668 7.22211C11.6668 6.30164 10.9206 5.55545 10.0001 5.55545ZM7.22233 7.22211C7.22233 5.68799 8.46598 4.44434 10.0001 4.44434C11.5342 4.44434 12.7779 5.68799 12.7779 7.22211C12.7779 8.75624 11.5342 9.99989 10.0001 9.99989C8.46598 9.99989 7.22233 8.75624 7.22233 7.22211ZM6.71286 14.4443H13.2874C13.0229 12.8678 11.6518 11.6666 10.0001 11.6666C8.34844 11.6666 6.97735 12.8678 6.71286 14.4443ZM5.55566 14.9999C5.55566 12.5453 7.54551 10.5554 10.0001 10.5554C12.4547 10.5554 14.4446 12.5453 14.4446 14.9999C14.4446 15.3067 14.1958 15.5554 13.889 15.5554H6.11122C5.80439 15.5554 5.55566 15.3067 5.55566 14.9999Z"
      fill={color}
    />
  </svg>
);

IconNoProfilePicture20.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconNoProfilePicture20.defaultProps = {
  color: '#fff',
  height: 20,
  width: 20,
};

export default IconNoProfilePicture20;
