import PropTypes from 'prop-types';

const IconSupportFill = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...rest} className="IconSupportFill">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 8.99261 13.759 9.92893 13.3322 10.7536L11.8076 9.22903C11.9325 8.8418 12 8.42878 12 8C12 7.67136 11.9604 7.35198 11.8856 7.04638L13.4484 5.4836C13.8024 6.24889 14 7.10137 14 8ZM8.83457 11.9128L10.4156 13.4939C9.67657 13.8193 8.85938 14 8 14C7.10137 14 6.24889 13.8024 5.4836 13.4484L7.04638 11.8856C7.35198 11.9604 7.67136 12 8 12C8.2862 12 8.56539 11.9699 8.83457 11.9128ZM4.15807 9.11708C4.05516 8.76255 4 8.38772 4 8C4 7.66808 4.04043 7.3456 4.11663 7.03722L4.0378 7.11604L2.50611 5.58436C2.18068 6.32343 2 7.14061 2 8C2 8.95386 2.22258 9.85573 2.61864 10.6565L4.15807 9.11708ZM5.24639 2.66778C6.07107 2.24104 7.00739 2 8 2C8.95386 2 9.85573 2.22258 10.6565 2.61864L9.11708 4.15807C8.76255 4.05516 8.38772 4 8 4C7.57122 4 7.1582 4.06747 6.77097 4.19236L5.24639 2.66778ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
      fill={color}
    />
  </svg>
);

IconSupportFill.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconSupportFill.defaultProps = {
  color: '#616E7F',
  height: 16,
  width: 16,
};

export default IconSupportFill;
