import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  TextInput,
} from 'shared/components';
import api from 'shared/api';
import { appName } from 'shared/constants';
import { ReactComponent as Logo } from 'shared/assets/logo_long_white.svg';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

const ForgotPassword = () => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    setLoading(true);
    const formData = {
      user_name: data.email.trim(),
      app_name: appName,
    };
    api.post('/api/user-management/reset-password', formData)
      .then(() => {
        setSubmitted(true);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
  }, [currentUserID, history]);

  if (isSubmitted) {
    return (
      <Styled.ForgotPassword>
        <Styled.ForgotPasswordForm>
          <Styled.ForgotPasswordLogo>
            <Logo height={40} width={200} />
          </Styled.ForgotPasswordLogo>
          <Styled.ForgotPasswordMainSubmitted onSubmit={handleSubmit(onSubmit)}>
            <Styled.Heading>Password recovery link has been sent</Styled.Heading>
            <Styled.Description>
              Check your email and click on the link we sent to continue
            </Styled.Description>
            <Styled.BackToLogin className="BackToLogin">
              <Link to="/login">Back to login</Link>
            </Styled.BackToLogin>
          </Styled.ForgotPasswordMainSubmitted>
        </Styled.ForgotPasswordForm>
        <Styled.ForgotPasswordBackground />
      </Styled.ForgotPassword>
    );
  }

  return (
    <Styled.ForgotPassword>
      <Styled.ForgotPasswordForm>
        <Styled.ForgotPasswordLogo>
          <Logo height={40} width={200} />
        </Styled.ForgotPasswordLogo>
        <Styled.ForgotPasswordMain onSubmit={handleSubmit(onSubmit)}>
          <Styled.Heading>Forgot your password?</Styled.Heading>
          <Styled.Description>Enter your email and we will send you a link to reset your password</Styled.Description>
          <Styled.InputWrapper>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="email-input"
                  label="Email"
                  placeholder="Enter email"
                  type="email"
                  error={errors.email?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputWrapper>
          <Button
            size="large"
            wide
            type="submit"
            handleClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Styled.ForgotPasswordMain>
        <Styled.BackToLogin>
          <Link to="/login">Back to login</Link>
        </Styled.BackToLogin>
      </Styled.ForgotPasswordForm>
      <Styled.ForgotPasswordBackground />
    </Styled.ForgotPassword>
  );
};

export default ForgotPassword;
