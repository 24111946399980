import PropTypes from 'prop-types';
import { IconPlus } from 'shared/icons';
import * as Styled from './styles';

const AddEntityBtn = ({
  text,
  description,
  onClick,
}) => (
  <Styled.AddEntityBtn type="button" onClick={onClick}>
    <Styled.Main>
      <Styled.Icon>
        <IconPlus width={18} height={18} color="#012934" />
      </Styled.Icon>
      {text && <Styled.Text>{text}</Styled.Text>}
    </Styled.Main>
    {description && (
      <Styled.Desc>{description}</Styled.Desc>
    )}
  </Styled.AddEntityBtn>
);

AddEntityBtn.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};

AddEntityBtn.defaultProps = {
  text: '',
  description: '',
  onClick: () => { },
};

export default AddEntityBtn;
