import styled from 'styled-components';

export const KycConfirm = styled.div`
  padding: 22px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-width: 920px;
  background-color: ${props => props.theme.colors.shades.neutral.white};
`;

export const LoadingScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Icon = styled.div`
  flex-shrink: 0;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.neutral.gray90};
  margin-bottom: 8px;
  margin-top: 35px;
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.neutral.gray90};
  margin-bottom: 48px;
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
  margin-bottom: 8px;
`;

export const DataContainer = styled.div`
  .TextInput {
    margin-bottom: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .Button {
    width: 100%;
  }
`;
