import styled from 'styled-components';

export const Tabs = styled.div`
  .react-tabs__tab-list {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};
  }

  .react-tabs__tab-panel--selected {
    .Button {
      margin-top: 0;
    }
  }

  .react-tabs__tab {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
    outline: 0;
    bottom: -1px;
    border: 0;
    border-bottom-color: transparent;
    color: ${props => props.theme.colors.shades.warm.dawn90};

    @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 14px;
      padding: 6px 16px;
    }
    
    &:focus,
    &:hover {
      border: 0;
      border-bottom-color: transparent;
      box-shadow: none;
      outline: 0;
      color: black;
    }

    &:after {
      display: none;
    }
  }

  .react-tabs__tab--selected {
    background: transparent;
    color: ${props => props.theme.colors.primary.daintree.daintree50};
    border: 0;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.colors.primary.daintree.daintree50};
    font-weight: bold;
    outline: 0;

    &:focus,
    &:hover {
      border: 0;
      border-bottom-width: 4px;
      border-bottom-style: solid;
      border-bottom-color: ${props => props.theme.colors.primary.daintree.daintree50};
      box-shadow: none;
      outline: 0;
      color: ${props => props.theme.colors.primary.daintree.daintree50};
    }
  }

  .react-tabs__tab--disabled {
    color: ${props => props.theme.colors.shades.warm.dawn40};
    cursor: not-allowed;

    &:hover {
      color: ${props => props.theme.colors.shades.warm.dawn40};
    }
  }

`;
