import styled from 'styled-components';

export const Checkbox = styled.div`
  position: relative;

  svg {
    display: none;
    pointer-events: none;
  }

  input {
    position: absolute;
    left: -9999px;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      display: inline-flex;
      align-items: center;
      color: ${props => props.theme.colors.shades.warm.dawn80};
      line-height: 24px;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: ${props => props.theme.colors.shades.neutral.white};
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.shades.warm.dawn60};
    }

    &:hover + label:before,
    &:focus + label:before {
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    &:checked + label:before {
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
      background: ${props => props.theme.colors.shades.neutral.black};
    }

    &:checked + label::before {
      background: black;
    }

    &:checked + label + svg {
      display: inline-flex;
      position: absolute;
      top: 6px;
      left: 4px;
    }
    
    &:disabled + label {
      color: ${props => props.theme.colors.primary.daintree.daintree50};
      cursor: auto;
    }
    
    &:disabled + label:before {
      background: ${props => props.theme.colors.shades.warm.dawn40};
      opacity: 0.5;
    }
  }
`;
