/* eslint-disable func-names */
import * as actionTypes from './actionTypes';

const initialState = {
  unreadNotifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.refreshUnreadNotifications: {
      return { ...state, unreadNotifications: action.list };
    }
    default:
      return Object.assign(state);
  }
}
