import { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { canCreateAsset, isInvestor, checkKycStatus } from 'shared/helpers';
import AllOfferings from './AllOfferings';
import MyOfferings from './MyOfferings';
import MyInvestments from './MyInvestments';
import { OfferingForm } from '../components';
import * as Styled from './styles';

const OfferingsList = () => {
  const organizations = useSelector(state => get(state, 'investing.organizations'));
  const user = useSelector(state => get(state, 'account.user'));
  const isAllowedToCreate = canCreateAsset(user, false);
  const kYCStatus = checkKycStatus(get(user, 'status'));
  const validated = kYCStatus.isValidated;
  const canInvest = isInvestor(user);

  const [isFormDisplayed, setFormDisplay] = useState(false);

  const handleAddOfferingBtn = () => {
    if (!isAllowedToCreate) {
      toast.error('You are not authorized to create offerings.');
      return false;
    }
    if (!validated) {
      toast.error('Complete the mandatory identity verification module to be able to use this feature.');
      return false;
    }
    if (!organizations || !organizations.length) {
      return toast.error('Add organization first before creating offering');
    }
    return setFormDisplay(true);
  };

  return (
    <Page type="offerings" title="Offerings">
      <Styled.OfferingsWrapper>
        <Styled.OfferingsList>
          <Tabs>
            <TabsHeader>
              <Tab>All offerings</Tab>
              <Tab>My offerings</Tab>
              {canInvest && <Tab>My investments</Tab>}
            </TabsHeader>
            <TabContent>
              <AllOfferings onAddBtnClick={handleAddOfferingBtn} user={user} isAllowedToCreate={isAllowedToCreate} />
            </TabContent>
            <TabContent>
              <MyOfferings onAddBtnClick={handleAddOfferingBtn} user={user} isAllowedToCreate={isAllowedToCreate} />
            </TabContent>
            {canInvest && (
              <TabContent>
                <MyInvestments />
              </TabContent>
            )}
          </Tabs>
        </Styled.OfferingsList>
        {isFormDisplayed && (
          <OfferingForm
            isOpen={isFormDisplayed}
            closeCb={() => setFormDisplay(false)}
            offering={null}
          />
        )}
      </Styled.OfferingsWrapper>
    </Page>
  );
};

export default OfferingsList;
