import styled from 'styled-components';

export const ExternalCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  border-top-width: 5px;
  border-top-color: ${props => props.theme.colors.danger.danger50};
  padding: 8px 16px 20px;
  max-width: 300px;
  min-height: 110px;
  margin-right: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: unset;
    margin-right: 0;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;
