import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';

const UnfinishedProfileCard = ({
  userProfile,
}) => {
  const history = useHistory();
  const userName = get(userProfile, 'full_name');
  const userTagline = get(userProfile, 'title');
  const userBio = get(userProfile, 'bio');
  const userCity = get(userProfile, 'city');
  const userCountry = get(userProfile, 'country_of_residence');
  const isUnfinished = !userName || !userTagline || !userBio || !userCity || !userCountry;

  if (!isUnfinished) {
    return null;
  }

  return (
    <Styled.UnfinishedProfileCard type="button" onClick={() => history.push('/profile')}>
      <Styled.Title>Finish setting up your profile</Styled.Title>
      <Styled.Text>Make sure that your name, tagline, bio, city and country fields are filled.</Styled.Text>
    </Styled.UnfinishedProfileCard>
  );
};

UnfinishedProfileCard.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

export default UnfinishedProfileCard;
