import PropTypes from 'prop-types';
import { Button } from 'shared/components';
import { IconCamera, IconReverse } from 'shared/icons';
import * as Styled from './styles';

const SnapSelfie = ({
  onImageTake,
  onCameraReverse,
  // numberOfCameras,
}) => (
  <Styled.TakeImage className="SnapSelfie">
    {/* {numberOfCameras <= 1 ? <div style={{ width: '48px', height: '48px' }} /> : (
      <Styled.Retry>
        <Button handleClick={onCameraReverse} variant="outlined"><IconReverse /></Button>
      </Styled.Retry>
    )} */}
    <Styled.Retry>
      <Button handleClick={onCameraReverse} variant="outlined"><IconReverse /></Button>
    </Styled.Retry>
    <Button handleClick={onImageTake}><IconCamera /></Button>
    <div style={{ width: '48px', height: '48px' }} />
  </Styled.TakeImage>
);

SnapSelfie.propTypes = {
  onImageTake: PropTypes.func.isRequired,
  onCameraReverse: PropTypes.func.isRequired,
  // numberOfCameras: PropTypes.number.isRequired,
};

export default SnapSelfie;
