import styled from 'styled-components';

export const Selector = styled.div`
  max-width: 400px;
  width: 100%;
  position: relative;

  .react-dropdown-select {
    max-width: 400px;
    min-height: 48px;
    padding: 22px 6px 12px 12px;
    align-items: flex-end;
  }

  .Select {
    border-radius: 0;
    background: #fff;
    font-size: 14px;
    min-height: 64px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.shades.warm.dawn30};

    &:focus,
    &:active,
    &:hover,
    &:focus-within {
      box-shadow: none;
      border: none;
    }

    &:focus,
    &:focus-within {
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    &:hover {
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    &:disabled {
      background: ${props => props.theme.colors.shades.warm.dawn05};
      border-width: 1px;
      border-style: solid;
      background: ${props => props.theme.colors.shades.warm.dawn30};
    }

    .react-dropdown-select-content {
      max-width: 400px;
      background: #fff;
      color: ${props => props.theme.colors.shades.neutral.black};
      font-size: 16px;
    }

    .react-dropdown-select-input {
      font-size: 16px;
      font-family: 'IBM Plex Sans',-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen', 'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue', sans-serif;
    }

    .react-dropdown-select-type-multi {
      background-color: transparent;

      .react-dropdown-select-option {
        background: ${props => props.theme.colors.primary.daintree.daintree40};
        border-radius: 0;
        font-size: 12px;
        margin: 3px 5px 3px 0;
      }
    }

    .react-dropdown-select-separator {
      margin-right: 8px;
      border-left-color: ${props => props.theme.colors.shades.warm.dawn30};
      align-self: center;
    }

    .react-dropdown-select-dropdown-handle {
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;

      svg {
        width: unset;
        height: unset;
      }
    }

    .react-dropdown-select-dropdown {
      max-width: 400px;
      width: 100%;
      border: none;
      border-radius: 0;
      box-shadow: 0px 16px 64px #D9D8D5;
      background: #fff;
      z-index: 999;

      .react-dropdown-select-item {
        display: flex;
        align-items: center;
        height: 48px;
        font-size: 14px;
        color: ${props => props.theme.colors.shades.warm.dawn70};

        &:hover {
          background-color: ${props => props.theme.colors.shades.warm.dawn05};
        }

        &.react-dropdown-select-item-selected {
          background-color: ${props => props.theme.colors.primary.flamingo.flamingo50};
          color: ${props => props.theme.colors.shades.neutral.white};
        }
      }
    }
  }
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 99;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.danger.danger50};
  font-size: 14px;
  margin-top: 4px;
`;
