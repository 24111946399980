import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import api from 'shared/api';
import { formatDate } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import { useWindowDimensions } from 'shared/HOCs';
import {
  PageLoader,
  Table,
} from 'shared/components';
import * as Styled from './styles';

const OfferingInvestments = ({
  offering,
}) => {
  const { isMobile } = useWindowDimensions();
  const offeringID = get(offering, 'id');
  const currencies = useSelector(state => get(state, 'investing.currencies'));

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getInvestments = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/investments`)
      .then((res) => {
        setData(get(res, 'data') || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getInvestments();
  }, [getInvestments]);

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (cell) => {
        const amount = get(cell, 'value');
        const currencyID = get(cell, 'cell.row.original.currency_id');
        const currency = currencies.find(c => get(c, 'id') === currencyID);
        return `${amount} ${get(currency, 'symbol')}`;
      },
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: cell => formatDate(get(cell, 'value'), defaultDateFormat),
      show: !isMobile,
    },
  ], [data]);

  if (isLoading) {
    return <Styled.OfferingInvestments><PageLoader /></Styled.OfferingInvestments>;
  }

  return (
    <Styled.OfferingInvestments>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable={false}
        rowClassName="UsersListRow"
      />
    </Styled.OfferingInvestments>
  );
};

OfferingInvestments.propTypes = {
  offering: PropTypes.object.isRequired,
};

export default OfferingInvestments;
