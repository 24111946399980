import PropTypes from 'prop-types';

const IconArrowUp = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 17" fill="none" {...rest} className="IconArrowDown" style={{ transform: 'rotate(180deg)' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 8.79289C14.0976 9.18342 14.0976 9.81658 13.7071 10.2071L7.70711 16.2071C7.31658 16.5976 6.68342 16.5976 6.29289 16.2071L0.292894 10.2071C-0.0976312 9.81658 -0.0976312 9.18342 0.292894 8.79289C0.683418 8.40237 1.31658 8.40237 1.70711 8.79289L6 13.0858L6 1.5C6 0.947716 6.44772 0.5 7 0.5C7.55229 0.5 8 0.947716 8 1.5L8 13.0858L12.2929 8.79289C12.6834 8.40237 13.3166 8.40237 13.7071 8.79289Z"
      fill={color}
    />
  </svg>
);

IconArrowUp.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconArrowUp.defaultProps = {
  color: '#817E77',
  height: 17,
  width: 14,
};

export default IconArrowUp;
