import styled from 'styled-components';

export const OfferingsWrapper = styled.section`
  width: 100%;
  /* height: calc(100vh - 90px); */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 90px);
  display: flex;
  justify-content: center;
  position: relative;
`;

export const OfferingsList = styled.div`
  width: 100%;
  max-width: 920px;
  padding: 0 10px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0;
  }
`;
