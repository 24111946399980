/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  PageLoader,
  UserInfoCell,
} from 'shared/components';
import api from 'shared/api';
import { sendErrorReport } from 'shared/errorReport';
import { getErrMsg } from 'shared/helpers';
import { CircleSelect } from 'Yoc/components';
import { saveInvitation } from 'redux/account/actions';
import * as Styled from './styles';

const InvitationDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const invitation = useSelector(state => get(state, 'account.invitation'));
  const inviterID = get(invitation, 'inviter_id') || '';

  const currentUser = useSelector(state => get(state, 'account.user'));
  const inviteeID = get(currentUser, 'user_id');

  const [isLoading, setLoading] = useState(false);
  const [isProfileLoading, setProfileLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [connectBackTopology, setConnectBackTopology] = useState(null);

  const inviterName = get(profileData, 'full_name') || '';

  const getProfile = useCallback(() => {
    api.get(`/api/user-management/user-profiles/${inviterID}`)
      .then((res) => {
        setProfileData(get(res, 'data.user'));
        setProfileLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'cannot_get_user_profile', inviterID);
        setProfileLoading(false);
      });
  }, [inviterID]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const dismissConnection = () => {
    if (isLoading) {
      return false;
    }

    dispatch(saveInvitation(null));
    history.push('/');
    return true;
  };

  const addConnection = () => {
    if (isLoading) {
      return false;
    }

    setLoading(true);
    const data = { user_id: inviterID, type: get(connectBackTopology, 'name') };
    api.post(`/api/yoc/users/${inviteeID}/connections`, data)
      .then(() => {
        toast.success(`You have been succesfully connected with ${inviterName}`);
        setLoading(false);
        dispatch(saveInvitation(null));
        history.push('/');
      })
      .catch((err) => {
        sendErrorReport(err, 'cannot_connect_back_invitation', data);
        const errMsg = getErrMsg(err);
        toast.error(errMsg || 'Error occured');
        setLoading(false);
      });
    return true;
  };

  if (isProfileLoading) {
    return (
      <Styled.InvitationDetails>
        <Styled.Main>
          <Styled.Form>
            <PageLoader />
          </Styled.Form>
        </Styled.Main>
      </Styled.InvitationDetails>
    );
  }

  return (
    <Styled.InvitationDetails>
      <Styled.Main>
        <Styled.Form>
          <Styled.Heading>Welcome!</Styled.Heading>
          <Styled.Description>
            {`You have been invited by ${inviterName}, do you want to connect back?`}
          </Styled.Description>
          <Styled.ProfileCell>
            <UserInfoCell
              profileImg={get(profileData, 'profile_picture')}
              name={get(profileData, 'full_name')}
              metaFirst={get(profileData, 'title')}
            />
          </Styled.ProfileCell>
          <Styled.InputWrapper>
            <Styled.SliderLabel>How close are you?</Styled.SliderLabel>
            <CircleSelect onAnswerSelect={val => setConnectBackTopology(val)} />
          </Styled.InputWrapper>
          <Styled.ButtonsContainer>
            <Button
              size="large"
              wide
              isLoading={isLoading}
              loaderColor="#fff"
              handleClick={addConnection}
            >
              Connect
            </Button>
            <Button
              size="large"
              variant="outlined"
              wide
              isLoading={isLoading}
              loaderColor="#fff"
              handleClick={dismissConnection}
            >
              Dismiss
            </Button>
          </Styled.ButtonsContainer>
        </Styled.Form>
      </Styled.Main>
    </Styled.InvitationDetails>
  );
};

export default InvitationDetails;
