import PropTypes from 'prop-types';

const IconUserOutline = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 20" fill="none" {...rest} className="IconUserOutline">
    <path
      d="M8 2C6.34315 2 5 3.34315 5 5C5 6.65685 6.34315 8 8 8C9.65685 8 11 6.65685 11 5C11 3.34315 9.65685 2 8 2ZM3 5C3 2.23858 5.23858 0 8 0C10.7614 0 13 2.23858 13 5C13 7.76142 10.7614 10 8 10C5.23858 10 3 7.76142 3 5ZM2.08296 18H13.917C13.441 15.1623 10.973 13 8 13C5.027 13 2.55904 15.1623 2.08296 18ZM0 19C0 14.5817 3.58172 11 8 11C12.4183 11 16 14.5817 16 19C16 19.5523 15.5523 20 15 20H1C0.447715 20 0 19.5523 0 19Z"
      fill={color}
    />
  </svg>
);

IconUserOutline.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUserOutline.defaultProps = {
  color: '#012934',
  height: 20,
  width: 16,
};

export default IconUserOutline;
