/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import * as Styled from './styles';

const Switcher = ({
  disabled,
  id,
  label,
  onChange,
  value,
  ...rest
}) => (
  <Styled.Switcher>
    <Styled.Label htmlFor={id}>
      <Toggle
        checked={value}
        className="Toggle"
        onChange={onChange}
        id={id}
        disabled={disabled}
        icons={false}
        {...rest}
      />
      <Styled.LabelText>{label}</Styled.LabelText>
    </Styled.Label>
  </Styled.Switcher>
);

Switcher.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

Switcher.defaultProps = {
  disabled: false,
  label: '',
  value: false,
};

export default Switcher;
