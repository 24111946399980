import { Route, Switch } from 'react-router-dom';
import DealRoomsList from './DealRoomsList';
import DealRoomPage from './DealRoomPage';

const Offerings = () => (
  <Switch>
    <Route exact path="/dealrooms" component={DealRoomsList} />
    <Route exact path="/dealrooms/:dealRoomID" component={DealRoomPage} />
  </Switch>
);

export default Offerings;
