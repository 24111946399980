import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Notice } from 'shared/components';
import { IconKYB } from 'shared/icons';
import config from 'config';

const KybNotice = ({
  status,
  handleClick,
}) => {
  const history = useHistory();
  if (status === 'published') { return null; }

  const text = {
    pending: 'Before we can start working together, we need to have a full picture of the organization and its business goals. We kindly ask you a to upload any document you deem useful for us to evaluate if our platform is the right partner. All the information you provide shall be kept strictly confidential, consistently with our standard ',
    submitted: 'KYB has been successfully submitted. Please wait while we validate your submitted documents. We will notify you shortly with the results.',
    validated: 'KYB has been successfully validated. You can publish your organization now so that it can be displayed to all users.',
    failed: 'KYB has failed. Please contact admin for support',
  };

  const getText = () => {
    if (!status || status === 'pending') {
      return (
        <>
          <span>{text.pending}</span>
          <a href={`${config.website_url}/nda`} target="_blank" rel="noopener noreferrer" data-scroll>Non-disclosure agreement</a>
        </>
      );
    }
    if (status === 'submitted' || status === 'processing') {
      return text.submitted;
    }
    if (status === 'validated') {
      return text.validated;
    }
    if (status === 'failed') {
      return text.failed;
    }
    return text.pending;
  };

  const getBtn = () => {
    if (status === 'pending') {
      return <Button handleClick={handleClick}>Start KYB</Button>;
    }
    if (status === 'failed') {
      return <Button handleClick={() => history.push('/support')}>Contact support</Button>;
    }
    return null;
  };

  const Content = () => (
    <div>
      <div>{getText()}</div>
      <div>{getBtn()}</div>
    </div>
  );

  const Title = () => (
    <>
      <IconKYB />
      <>Know Your Business (“KYB”)</>
    </>
  );

  return (
    <Notice
      title={<Title />}
      content={<Content />}
    />
  );
};

KybNotice.propTypes = {
  status: PropTypes.string, // `pending`, `submitted`, `processing`, `validated`, `failed`, `published`
  handleClick: PropTypes.func,
};

KybNotice.defaultProps = {
  status: null,
  handleClick: () => {},
};

export default KybNotice;
