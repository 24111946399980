import PropTypes from 'prop-types';

const IconKyc = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 34 34" fill="none" {...rest} className="IconKyc">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8884 0.665307C16.5212 0.0989442 17.4786 0.0989513 18.1114 0.665323C21.356 3.56939 25.6367 5.33327 30.3333 5.33327C30.6505 5.33327 30.9657 5.32522 31.2787 5.30935C32.0689 5.26927 32.7784 5.79028 32.9766 6.55625C33.4273 8.29745 33.6666 10.122 33.6666 12C33.6666 22.0981 26.76 30.5788 17.4154 32.984C17.1429 33.0541 16.8571 33.0541 16.5846 32.984C7.23993 30.5788 0.333313 22.0981 0.333313 12C0.333313 10.1219 0.572655 8.2973 1.02337 6.55603C1.22163 5.79007 1.9311 5.26907 2.72129 5.30915C3.03426 5.32502 3.34944 5.33306 3.66665 5.33306C8.36316 5.33306 12.6437 3.56926 15.8884 0.665307ZM3.96907 8.66433C3.77055 9.74499 3.66665 10.8597 3.66665 12C3.66665 20.3901 9.30404 27.4675 17 29.6437C24.6959 27.4675 30.3333 20.3901 30.3333 12C30.3333 10.8598 30.2294 9.74513 30.0309 8.66453C25.1205 8.59729 20.6021 6.89495 16.9998 4.07919C13.3977 6.89482 8.87937 8.59708 3.96907 8.66433ZM23.1785 12.4882C23.8294 13.139 23.8294 14.1943 23.1785 14.8452L16.5118 21.5118C15.861 22.1627 14.8057 22.1627 14.1548 21.5118L10.8215 18.1785C10.1706 17.5276 10.1706 16.4724 10.8215 15.8215C11.4723 15.1706 12.5276 15.1706 13.1785 15.8215L15.3333 17.9763L20.8215 12.4882C21.4723 11.8373 22.5276 11.8373 23.1785 12.4882Z"
      fill={color}
    />
  </svg>
);

IconKyc.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconKyc.defaultProps = {
  color: '#E6EAEB',
  height: 34,
  width: 34,
};

export default IconKyc;
