import styled from 'styled-components';

export const Banner = styled.div`
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  padding: 8px 16px;
  /* min-height: 104px; */
  display: flex;
  flex-direction: column;
`;

export const BannerTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn05};
  margin-bottom: 6px;
`;

export const BannerText = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn05};
  max-width: 300px;
`;
