import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ImageGroup, Image } from 'react-fullscreen-image';
import { get, sortBy } from 'lodash';
import toast from 'react-hot-toast';
import api from 'shared/api';
import {
  Button,
  Loader,
  Notice,
  Page,
  PageLoader,
  ProgressBar,
  Section,
} from 'shared/components';
import { IconPlus, IconClose, IconInterest } from 'shared/icons';
import { parseObject, formatDate, isInvestor } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import { ProfileCard } from 'ProfilePage/components';
import { OrganizationDetails } from 'Organizations/components';
import { getUserOfferings } from 'redux/investing/actions';
import {
  OfferingForm,
  OfferingImagesUpload,
  ChangeOfferingBannerForm,
  InterestForm,
} from '../components';
import * as Styled from './styles';

const OfferingPage = ({
  isAuth,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { offeringID } = params;
  const currencies = useSelector(state => get(state, 'investing.currencies'));
  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');
  const isUserInvestor = isInvestor(currentUser);

  const [isLoading, setLoading] = useState(true);
  const [offeringData, setOfferingData] = useState(null);
  const [offeringOrganization, setOfferingOrganization] = useState(null);
  const [isOrganizationPopupDisplayed, setOrganizationPopupDisplay] = useState(false);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  // offering owners
  const [owners, setOwners] = useState([]);
  const [ownersLoading, setOwnersLoading] = useState(true);
  // offering members
  const [members, setMembers] = useState([]);
  const [membersLoading, setMembersLoading] = useState(true);
  // offering images
  const [isImagesUploadFormDisplayed, setImagesUploadFormDisplayed] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [isChangeBannerDisplayed, setChangeBannerDisplayed] = useState(false);
  const [deletingImage, setImageDeleting] = useState(null);
  // offering interest
  const [isInterestDisplayed, setInterestDisplayed] = useState(false);

  const publishOffering = useCallback(() => {
    const data = { stage: 'published' };
    api.patch(`/api/investing/offerings/${offeringID}`, data)
      .then((res) => {
        setOfferingData(get(res, 'data'));
        dispatch(getUserOfferings(currentUserID));
        toast.success('Offering published succesfully');
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [offeringID]);

  const getOfferingOwners = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/owners`)
      .then((res) => {
        // TODO use roles field to find the vp
        setOwners(get(res, 'data') || []);
        setOwnersLoading(false);
      })
      .catch(() => {
        setOwnersLoading(false);
      });
  }, [offeringID]);

  const getOfferingMembers = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/members`)
      .then((res) => {
        setMembers(get(res, 'data') || []);
        setMembersLoading(false);
      })
      .catch(() => {
        setMembersLoading(false);
      });
  }, [offeringID]);

  const getOfferingImages = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/files?file_type=offering_picture`)
      .then((res) => {
        const imagesList = get(res, 'data') || [];
        const sortedImages = sortBy(imagesList.map(i => i.file), 'created_at').reverse();
        setImages(sortedImages);
        setImagesLoading(false);
      })
      .catch(() => {
        setImagesLoading(false);
      });
  }, [offeringID]);

  const getOffering = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}`)
      .then((res) => {
        setOfferingData(get(res, 'data.offering'));
        setOfferingOrganization(get(res, 'data.business_entity_owners'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getOffering();
    getOfferingOwners();
    getOfferingImages();
    getOfferingMembers();
  }, [getOffering, getOfferingOwners, getOfferingImages, getOfferingMembers]);

  useEffect(() => {
    setOrganizationPopupDisplay(false);
  }, [offeringID]);

  const handleImageDelete = (image) => {
    const imageID = get(image, 'id');
    setImageDeleting(imageID);
    api.delete(`/api/investing/offerings/files/${imageID}`)
      .then(() => {
        getOfferingImages();
        setImageDeleting(null);
        toast.success('Offering image deleted succesfully');
      })
      .catch(() => {
        setImageDeleting(null);
        toast.error('Error occured');
      });
  };

  const handleInterestClick = () => {
    if (!isUserInvestor) {
      toast.error('You do not have a role of investor. Please contact support.');
      return false;
    }
    setInterestDisplayed(true);
    return true;
  };

  if (isLoading || imagesLoading || membersLoading) {
    return <Page showBack type="yoc_user" title="Your own circle"><PageLoader /></Page>;
  }

  const isUserOfferingOwner = owners.find(o => get(o, 'profile.user_id') === currentUserID);
  const isUserOfferingMember = members.find(o => get(o, 'profile.user_id') === currentUserID);
  const usd = currencies.find(c => c.code === 'USD');
  const offeringCurrency = currencies.find(c => get(c, 'id') === get(offeringData, 'currency_id'));
  const initialCurrency = offeringCurrency || usd;
  const { symbol } = initialCurrency;
  const percentage = (get(offeringData, 'amount_raised') / get(offeringData, 'target')) * 100;
  const offeringSummary = parseObject(get(offeringData, 'bio'), '');
  const isPublished = get(offeringData, 'stage') === 'published';
  const offeringLogo = get(offeringData, 'logo') || null;

  const organizationName = get(offeringOrganization, '[0].name');

  const startDate = formatDate(get(offeringData, 'opened_at'), defaultDateFormat, '');
  const endDate = formatDate(get(offeringData, 'closed_at'), defaultDateFormat, '');
  const prevInvestments = Number(get(offeringData, 'prev_investments'));
  const foundersShares = Number(get(offeringData, 'issued_shares'));
  const totalOutsdandingShares = Number(get(offeringData, 'outstanding_shares'));
  const offPlatformInvestments = Number(get(offeringData, 'off_platform_investments'));
  const targetFunding = Number(get(offeringData, 'target'));
  const isOverfundingAllowed = get(offeringData, 'allow_max_overfunding');
  const maxOverfundingValue = get(offeringData, 'max_overfunding');
  const isUnlimitedOverfunding = isOverfundingAllowed && !maxOverfundingValue;
  const maxOverFunding = isUnlimitedOverfunding ? 'Unlimited' : Number(get(offeringData, 'max_overfunding')).toLocaleString();

  return (
    <Page type="offerings" title="Offering">
      <Styled.OfferingPage>
        <Styled.Main>
          <Styled.Header>
            <Styled.Title>{get(offeringData, 'name')}</Styled.Title>
            <Styled.Organization>
              <Button variant="text" handleClick={() => setOrganizationPopupDisplay(true)}>
                {organizationName}
              </Button>
            </Styled.Organization>
            <Styled.FundingContainer>
              <Styled.AmountRaised>{`${symbol} ${Number(get(offeringData, 'amount_raised')).toLocaleString()} Funded`}</Styled.AmountRaised>
              <Styled.AmountSeparator>/</Styled.AmountSeparator>
              <Styled.AmountGoal>{`${symbol} ${Number(get(offeringData, 'target')).toLocaleString()} Goal`}</Styled.AmountGoal>
            </Styled.FundingContainer>
          </Styled.Header>
          <Styled.Page>
            <Styled.Data>
              <ProgressBar height="8px" percentage={percentage} />
              <Styled.Banner>
                <Styled.BannerImg src={offeringLogo || noImagePlaceholder} alt="organization banner" />
                {(isAuth || isUserOfferingOwner) && (
                  <Styled.BannerBtn hasSelectedImage={!!offeringLogo} className="BannerBtn" type="button" onClick={() => setChangeBannerDisplayed(true)}>
                    <Styled.BannerBtnText>
                      {offeringLogo ? 'Change banner' : 'Add banner'}
                    </Styled.BannerBtnText>
                  </Styled.BannerBtn>
                )}
              </Styled.Banner>
              <Styled.ImagesList multiImages={images.length > 1}>
                <ImageGroup>
                  {images.map(i => (
                    <Styled.ImageBtn key={i.id}>
                      <Image
                        src={i.parts[0]}
                        alt="offering illustration"
                      />
                      {isUserOfferingOwner && (
                        <Styled.ImageDeleteBtn type="button" onClick={() => handleImageDelete(i)} disabled={deletingImage === i.id}>
                          <IconClose color="#4a4a4a" width={12} height={12} />
                        </Styled.ImageDeleteBtn>
                      )}
                    </Styled.ImageBtn>
                  ))}
                </ImageGroup>
                {isUserOfferingOwner && (
                  <Styled.AddImageBtn onClick={() => setImagesUploadFormDisplayed(true)}>
                    <IconPlus color="#20201E" />
                    <Styled.AddImageBtnText>Add image</Styled.AddImageBtnText>
                  </Styled.AddImageBtn>
                )}
              </Styled.ImagesList>
              {!isPublished ? (
                <Notice
                  type="error"
                  title="Offering is not published"
                  content="Only published offerings will be visible for other users. You can browse all your offerings under My offerings tab"
                />
              ) : null}
              <Styled.AccentCardsList>
                <Styled.AccentCard>
                  <Styled.AccentLabel>Pre-money Valuation</Styled.AccentLabel>
                  <Styled.AccentValue>{Number(get(offeringData, 'pre_money_valuation')).toLocaleString() || '-'}</Styled.AccentValue>
                </Styled.AccentCard>
                <Styled.AccentCard>
                  <Styled.AccentLabel>Min investment</Styled.AccentLabel>
                  <Styled.AccentValue>{Number(get(offeringData, 'min_investment')).toLocaleString() || '-'}</Styled.AccentValue>
                </Styled.AccentCard>
                <Styled.AccentCard>
                  <Styled.AccentLabel>Max investment</Styled.AccentLabel>
                  <Styled.AccentValue>{Number(get(offeringData, 'max_investment')).toLocaleString() || '-'}</Styled.AccentValue>
                </Styled.AccentCard>
              </Styled.AccentCardsList>
              <Section
                title="Description"
                content={get(offeringData, 'description') || 'N/A'}
              />
              <Section
                title="Company's purpose"
                content={get(offeringSummary, 'purpose') || 'N/A'}
              />
              <Section
                title="Market analysis"
                content={get(offeringSummary, 'market') || 'N/A'}
              />
              <Section
                title="Business model"
                content={get(offeringSummary, 'business_model') || 'N/A'}
              />
              <Section
                title="Offering"
                content={get(offeringSummary, 'offering') || 'N/A'}
              />
            </Styled.Data>
            <Styled.Aside>
              <Styled.DetailsContainer>
                {!!startDate && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Start</Styled.DetailLabel>
                    <Styled.DetailValue>{startDate}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {!!endDate && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>End</Styled.DetailLabel>
                    <Styled.DetailValue>{endDate}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {!!prevInvestments && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Previous rounds</Styled.DetailLabel>
                    <Styled.DetailValue>{prevInvestments.toLocaleString()}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {isOverfundingAllowed && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Max overfunding</Styled.DetailLabel>
                    <Styled.DetailValue>{maxOverFunding}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {!!foundersShares && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Founders shares</Styled.DetailLabel>
                    <Styled.DetailValue>{foundersShares.toLocaleString()}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {!!totalOutsdandingShares && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Total outstanding shares</Styled.DetailLabel>
                    <Styled.DetailValue>{totalOutsdandingShares.toLocaleString()}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {!!offPlatformInvestments && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Off plaftorm investments</Styled.DetailLabel>
                    <Styled.DetailValue>{offPlatformInvestments.toLocaleString()}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
                {!!targetFunding && (
                  <Styled.DetailsItem>
                    <Styled.DetailLabel>Target funding</Styled.DetailLabel>
                    <Styled.DetailValue>{targetFunding.toLocaleString()}</Styled.DetailValue>
                  </Styled.DetailsItem>
                )}
              </Styled.DetailsContainer>
              {!isUserOfferingMember && !isUserOfferingOwner && (
                <Button
                  size="small"
                  type="button"
                  handleClick={handleInterestClick}
                >
                  <>
                    <IconInterest />
                    <span>Interested</span>
                  </>
                </Button>
              )}
              {(isUserOfferingMember || isUserOfferingOwner) && (
                <Button
                  variant={isPublished ? 'contained' : 'outlined'}
                  size="small"
                  type="submit"
                  handleClick={() => history.push(`/offerings/${offeringID}/dataroom`)}
                >
                  Dataroom
                </Button>
              )}
              {(isAuth || isUserOfferingOwner) && (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    type="submit"
                    handleClick={() => setFormDisplay(true)}
                  >
                    Edit
                  </Button>
                  {!isPublished ? (
                    <Button size="small" handleClick={publishOffering}>
                      Publish
                    </Button>
                  ) : null}
                </>
              )}
              {ownersLoading ? <Loader /> : (
                <ProfileCard
                  data={get(owners, '[0].profile')}
                  showActions={false}
                  showLink
                />
              )}
            </Styled.Aside>
          </Styled.Page>
        </Styled.Main>
        {isFormDisplayed && (
          <OfferingForm
            isOpen={isFormDisplayed}
            closeCb={() => {
              getOfferingMembers();
              setFormDisplay(false);
            }}
            offering={offeringData}
            organizationID={get(owners, '[0].owner.business_entity_id')}
          />
        )}
        {isImagesUploadFormDisplayed && (
          <OfferingImagesUpload
            isOpen={isImagesUploadFormDisplayed}
            closeCb={() => setImagesUploadFormDisplayed(false)}
            offering={offeringData}
            refetchFiles={getOfferingImages}
          />
        )}
        {isChangeBannerDisplayed && (
          <ChangeOfferingBannerForm
            isOpen={isChangeBannerDisplayed}
            closeCb={() => setChangeBannerDisplayed(false)}
            confirmCb={() => {
              getOffering();
              setChangeBannerDisplayed(false);
            }}
            offering={offeringData}
          />
        )}
        {isInterestDisplayed && (
          <InterestForm
            isOpen={isInterestDisplayed}
            closeCb={() => setInterestDisplayed(false)}
            confirmCb={() => {
              getOfferingMembers();
              setInterestDisplayed(false);
            }}
            offering={offeringData}
            currencySymbol={symbol}
          />
        )}
        {isOrganizationPopupDisplayed && (
          <OrganizationDetails
            isOpen={isOrganizationPopupDisplayed}
            closeCb={() => setOrganizationPopupDisplay(false)}
            organization={offeringOrganization[0]}
          />
        )}
      </Styled.OfferingPage>
    </Page>
  );
};

OfferingPage.propTypes = {
  isAuth: PropTypes.bool,
};

OfferingPage.defaultProps = {
  isAuth: false,
};

export default OfferingPage;
