/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

const getSM = () => css`
  height: 32px;

  input {
    font-size: 14px;
  }
`;

const getMD = () => css`
  height: 40px;

  input {
    font-size: 14px;
  }
`;

const getLG = () => css`
  height: 64px;
`;

const sizeStyling = (props) => {
  const { size } = props;

  switch (size) {
    case 'small':
      return getSM();
    case 'medium':
      return getMD();
    case 'large':
      return getLG();
    default:
      return getLG();
  }
};

const getDisabledStyle = () => css`
  background: ${props => props.theme.colors.shades.warm.dawn05};

  &:hover {
    border-color: ${props => props.theme.colors.shades.warm.dawn30};
  }

  /* .Label,
  .LabelRequired {
    color: ${props => props.theme.colors.shades.warm.dawn50};
  } */
`;

const getErrorStyle = () => css`
  border-color: ${props => props.theme.colors.danger.danger50};

  &:hover {
    border-color: ${props => props.theme.colors.danger.danger50};
  }
`;

const getWideStyling = () => css`
  width: 100%;
  max-width: 420px;
`;

export const TextInput = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
  width: 100%;
  max-width: 320px;
  min-width: 240px;
  background: transparent;
  outline: none;
  background-color: ${props => props.theme.colors.shades.neutral.white};

  ${props => sizeStyling(props)}

  &:hover {
    border-color: ${props => props.theme.colors.primary.daintree.daintree50};
  }

  ${props => {
    if (props.disabled) {
      return getDisabledStyle();
    }
  }}

  ${props => {
    if (props.hasError) {
      return getErrorStyle();
    }
  }}
  ${props => {
    if (props.wide) {
      return getWideStyling();
    }
  }}
`;

export const TextInputMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LabelContainer = styled.div`
  display: flex;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const LabelRequired = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const Input = styled.input`
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 14px;
  }
  &::-moz-placeholder { /* Firefox 19+ */

    font-size: 14px;
  }
  &:-ms-input-placeholder { /* IE 10+ */

    font-size: 14px;
  }
  &:-moz-placeholder { /* Firefox 18- */

    font-size: 14px;
  }
`;

export const NumberInput = styled.div`

  input {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 14px;
    }
    &::-moz-placeholder { /* Firefox 19+ */

      font-size: 14px;
    }
    &:-ms-input-placeholder { /* IE 10+ */

      font-size: 14px;
    }
    &:-moz-placeholder { /* Firefox 18- */

      font-size: 14px;
    }
  }
`;

export const PhoneInput = styled.div`

  .PhoneInputCountry {
    margin-right: 10px;

    .PhoneInputCountryIcon {
      box-shadow: none;

      .PhoneInputInternationalIconGlobe {
        stroke: ${props => props.theme.colors.shades.warm.dawn90};
      }

      .PhoneInputInternationalIconPhone {
        fill: ${props => props.theme.colors.shades.warm.dawn90};
      }
    }
  }

  .PhoneInputInput {
    border: none;
    outline: none;
    font-size: 16px;
  }
`;

export const IconLeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`;

export const IconTrailing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-left: 8px;
`;

export const InputButton = styled.button`
  appearance: none;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0 0 0 12px;
  margin-left: 8px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${props => props.theme.colors.shades.warm.dawn30};
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  font-weight: bold;
  min-height: 24px;
  outline: none;
`;

export const HelperText = styled.div`
  color: ${props => props.theme.colors.shades.warm.dawn90};
  font-size: 12px;
  line-height: 20px;
`;

export const ErrorText = styled.div`
  color: ${props => props.theme.colors.danger.danger50};
  font-size: 14px;
  margin-top: 4px;
`;
