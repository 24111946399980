import { combineReducers } from 'redux';
import accountReducer from '../account/accountReducer';
import entityReducer from '../entity/entityReducer';
import investingReducer from '../investing/investingReducer';
import uiReducer from '../ui/uiReducer';
import topologyReducer from '../topology/topologyReducer';
import surveysReducer from '../surveys/surveysReducer';
import navigationReducer from '../navigation/navigationReducer';
import notificationsReducer from '../notifications/notificationsReducer';

const rootReducer = combineReducers({
  entity: entityReducer,
  investing: investingReducer,
  account: accountReducer,
  topology: topologyReducer,
  ui: uiReducer,
  surveys: surveysReducer,
  navigationHistory: navigationReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
