import styled from 'styled-components';

export const OrganizationsWrapper = styled.section`
  width: 100%;
  /* height: calc(100vh - 90px); */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 90px);
  display: flex;
  justify-content: center;
  position: relative;
`;

export const OrganizationsList = styled.div`
  width: 100%;
  max-width: 920px;
  padding: 0 10px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0;
  }
`;

export const ListHeader = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.shades.warm.dawn05};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 12px 8px;
  }
`;

export const ListTitle = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const TitleText = styled.div`
  display: flex;
  align-items: center;
  color: #616E7F;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
`;

export const ListSearch = styled.form`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    /* TODO return after filters are done */
    /* width: 80%; */
    width: 100%;

    .TextInput {
      max-width: 100%;
    }
  }
`;

export const ListAction = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 100%;
  }
`;

export const ActionText = styled.div`
  margin-left: 10px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 8px;
  }
`;

export const AddBtn = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
  border: none;
  margin: 0 0 0 14px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 0 0 24px;
  }
`;
