import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  UserInfoCell,
  Loader,
} from 'shared/components';
import { OfferingCard } from 'Offerings/components';
import api from 'shared/api';
import * as Styled from './styles';

const OfferingOwnerNotification = ({
  offeringOwner,
}) => {
  const history = useHistory();
  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const offeringID = get(offeringOwner, 'OfferingOwner.owner.offering_id') || '';
  const ownerName = get(offeringOwner, 'OfferingOwner.profile.full_name') || '';
  const userProfile = get(offeringOwner, 'OfferingOwner.profile') || '';
  const userID = get(offeringOwner, 'OfferingOwner.profile.user_id') || '';

  const [isOfferingLoading, setOfferingLoading] = useState(true);
  const [offeringData, setOfferingData] = useState(null);

  const getOffering = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}`)
      .then((res) => {
        setOfferingData(get(res, 'data'));
        setOfferingLoading(false);
      })
      .catch(() => {
        setOfferingLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getOffering();
  }, [getOffering]);

  if (isOfferingLoading) {
    return (
      <Styled.OfferingOwnerNotification>
        <Loader color="#616E7F" width={20} height={20} />
      </Styled.OfferingOwnerNotification>
    );
  }

  const isLogedInUser = currentUserID === userID;
  const getText = () => {
    if (isLogedInUser) {
      return 'You have been added as an owner to the offering.';
    }
    return `${ownerName} has been added as an owner to the offering`;
  };

  return (
    <Styled.OfferingOwnerNotification>
      <Styled.Text>{getText()}</Styled.Text>
      {!isLogedInUser && (
        <Styled.Owner>
          <span>User profile:</span>
          {/* THIS needs to go to /yoc on client and /users on admin app */}
          <Link to={`/yoc/${userID}`}>
            <UserInfoCell
              profileImg={get(userProfile, 'profile_picture')}
              name={get(userProfile, 'full_name')}
              metaFirst={get(userProfile, 'title')}
            />
          </Link>
        </Styled.Owner>
      )}
      <Styled.Offering>
        <span>Offering:</span>
        <OfferingCard
          handleCardClick={() => history.push(`offerings/${offeringID}`)}
          offering={offeringData}
        />
      </Styled.Offering>
    </Styled.OfferingOwnerNotification>
  );
};

OfferingOwnerNotification.propTypes = {
  offeringOwner: PropTypes.object.isRequired,
};

export default OfferingOwnerNotification;
