import PropTypes from 'prop-types';
import { IconCheckmark } from 'shared/icons';
import * as Styled from './styles';

const Checkbox = ({
  disabled,
  id,
  label,
  onChange,
  value,
  ...rest
}) => (
  <Styled.Checkbox className="Checkbox">
    <input
      className="styled-checkbox"
      id={id}
      type="checkbox"
      checked={value}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
    <label htmlFor={id}>{label}</label>
    <IconCheckmark />
  </Styled.Checkbox>
);

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  label: '',
  value: false,
};

export default Checkbox;
