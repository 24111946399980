import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { ReactComponent as FactoroLogo } from 'shared/assets/logo_long_white.svg';
import * as Styled from './styles';

const SplashScreen = () => {
  const entityName = useSelector(state => get(state, 'entity.details.name'));
  const entityLogo = useSelector(state => get(state, 'entity.details.logoURL'));

  return (
    <Styled.SplashScreen>
      <Styled.SplashMain>
        {entityLogo && <Styled.EntityLogo src={entityLogo} alt="logo" />}
        <Styled.MainText>{entityName}</Styled.MainText>
      </Styled.SplashMain>
      <Styled.SplashFooter>
        <Styled.FooterText>Powered by</Styled.FooterText>
        <FactoroLogo height={20} width={68} />
      </Styled.SplashFooter>
    </Styled.SplashScreen>
  );
};

export default SplashScreen;
