import PropTypes from 'prop-types';

const IconUserAddFill = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 17" fill="none" {...rest} className="IconUserAddFill">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5999 7.29998C9.58813 7.29998 11.1999 5.6882 11.1999 3.69998C11.1999 1.71175 9.58813 0.0999756 7.5999 0.0999756C5.61168 0.0999756 3.9999 1.71175 3.9999 3.69998C3.9999 5.6882 5.61168 7.29998 7.5999 7.29998Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5999 9.69998C11.5764 9.69998 14.7999 12.9235 14.7999 16.9H0.399902C0.399902 12.9235 3.62345 9.69998 7.5999 9.69998Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1999 4.89998C17.1999 4.23723 16.6626 3.69998 15.9999 3.69998C15.3372 3.69998 14.7999 4.23723 14.7999 4.89998V6.09998H13.5999C12.9372 6.09998 12.3999 6.63723 12.3999 7.29998C12.3999 7.96272 12.9372 8.49998 13.5999 8.49998H14.7999V9.69998C14.7999 10.3627 15.3372 10.9 15.9999 10.9C16.6626 10.9 17.1999 10.3627 17.1999 9.69998V8.49998H18.3999C19.0626 8.49998 19.5999 7.96272 19.5999 7.29998C19.5999 6.63723 19.0626 6.09998 18.3999 6.09998H17.1999V4.89998Z"
      fill={color}
    />
  </svg>
);

IconUserAddFill.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUserAddFill.defaultProps = {
  color: '#012934',
  height: 17,
  width: 20,
};

export default IconUserAddFill;
