import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Page,
  PageLoader,
} from 'shared/components';
import ProfilePage from 'ProfilePage';

const Profile = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [inviter, setInviter] = useState(null);
  const [inviterLoading, setInviterLoading] = useState(null);

  const getProfile = useCallback(() => {
    api.get(`/api/user-management/user-profiles/${userID}`)
      .then((res) => {
        setProfileData(get(res, 'data.user'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userID]);

  const getUserInviter = useCallback(() => {
    api.get(`/api/yoc/users/${userID}/inviter`)
      .then((res) => {
        const data = get(res, 'data');
        setInviter(data);
        setInviterLoading(false);
      })
      .catch(() => {
        setInviter(null);
        setInviterLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    getProfile();
    getUserInviter();
  }, [getProfile, getUserInviter]);

  if (isLoading || inviterLoading) {
    return <Page type="profile" title="Profile"><PageLoader /></Page>;
  }

  return (
    <Page type="profile" title="Profile">
      <ProfilePage
        user={profileData}
        inviter={inviter}
        userClickCb={(u) => {
          history.push(`/yoc/${u.user_id}`);
        }}
        isAuth
        refetchProfile={getProfile}
      />
    </Page>
  );
};

export default Profile;
