export const Theme = {
  colors: {
    primary: {
      flamingo: {
        flamingo05: '#EBF5FB',
        flamingo40: '#3098D3',
        flamingo50: '#459FF2',
        flamingo60: '#5270B6',
      },
      daintree: {
        daintree05: '#E6EAEB',
        daintree40: '#0C0C0C',
        daintree50: '#191919',
        daintree60: '#000000',
      },
    },
    shades: {
      warm: {
        dawn05: '#F6F5F4',
        dawn10: '#ECECEA',
        dawn20: '#D9D8D5',
        dawn30: '#C7C5BF',
        dawn40: '#B4B1AA',
        dawn50: '#A19E95',
        dawn60: '#817E77',
        dawn70: '#615F59',
        dawn80: '#403F3C',
        dawn90: '#20201E',
      },
      neutral: {
        white: '#ffffff',
        black: '#000000',
        gray05: '#FAFAFA',
        gray10: '#F5F5F5',
        gray20: '#EBEBEB',
        gray30: '#E0E0E0',
        gray40: '#D6D6D6',
        gray50: '#CCCCCC',
        gray60: '#A3A3A3',
        gray70: '#7A7A7A',
        gray80: '#525252',
        gray90: '#292929',
      },
    },
    danger: {
      danger05: '#FFECEB',
      danger20: '#e2665e',
      danger40: '#FF4133',
      danger50: '#f00',
      danger60: '#99271F',
    },
    success: {
      success05: '#EAFAF2',
      success40: '#21A360',
      success50: '#197A48',
      success60: '#105230',
    },
    warning: {
      warning05: '#FFF6EB',
      warning40: '#FFB35C',
      warning50: '#FFA033',
      warning60: '#CC8029',
    },
  },
  breakpoints: {
    xxxs: '350px',
    xxs: '400px',
    xs: '600px',
    s: '768px',
    m: '1024px',
    l: '1366px',
    xl: '1920px',
    xxl: '2560px',
  },
  shadows: {
    lInset: '0px 16px 64px #D9D8D5',
    l100: '0px 0px 4px #D9D8D5',
    l200: '0px 8px 32px #D9D8D5',
    l300: '0px 16px 64px #D9D8D5',
  },
  dimensions: {
    sidebarExpanded: '240px',
    sidebarContracted: '48px',
  },
  borderRadius: 0,
};
