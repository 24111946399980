import styled from 'styled-components';

export const SplashScreen = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

export const SplashMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const EntityLogo = styled.img`
  width: 100%;
  max-width: 80px;
  height: auto;
  margin-bottom: 8px;
`;

export const MainText = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  color: ${props => props.theme.colors.shades.warm.dawn05};
`;

export const SplashFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
`;

export const FooterText = styled.span`
  font-size: 12px;
  line-height: 20px;
  margin-right: 8px;
  color: ${props => props.theme.colors.shades.warm.dawn05};
`;
