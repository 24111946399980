/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const Cell = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  ${props => {
    if (props.status === 'critical' && !props.isLite) {
      return css`
        background-color: ${() => props.theme.colors.danger.danger05};
      `;
    }
    if (props.status === 'high' && !props.isLite) {
      return css`
        background-color: ${() => props.theme.colors.warning.warning05};
      `;
    }
    if (props.status === 'normal' && !props.isLite) {
      return css`
        background-color: ${() => props.theme.colors.success.success05};
      `;
    }
    if (props.status === 'low' && !props.isLite) {
      return css`
        background-color: ${() => props.theme.colors.shades.warm.dawn05};
      `;
    }
  }}

  ${props => {
    if (!props.isLite) {
      return css`
        padding: 0 10px;
        height: 24px;
        border-radius: 10px;
        border-radius: 5000px;
      `;
    }
  }}
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;

  ${props => {
    if (props.status === 'critical') {
      return css`
        background-color: ${() => props.theme.colors.danger.danger50};
      `;
    }
    if (props.status === 'high') {
      return css`
        background-color: ${() => props.theme.colors.warning.warning50};
      `;
    }
    if (props.status === 'normal') {
      return css`
        background-color: ${() => props.theme.colors.success.success50};
      `;
    }
    if (props.status === 'low') {
      return css`
        background-color: ${() => props.theme.colors.shades.warm.dawn60};
      `;
    }
  }}
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};

  ${props => {
    if (props.status === 'critical' && !props.isLite) {
      return css`
        color: ${() => props.theme.colors.danger.danger50};
      `;
    }
    if (props.status === 'high' && !props.isLite) {
      return css`
        color: ${() => props.theme.colors.warning.warning50};
      `;
    }
    if (props.status === 'normal' && !props.isLite) {
      return css`
        color: ${() => props.theme.colors.success.success50};
      `;
    }
    if (props.status === 'low' && !props.isLite) {
      return css`
        color: ${() => props.theme.colors.shades.warm.dawn60};
      `;
    }
  }}

  ${props => {
    if (!props.isLite) {
      return css`
        font-weight: bold;
        text-transform: uppercase;
      `;
    }
  }}
`;
