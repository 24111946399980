import PropTypes from 'prop-types';

const IconChevronUp = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 9" fill="none" {...rest} className="IconChevronUp">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7071 8.70711C15.3166 9.09763 14.6834 9.09763 14.2929 8.70711L8 2.41421L1.70711 8.70711C1.31658 9.09763 0.683417 9.09763 0.292893 8.70711C-0.0976314 8.31658 -0.0976313 7.68342 0.292894 7.29289L7.29289 0.292893C7.68342 -0.0976322 8.31658 -0.0976321 8.70711 0.292893L15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711Z"
      fill={color}
    />
  </svg>
);

IconChevronUp.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconChevronUp.defaultProps = {
  color: '#012934',
  height: 9,
  width: 16,
};

export default IconChevronUp;
