import PropTypes from 'prop-types';
import { cameraTypes } from 'shared/constants';
import SnapId from '../SnapId';
import SnapSelfie from '../SnapSelfie';
import SubmitImage from '../SubmitImage';

const CameraFooterActions = ({
  cameraType,
  handleCameraReverse,
  isImageDisplayed,
  numberOfCameras,
  retryImage,
  saveImage,
  submitImage,
  type,
}) => {
  const isSelfie = cameraType === cameraTypes.selfie;

  if (isSelfie) {
    if (isImageDisplayed) {
      return <SubmitImage onImageRetry={retryImage} onImageSubmit={submitImage} type={type} />;
    }
    return (
      <SnapSelfie
        onImageTake={saveImage}
        onCameraReverse={handleCameraReverse}
        numberOfCameras={numberOfCameras}
      />
    );
  }
  if (!isImageDisplayed) {
    return <SnapId onImageTake={saveImage} type={type} />;
  }
  return <SubmitImage onImageRetry={retryImage} onImageSubmit={submitImage} type={type} />;
};

CameraFooterActions.propTypes = {
  cameraType: PropTypes.string.isRequired,
  handleCameraReverse: PropTypes.func.isRequired,
  isImageDisplayed: PropTypes.bool.isRequired,
  numberOfCameras: PropTypes.number.isRequired,
  retryImage: PropTypes.func.isRequired,
  saveImage: PropTypes.func.isRequired,
  submitImage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default CameraFooterActions;
