import { Route, Switch, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { checkKycStatus, canCreateAsset } from 'shared/helpers';
import { NotAuthorized } from 'shared/components';
import OrganizationsList from './OrganizationsList';
import OrganizationPage from './OrganizationPage';

const Organizations = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const organizations = useSelector(state => get(state, 'investing.organizations'));
  const isAllowedToCreate = canCreateAsset(user, false);
  const kYCStatus = checkKycStatus(get(user, 'status'));

  if (kYCStatus.isExternal) {
    return (
      <NotAuthorized
        title="You can’t access this page yet"
        text="Platform admin needs to verify you before you can use the app."
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  if (!isAllowedToCreate && !organizations.length) {
    return (
      <NotAuthorized
        title="You can’t access this page"
        text="You are not member of any organization"
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  return (
    <Switch>
      <Route exact path="/organizations" component={OrganizationsList} />
      <Route exact path="/organizations/:organizationID" component={OrganizationPage} />
    </Switch>
  );
};

export default Organizations;
