/* eslint-disable no-unused-vars */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  Selector,
} from 'shared/components';
import { IconClose, IconUpwardArrow } from 'shared/icons';
import { formatDate } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import api from 'shared/api';
import {
  getUserInvestments,
} from 'redux/investing/actions';
import config from 'config';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    paddingTop: '40px',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '700px',
  },
};

const InvestForm = ({
  offering,
  isOpen,
  closeCb,
  confirmCb,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  const offeringData = get(offering, 'offering');
  const offeringID = get(offering, 'offering.id');
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');
  const currencies = useSelector(state => get(state, 'investing.currencies'));
  const usd = currencies.find(c => c.code === 'USD');
  const offeringCurrency = currencies.find(c => get(c, 'id') === get(offeringData, 'currency_id'));
  const currency = offeringCurrency || usd;
  const { symbol } = currency;

  const startDate = formatDate(get(offeringData, 'opened_at'), defaultDateFormat, '');
  const endDate = formatDate(get(offeringData, 'closed_at'), defaultDateFormat, '');
  const targetFunding = Number(get(offeringData, 'target'));
  const minInvestment = Number(get(offeringData, 'min_investment'));
  const maxInvestment = Number(get(offeringData, 'max_investment'));
  const prevInvestments = Number(get(offeringData, 'prev_investments'));
  const foundersShares = Number(get(offeringData, 'issued_shares'));
  const totalOutsdandingShares = Number(get(offeringData, 'outstanding_shares'));
  const offPlatformInvestments = Number(get(offeringData, 'off_platform_investments'));
  const preMoney = Number(get(offeringData, 'pre_money_valuation'));
  const isOverfundingAllowed = get(offeringData, 'allow_max_overfunding');
  const maxOverfundingValue = get(offeringData, 'max_overfunding');
  const isUnlimitedOverfunding = isOverfundingAllowed && !maxOverfundingValue;
  const maxOverFunding = isUnlimitedOverfunding ? 'Unlimited' : `${Number(get(offeringData, 'max_overfunding')).toLocaleString()} ${symbol}`;

  const organizationName = get(offering, 'business_entity_owners[0].name');

  const handleAmountChange = (val) => {
    setAmount(val);
    setAmountError('');
  };

  const validateAmount = () => {
    if (!amount) {
      setAmountError('This field is required.');
      toast.error('Enter valid investment amount');
      return false;
    }
    if (!!minInvestment && Number(amount) < minInvestment) {
      setAmountError('Amount cannot be less than offering min investment value.');
      toast.error('Enter valid investment amount');
      return false;
    }
    if (!!maxInvestment && Number(amount) > maxInvestment) {
      setAmountError('Amount cannot be bigger than offering max investment value.');
      toast.error('Enter valid investment amount');
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateAmount();

    if (!isValid) {
      return false;
    }

    const data = {
      user_role_id: get(user, 'roles.maker'),
      amount: Number(amount),
      currency_code: get(currency, 'code'),
    };
    api.post(`/api/investing/offerings/${offeringID}/investments`, data)
      .then(() => {
        setLoading(false);
        toast.success('Investment saved succesfully.');
        dispatch(getUserInvestments(userID));
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Error occured');
      });
    return true;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="InvestFormModal"
    >
      <Styled.InvestForm>
        <Styled.Main>
          <Styled.Header>
            <Styled.HeaderTitle>{get(offering, 'offering.name')}</Styled.HeaderTitle>
            <Styled.Organization>{organizationName}</Styled.Organization>
          </Styled.Header>
          <Styled.Form onSubmit={handleSubmit}>
            <Styled.DetailsContainer>
              {!!startDate && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Start</Styled.DetailLabel>
                  <Styled.DetailValue>{startDate}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!endDate && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>End</Styled.DetailLabel>
                  <Styled.DetailValue>{endDate}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!targetFunding && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Target funding</Styled.DetailLabel>
                  <Styled.DetailValue>{`${targetFunding.toLocaleString()} ${symbol}`}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!minInvestment && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Min investments</Styled.DetailLabel>
                  <Styled.DetailValue>{`${minInvestment.toLocaleString()} ${symbol}`}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!maxInvestment && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Max investments</Styled.DetailLabel>
                  <Styled.DetailValue>{`${maxInvestment.toLocaleString()} ${symbol}`}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!prevInvestments && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Previous rounds</Styled.DetailLabel>
                  <Styled.DetailValue>{`${prevInvestments.toLocaleString()} ${symbol}`}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {isOverfundingAllowed && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Max overfunding</Styled.DetailLabel>
                  <Styled.DetailValue>{maxOverFunding}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!foundersShares && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Founders shares</Styled.DetailLabel>
                  <Styled.DetailValue>{foundersShares.toLocaleString()}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!totalOutsdandingShares && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Total outstanding shares</Styled.DetailLabel>
                  <Styled.DetailValue>{totalOutsdandingShares.toLocaleString()}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!offPlatformInvestments && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Off plaftorm investments</Styled.DetailLabel>
                  <Styled.DetailValue>{`${offPlatformInvestments.toLocaleString()} ${symbol}`}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
              {!!preMoney && (
                <Styled.DetailsItem>
                  <Styled.DetailLabel>Pre-money Valuation</Styled.DetailLabel>
                  <Styled.DetailValue>{`${preMoney.toLocaleString()} ${symbol}`}</Styled.DetailValue>
                </Styled.DetailsItem>
              )}
            </Styled.DetailsContainer>
            <Styled.InputContainer>
              <TextInput
                id="outstand_capital"
                label="Investment amount"
                placeholder={`${symbol}`}
                prefix={symbol}
                type="number"
                wide
                disabled={isLoading}
                onChange={handleAmountChange}
                error={amountError}
                value={amount}
              />
            </Styled.InputContainer>
            <Styled.ButtonContainer>
              <Button
                type="submit"
                wide
                disabled={isLoading}
              >
                <IconUpwardArrow />
                Invest
              </Button>
            </Styled.ButtonContainer>
            <Styled.Acknowledge>
              <p>
                By clicking Invest you agree to Kobo Funds platform
                {' '}
                <a href={`${config.website_url}/terms`} target="_blank" rel="noopener noreferrer" data-scroll>Terms & Conditions</a>
                ,
                {' '}
                <a href={`${config.website_url}/privacy`} target="_blank" rel="noopener noreferrer" data-scroll>Privacy policy</a>
                {' '}
                and
                {' '}
                <a href={`${config.website_url}/nda`} target="_blank" rel="noopener noreferrer" data-scroll>Non disclosure agreement</a>
                {' '}
                of all the Confidential Information received through the platform.
              </p>
            </Styled.Acknowledge>
          </Styled.Form>
        </Styled.Main>
      </Styled.InvestForm>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={closeCb}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

InvestForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
};

export default InvestForm;
