import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import YocList from '../YocList';
import UsersList from '../UsersList';
import * as Styled from './styles';

const YocContainer = () => (
  <Page type="yoc" title="Your own circle">
    <Styled.YocWrapper>
      <Styled.YocList>
        <Tabs>
          <TabsHeader>
            <Tab>My circles</Tab>
            <Tab>All users</Tab>
          </TabsHeader>
          <TabContent>
            <YocList />
          </TabContent>
          <TabContent>
            <UsersList />
          </TabContent>
        </Tabs>
      </Styled.YocList>
    </Styled.YocWrapper>
  </Page>
);

export default YocContainer;
