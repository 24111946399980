import PropTypes from 'prop-types';

const CameraFooterText = ({
  isImageDisplayed,
  isSelfie,
}) => {
  if (isSelfie) {
    if (isImageDisplayed) {
      return 'Looking good?';
    }
    return 'Place your face in the center of the circle';
  }

  if (isImageDisplayed) {
    return 'Did it come out bright and crisp?';
  }

  return 'Use the guides to frame the document correctly, then snap';
};

CameraFooterText.propTypes = {
  isImageDisplayed: PropTypes.bool.isRequired,
  isSelfie: PropTypes.bool.isRequired,
};

export default CameraFooterText;
