import styled from 'styled-components';

export const TicketFiltersForm = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;

  button {
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }
`;

export const FilterSection = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  .Checkbox {
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.div`
  line-height: 24px;
  margin-bottom: 18px;
  color: ${props => props.theme.colors.shades.warm.dawn80};
`;
