import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';

const mapStateToProps = state => ({
  user: get(state, 'account.user'),
});

const isAuthorized = (WrappedComponent) => {
  const wrapped = ({ user }) => {
    if (!get(user, 'user_id')) {
      return <Redirect to="/login" />;
    }

    return <WrappedComponent />;
  };

  wrapped.propTypes = {
    user: PropTypes.object,
  };

  wrapped.defaultProps = {
    user: {},
  };

  return connect(mapStateToProps)(wrapped);
};

export default isAuthorized;
