import PropTypes from 'prop-types';

const EmojiFrowning = ({
  height,
  width,
  background,
  strokes,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" {...rest} className="EmojiFrowning">
    <path d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0 8.06 8.06 0 18 0C27.941 0 36 8.06 36 18Z" fill={background} />
    <path d="M11.5 18C12.8807 18 14 16.433 14 14.5C14 12.567 12.8807 11 11.5 11C10.1193 11 9 12.567 9 14.5C9 16.433 10.1193 18 11.5 18Z" fill={strokes} />
    <path d="M24.5 18C25.8807 18 27 16.433 27 14.5C27 12.567 25.8807 11 24.5 11C23.1193 11 22 12.567 22 14.5C22 16.433 23.1193 18 24.5 18Z" fill={strokes} />
    <path d="M8.66499 27.871C8.84299 28.032 9.10899 28.042 9.29999 27.9C9.33899 27.871 13.222 25 18 25C22.766 25 26.662 27.871 26.7 27.9C26.891 28.042 27.157 28.03 27.335 27.871C27.512 27.711 27.552 27.447 27.429 27.243C27.3 27.029 24.212 22 18 22C11.788 22 8.69899 27.028 8.57099 27.243C8.44799 27.448 8.48699 27.711 8.66499 27.871Z" fill={strokes} />
  </svg>
);

EmojiFrowning.propTypes = {
  background: PropTypes.string,
  strokes: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EmojiFrowning.defaultProps = {
  background: '#FFCC4D',
  strokes: '#664500',
  height: 26,
  width: 26,
};

export default EmojiFrowning;
