import PropTypes from 'prop-types';

const IconBell = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 21" fill="none" {...rest} className="IconBell">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0185 3.16519C6.18499 1.66592 7.45635 0.5 9.00003 0.5C10.5437 0.5 11.8151 1.66592 11.9816 3.1652C14.3554 4.28439 16 6.69905 16 9.5V12.6585C16 12.9321 16.1087 13.1945 16.3022 13.388L17.7071 14.7929C17.9931 15.0789 18.0787 15.509 17.9239 15.8827C17.7691 16.2564 17.4045 16.5 17 16.5L13 16.5C13 18.7091 11.2092 20.5 9.00003 20.5C6.79089 20.5 5.00003 18.7091 5.00003 16.5L1.00003 16.5C0.595567 16.5 0.230931 16.2564 0.0761496 15.8827C-0.0786313 15.509 0.00692445 15.0789 0.292923 14.7929L1.69785 13.388C1.89133 13.1945 2.00003 12.9321 2.00003 12.6585V9.5C2.00003 6.69905 3.64464 4.28439 6.0185 3.16519ZM7.00003 16.5C7.00003 17.6046 7.89546 18.5 9.00003 18.5C10.1046 18.5 11 17.6046 11 16.5H7.00003ZM9.00003 2.5C8.44774 2.5 8.00003 2.94772 8.00003 3.5V3.84142C8.00003 4.26523 7.73287 4.64302 7.33328 4.78426C5.38994 5.47113 4.00003 7.32453 4.00003 9.5V12.6585C4.00003 13.3277 3.77872 13.9742 3.37664 14.5H14.6234C14.2213 13.9742 14 13.3277 14 12.6585V9.5C14 7.32453 12.6101 5.47113 10.6668 4.78426C10.2672 4.64302 10 4.26523 10 3.84142V3.5C10 2.94772 9.55231 2.5 9.00003 2.5Z"
      fill={color}
    />
  </svg>
);

IconBell.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconBell.defaultProps = {
  color: '#F26545',
  height: 21,
  width: 18,
};

export default IconBell;
