import styled from 'styled-components';

export const Switcher = styled.div`

`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .Toggle {

    .react-toggle-track {
      width: 40px;
      height: 20px;
      background: ${props => props.theme.colors.primary.daintree.daintree05} !important;
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    .react-toggle-thumb {
      width: 18px;
      height: 18px;
      left: 0px;
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    &.react-toggle--focus {

      .react-toggle-thumb {
        box-shadow: none;
      }
    }

    &.react-toggle--disabled {
      opacity: 1;

      .react-toggle-track {
        background: ${props => props.theme.colors.shades.warm.dawn10} !important;
        border-width: 1px;
        border-style: solid;
        border-color: ${props => props.theme.colors.shades.warm.dawn30};
      }

      .react-toggle-thumb {
        left: 0px;
        border-color: ${props => props.theme.colors.shades.warm.dawn30};
      }
    }

    &.react-toggle--checked {

      .react-toggle-track {
        background: ${props => props.theme.colors.primary.daintree.daintree50} !important;
      }

      .react-toggle-thumb {
        left: 22px;
      }

      &.react-toggle--disabled {

        .react-toggle-track {
          background: ${props => props.theme.colors.shades.warm.dawn50} !important;
          border-width: 1px;
          border-style: solid;
          border-color: ${props => props.theme.colors.shades.warm.dawn50};
        }

        .react-toggle-thumb {
          left: 21px;
          border-color: ${props => props.theme.colors.shades.neutral.white};
        }
      }
    }
  }
`;

export const LabelText = styled.span`
  margin-left: 10px;
`;
