import PropTypes from 'prop-types';

const IconSupport = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconSupport">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.78674 3.72924C2.0847 4.63237 1.66665 5.7672 1.66665 6.99967C1.66665 8.23215 2.0847 9.36698 2.78674 10.2701L4.21891 8.83793C3.8699 8.31098 3.66665 7.67906 3.66665 6.99967C3.66665 6.32029 3.8699 5.68837 4.21891 5.16141L2.78674 3.72924ZM3.72955 2.78644L5.16172 4.21861C5.68867 3.86959 6.32059 3.66634 6.99998 3.66634C7.67937 3.66634 8.31128 3.86959 8.83824 4.21861L10.2704 2.78644C9.36729 2.0844 8.23245 1.66634 6.99998 1.66634C5.76751 1.66634 4.63267 2.0844 3.72955 2.78644ZM11.2132 3.72925L9.78105 5.16141C10.1301 5.68837 10.3333 6.32029 10.3333 6.99967C10.3333 7.67906 10.1301 8.31098 9.78105 8.83793L11.2132 10.2701C11.9153 9.36698 12.3333 8.23215 12.3333 6.99967C12.3333 5.7672 11.9153 4.63237 11.2132 3.72925ZM10.2704 11.2129L8.83824 9.78074C8.31128 10.1298 7.67937 10.333 6.99998 10.333C6.32059 10.333 5.68867 10.1298 5.16172 9.78074L3.72955 11.2129C4.63267 11.915 5.76751 12.333 6.99998 12.333C8.23245 12.333 9.36729 11.915 10.2704 11.2129ZM0.333313 6.99967C0.333313 3.31778 3.31808 0.333008 6.99998 0.333008C10.6819 0.333008 13.6666 3.31778 13.6666 6.99967C13.6666 10.6816 10.6819 13.6663 6.99998 13.6663C3.31808 13.6663 0.333313 10.6816 0.333313 6.99967ZM4.99998 6.99967C4.99998 8.10424 5.89541 8.99967 6.99998 8.99967C8.10455 8.99967 8.99998 8.10424 8.99998 6.99967C8.99998 5.89511 8.10455 4.99967 6.99998 4.99967C5.89541 4.99967 4.99998 5.89511 4.99998 6.99967Z"
      fill={color}
    />
  </svg>
);

IconSupport.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconSupport.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconSupport;
