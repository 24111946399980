import PropTypes from 'prop-types';
import * as Styled from './styles';

const Subtitle = ({
  text,
}) => (
  <Styled.Subtitle>{text}</Styled.Subtitle>
);

Subtitle.propTypes = {
  text: PropTypes.string,
};

Subtitle.defaultProps = {
  text: '',
};

export default Subtitle;
