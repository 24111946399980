import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { useTitle } from 'react-use';
import { get } from 'lodash';
import { ThemeProvider } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import Login from 'auth/Login';
import ForgotPassword from 'auth/ForgotPassword';
import ResetPassword from 'auth/ResetPassword';
import Register from 'auth/Register';
import AppContainer from 'AppContainer';
import { getUser } from 'redux/account/actions';
import { SplashScreen } from 'shared/components';
import { Auth } from 'shared/HOCs';
import { Theme } from './Theme';

const App = () => {
  // useTitle('Kobofunds');
  const dispatch = useDispatch();
  const userLoading = useSelector(state => get(state, 'account.userLoading'));

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  if (userLoading) {
    return (
      <ThemeProvider theme={Theme}>
        <SplashScreen />
      </ThemeProvider>
    );
  }

  return (
    <HelmetProvider>
      <ThemeProvider theme={Theme}>
        <div className="App">
          <Helmet>
            <title>Kobofunds</title>
          </Helmet>
          <BrowserRouter>
            <Switch>
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route path="/" component={Auth(AppContainer)} />
            </Switch>
          </BrowserRouter>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              duration: 2500,
              style: {
                borderRadius: get(Theme, 'borderRadius'),
                fontSize: '12px',
              },
              success: {
                iconTheme: {
                  primary: get(Theme, 'colors.success.success50'),
                },
              },
              error: {
                iconTheme: {
                  primary: get(Theme, 'colors.danger.danger50'),
                },
              },
            }}
          />
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
