import { Button } from 'shared/components';
import { IconAlert } from 'shared/icons';
import * as Styled from './styles';

const GlobalError = () => (
  <Styled.GlobalError>
    <Styled.ErrorWrapper>
      <IconAlert color="#34545D" height={33} width={33} />
      <Styled.ErrorTitle>Error occured</Styled.ErrorTitle>
      <Styled.ErrorText>
        Our tech team has been notified, to restart browsing please click on a button below
      </Styled.ErrorText>
      <Button
        onClick={() => {
          window.location = '/';
        }}
      >
        Restart
      </Button>
    </Styled.ErrorWrapper>
  </Styled.GlobalError>
);

export default GlobalError;
