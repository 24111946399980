import { useState } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkKycStatus } from 'shared/helpers';
import { useWindowDimensions } from 'shared/HOCs';
import {
  Alert,
  Page,
  OnboardingGallery,
  NotAuthorized,
} from 'shared/components';
import {
  IconCamera,
} from 'shared/icons';
import KycCamera from './KycCamera';
import KycConfirm from './KycConfirm';
import * as Styled from './styles';

const Kyc = () => {
  const { isTablet } = useWindowDimensions();
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const kYCStatus = checkKycStatus(get(user, 'status'));
  const external = kYCStatus.isExternal;
  const validated = kYCStatus.isValidated;

  const [isOnboardingFinished, setOnboardingFinished] = useState(false);
  const [isKycFinished, setKycFinished] = useState(false);
  const [kycData, setKycData] = useState(null);
  const [documentType, setDocumentType] = useState(null);

  const getFinishBtnContent = () => (
    <Styled.FinishBtn>
      <span>Open camera</span>
      <IconCamera />
    </Styled.FinishBtn>
  );

  const onFinishConfirm = (val) => {
    setDocumentType(val);
    setOnboardingFinished(true);
  };

  const onKycComplete = (data) => {
    setKycData(data);
    setKycFinished(true);
  };

  const onConfirm = () => {
    history.push('/');
  };

  const handleRetry = () => {
    setKycFinished(false);
    setKycData(null);
  };

  if (external) {
    return (
      <NotAuthorized
        title="You have not been verified"
        text="Platform admin needs to verify you before you can use the app."
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  if (validated && !isOnboardingFinished) {
    return (
      <NotAuthorized
        title="You have been verified already"
        text=""
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  if (!isTablet) {
    return (
      <Page type="kyc" title="Proof of identity">
        <Styled.Kyc>
          <Alert
            isOpen
            title="Mobile device not detected"
            text="Verify your identity on mobile and gain full access to all features"
            primaryBtnText="Go to dashboard"
            onPrimaryBtnClick={() => history.push('/login')}
            secondaryBtnText="Contact support"
            onSecondaryBtnClick={() => history.push('/support')}
          />
        </Styled.Kyc>
      </Page>
    );
  }

  return (
    <Page type="kyc" title="Proof of identity">
      <Styled.Kyc>
        {!isOnboardingFinished && (
          <OnboardingGallery
            finishBtn={getFinishBtnContent()}
            onFinishConfirm={onFinishConfirm}
          />
        )}
        {isOnboardingFinished && !isKycFinished && (
          <KycCamera
            closeCb={() => setOnboardingFinished(false)}
            onKycComplete={onKycComplete}
            documentType={documentType}
          />
        )}
        {isKycFinished && (
          <KycConfirm
            data={kycData}
            onConfirm={onConfirm}
            onRetry={handleRetry}
            documentType={documentType}
          />
        )}
      </Styled.Kyc>
    </Page>
  );
};

export default Kyc;
