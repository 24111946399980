import axios from 'axios';
// import { get } from 'lodash';

axios.defaults.withCredentials = true;
// axios.interceptors.response.use((response) => {
//   if (response.status === 401
//     || response.status === 403) {
//     window.location.href = '/login'; // eslint-disable-line no-undef
//   }
//   return response;
// }, (error) => {
//   const errorResponseStatus = get(error, 'response.status');
//   // const errorResponseData = get(error, 'response.data');
//   if (errorResponseStatus === 403 || errorResponseStatus === 401) {
//     // console.log(errorResponseData);
//     if (!window.location.pathname.includes('/login')) {
//       window.location.href = '/login';
//     }
//   }
//   return Promise.reject(error);
// });

const isDevelopment = process.env.NODE_ENV === 'development';
const endpoint = '';

const buildDevUrl = (url) => {
  const port3050 = [
    '/api/user-management/auth',
    '/api/user-management/register',
    '/api/user-management/change-password',
    '/api/user-management/reset-password',
    '/api/user-management/user-roles',
    '/api/user-management/user-profiles',
    '/api/user-management/users',
    '/api/user-management/user-roles',
    '/api/user-management/user-tags',
    '/api/user-management/files',
    '/api/user-management/bank-accounts',
    '/api/user-management/tickets',
    '/api/user-management/countries',
    '/api/user-management/currencies',
    '/api/user-management/business-entities',
    '/api/user-management/business-entity-tags',
  ];
  const port3051 = [
    'api/yoc/invitations',
    'api/yoc/topologies',
    'api/yoc/topology-types',
    'api/yoc/subtopologies',
    '/api/yoc/users',
  ];
  const port3052 = [
    'api/kyc/upload-file',
    'api/kyc/compare-faces',
    '/api/kyc/extract-text',
  ];
  const port3053 = [
    '/api/profiling/questionnaires',
    '/api/profiling/user-questionnaires',
    '/api/profiling/user-questionnaire-answers',
  ];
  const port3054 = [
    '/api/investing/offerings',
    '/api/investing/offering-tags',
    '/api/investing/users/',
    '/api/investing/business-entities/',
    '/api/investing/deal-rooms/',
  ];
  const port3056 = [
    '/api/notifying',
  ];
  const port3049 = [
    '/api/debugging',
  ];
  let devEndpoint;

  port3050.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3050${url}`; }
  });

  port3051.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3051${url}`; }
  });

  port3052.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3052${url}`; }
  });

  port3053.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3053${url}`; }
  });

  port3054.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3054${url}`; }
  });

  port3056.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3056${url}`; }
  });

  port3049.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3049${url}`; }
  });
  return devEndpoint || url;
};

const getPath = url => {
  if (isDevelopment) {
    const devURL = buildDevUrl(url);
    return devURL;
  }
  return `${endpoint}${url}`;
};

const api = {
  get: (url, config = undefined) => axios.get(getPath(url), config),
  post: (url, data = undefined, config = undefined) => axios.post(getPath(url), data, config),
  patch: (url, data, config) => axios.patch(getPath(url), data, config),
  delete: (url, data = undefined, config = undefined) => axios.delete(getPath(url), { data }, config), // { data: { params to be sent here } }
};

export default api;
