import PropTypes from 'prop-types';

const IconAttachment = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 19 20" fill="none" {...rest} className="IconAttachment">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88301 2.87439L3.46453 9.46456C1.51191 11.4172 1.51184 14.5829 3.46447 16.5356C5.41709 18.4882 8.58291 18.4882 10.5355 16.5356L10.5364 16.5347L16.7937 10.2921C17.1847 9.90203 17.8179 9.90277 18.2079 10.2938C18.598 10.6847 18.5973 11.3179 18.2063 11.708L11.9497 17.9498C11.9496 17.9499 11.9499 17.9496 11.9497 17.9498C9.21604 20.683 4.78377 20.6833 2.05025 17.9498C-0.681799 15.2177 -0.683417 10.7892 2.0454 8.05514L8.4644 1.46444C10.417 -0.488186 13.5829 -0.488124 15.5355 1.4645C17.4865 3.41548 17.4882 6.57764 15.5404 8.53066L9.12138 15.1214C7.94981 16.293 6.05025 16.2929 4.87868 15.1214C3.70711 13.9498 3.70711 12.0503 4.87868 10.8787L11.4645 4.29292C11.855 3.9024 12.4882 3.9024 12.8787 4.29292C13.2692 4.68345 13.2692 5.31661 12.8787 5.70714L6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071C6.68212 14.0964 7.31239 14.0977 7.70322 13.711L14.1213 7.12129C15.2928 5.94972 15.2929 4.05028 14.1213 2.87871C12.9512 1.70858 11.0549 1.70714 9.88301 2.87439Z"
      fill={color}
    />
  </svg>
);

IconAttachment.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconAttachment.defaultProps = {
  color: '#615F59',
  height: 20,
  width: 19,
};

export default IconAttachment;
