/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const CircleSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: relative;

  /* .placeholder {
    margin-top: -5px;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #96dbfa;
    background-color: #fff;
    touch-action: pan-x;
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #012934;
    position: absolute;
    top: 6px;
  } */

  .CircleSlider {
    /* width: 300px; */
    width: 100%;

    .rc-slider-rail {
      height: 12px;
      background-color: ${props => props.theme.colors.primary.daintree.daintree05};
    }

    .rc-slider-track {
      height: 12px;
      background-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    .rc-slider-step {

      .rc-slider-dot {
        bottom: -4px;
        height: 4px;
        width: 4px;
        border: none;
        margin-left: 0;
        background-color: ${props => props.theme.colors.primary.daintree.daintree50};

        &:last-of-type {
          left: 98% !important;
        }
      }

      .rc-slider-dot-active {
        background-color: ${props => props.theme.colors.shades.neutral.white};
      }
    }

    .rc-slider-handle {
      width: 20px;
      height: 20px;
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.primary.daintree.daintree50};
    }

    .rc-slider-mark-text {
      top: -55px;
      text-align: center;
      display: flex;
      align-items: center;
      height: 30px;
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.shades.neutral.gray90};
      transform: initial !important;

      &:first-of-type {
        /* width: 10px; */
        text-align: start;
      }

      &:last-of-type {
        /* width: 10px; */
        text-align: end;
        left: 86% !important;
      }
    }
  }

  ${props => {
    if (props.value === null) {
      return css`
        .rc-slider-track {
          background-color: ${() => props.theme.colors.primary.daintree.daintree05} !important;
        }

        .rc-slider-dot-active {
          background-color: ${() => props.theme.colors.primary.daintree.daintree50} !important;
        }
      `;
    }
  }}
`;
