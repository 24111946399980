import PropTypes from 'prop-types';

const IconUserInvite = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 14" fill="none" {...rest} className="IconUserInvite">
    <path d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z" fill={color} />
    <path d="M6 8C9.31371 8 12 10.6863 12 14H0C0 10.6863 2.68629 8 6 8Z" fill={color} />
    <path d="M14 4C14 3.44772 13.5523 3 13 3C12.4477 3 12 3.44772 12 4V5H11C10.4477 5 10 5.44771 10 6C10 6.55228 10.4477 7 11 7H12V8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8V7H15C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5H14V4Z" fill={color} />
  </svg>
);

IconUserInvite.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUserInvite.defaultProps = {
  color: '#012934',
  height: 14,
  width: 16,
};

export default IconUserInvite;
