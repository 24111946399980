import PropTypes from 'prop-types';

const EmojiConfused = ({
  height,
  width,
  background,
  strokes,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" {...rest} className="EmojiConfused">
    <path d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0 8.06 8.06 0 18 0C27.941 0 36 8.06 36 18Z" fill={background} />
    <path d="M11.5 20C12.8807 20 14 18.433 14 16.5C14 14.567 12.8807 13 11.5 13C10.1193 13 9 14.567 9 16.5C9 18.433 10.1193 20 11.5 20Z" fill={strokes} />
    <path d="M24.5 20C25.8807 20 27 18.433 27 16.5C27 14.567 25.8807 13 24.5 13C23.1193 13 22 14.567 22 16.5C22 18.433 23.1193 20 24.5 20Z" fill={strokes} />
    <path d="M12 28C14 23 25 23 25 25C25 26 17 24 12 28Z" fill={strokes} />
  </svg>
);

EmojiConfused.propTypes = {
  background: PropTypes.string,
  strokes: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EmojiConfused.defaultProps = {
  background: '#FFCC4D',
  strokes: '#664500',
  height: 26,
  width: 26,
};

export default EmojiConfused;
