import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import api from 'shared/api';
import { formatDate } from 'shared/helpers';
import {
  Button,
  PageLoader,
  Table,
  UserInfoCell,
} from 'shared/components';
import AddOfferingOwnerForm from '../AddOfferingOwnerForm';
import * as Styled from './styles';

const OfferingOwners = ({
  offering,
  isAuth,
}) => {
  const history = useHistory();
  const offeringID = get(offering, 'id');
  const countries = useSelector(state => get(state, 'investing.countries'));

  const [isLoading, setLoading] = useState(true);
  const [isAddMemberDisplayed, setAddMemberDisplayed] = useState(false);
  const [owners, setOwners] = useState([]);

  const getOwners = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/owners`)
      .then((res) => {
        setOwners(get(res, 'data') || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getOwners();
  }, [getOwners]);

  const tableData = useMemo(() => owners, [owners]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'profile.full_name',
      Cell: (cell) => {
        const country = get(cell, 'row.original.profile.country_of_residence') || '';
        const countryOfResidence = countries.find(c => get(c, 'id') === country);
        const city = get(cell, 'row.original.profile.city') || '';
        const address = `${city}${country ? `, ${get(countryOfResidence, 'name')}` : ''}`;

        return (
          <UserInfoCell
            profileImg={get(cell, 'row.original.profile.profile_picture')}
            name={get(cell, 'value')}
            metaFirst={get(cell, 'row.original.profile.title')}
            metaSecond={address}
          />
        );
      },
      minWidth: 250,
    },
    {
      Header: 'Created at',
      accessor: 'owner.created_at',
      Cell: cell => formatDate(get(cell, 'value')),
    },
  ], [owners]);

  const handleRowClick = row => {
    history.push(`/yoc/${get(row, 'profile.user_id')}`);
  };

  if (isLoading) {
    return <Styled.OfferingOwners><PageLoader /></Styled.OfferingOwners>;
  }

  return (
    <Styled.OfferingOwners>
      {isAuth && (
        <Button
          size="small"
          type="submit"
          handleClick={() => setAddMemberDisplayed(true)}
        >
          Add owner
        </Button>
      )}
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onRowClick={handleRowClick}
        rowClassName="UsersListRow"
      />
      {isAddMemberDisplayed && (
        <AddOfferingOwnerForm
          isOpen={isAddMemberDisplayed}
          closeCb={() => setAddMemberDisplayed(false)}
          offering={offering}
          refetchOwners={getOwners}
        />
      )}
    </Styled.OfferingOwners>
  );
};

OfferingOwners.propTypes = {
  offering: PropTypes.object.isRequired,
  isAuth: PropTypes.bool,
};

OfferingOwners.defaultProps = {
  isAuth: false,
};

export default OfferingOwners;
