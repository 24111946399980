import PropTypes from 'prop-types';
import Sidebar from 'Menu/Sidebar';
import MobileMenu from 'Menu/MobileMenu';
import { useWindowDimensions } from 'shared/HOCs';
import * as Styled from './styles';

const Page = ({
  showBack,
  children,
  title,
  type,
}) => {
  const { isTablet } = useWindowDimensions();
  return (
    <Styled.Page isMobile={isTablet}>
      {isTablet ? <MobileMenu title={title} type={type} showBack={showBack} /> : <Sidebar />}
      <Styled.PageContent>
        {children}
      </Styled.PageContent>
    </Styled.Page>
  );
};

Page.propTypes = {
  showBack: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
};

Page.defaultProps = {
  showBack: false,
  title: '',
  type: '',
};

export default Page;
