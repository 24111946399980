import styled from 'styled-components';

export const OrganizationOverview = styled.div`
  margin-top: 16px;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Value = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-bottom: 16px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const ContactItem = styled.a`
  appearance: none;
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  margin: 0 8px 8px 0;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};

  span {
    margin-left: 10px;
  }
`;

export const Description = styled.div`
  padding-top: 16px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${props => props.theme.colors.shades.warm.dawn20};
`;
