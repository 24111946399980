import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import api from 'shared/api';
import { Page, PageLoader } from 'shared/components';
import ProfilePage from 'ProfilePage';

const YocDetails = () => {
  const params = useParams();
  const history = useHistory();
  const { yocID } = params;
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');
  const isLoggedIn = userID === Number(yocID);

  const [isLoading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [invitations, setInvitations] = useState([]);

  const getPendingInvitations = useCallback(() => {
    api.get(`/api/yoc/users/${yocID}/invitations`)
      .then((res) => {
        setInvitations(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [yocID]);

  const getProfile = useCallback(() => {
    api.get(`/api/user-management/user-profiles/${yocID}`)
      .then((res) => {
        setProfileData(get(res, 'data'));
        return getPendingInvitations();
      })
      .catch(() => {
        setLoading(false);
      });
  }, [yocID]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  if (isLoading) {
    return <Page showBack type="yoc_user" title="Your own circle"><PageLoader /></Page>;
  }

  const title = get(profileData, 'user.full_name') || get(profileData, 'user.user_name');

  return (
    <Page showBack type="yoc_user" title={title}>
      <ProfilePage
        user={get(profileData, 'user')}
        connection={get(profileData, 'connection')}
        userClickCb={(u) => {
          history.push(`/yoc/${get(u, 'user_id')}`);
        }}
        refetchProfile={getProfile}
        isAuth={isLoggedIn}
        invitations={invitations}
      />
    </Page>
  );
};

export default YocDetails;
