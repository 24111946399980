import { Route, Switch } from 'react-router-dom';
import NotificationsList from './NotificationsList';

const Notifications = () => (
  <Switch>
    <Route exact path="/notifications" component={NotificationsList} />
    {/* <Route exact path="/notifications/:notificationID" component={OfferingPage} /> */}
  </Switch>
);

export default Notifications;
