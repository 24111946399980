import styled from 'styled-components';

export const InvestForm = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Organization = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;

  button {
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    min-width: 100%;
  }
`;

export const Form = styled.form``;

export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 20px;

  .TextInput {
    max-width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    max-width: 100%;
  }
`;

export const Acknowledge = styled.div`
  margin-top: 10px;
  font-size: 14px;

  p {
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.colors.shades.warm.dawn90};
    font-weight: bold;
  }
`;

export const DetailsContainer = styled.div`
  padding: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33%;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 48%;
  }
`;

export const DetailLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const DetailValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;
