import styled from 'styled-components';

export const YocWrapper = styled.section`
  width: 100%;
  /* height: calc(100vh - 90px); */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 90px);
  display: flex;
  justify-content: center;
  position: relative;
`;

export const YocList = styled.div`
  width: 100%;
  max-width: 920px;
`;

export const ListHeader = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.shades.warm.dawn05};

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 12px 8px;
  }
`;

export const ListSearch = styled.form`
  display: flex;
  align-items: center;

  .Button {
    margin: 0;

    svg {
      margin: 0;
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    /* TODO return after filters are done */
    /* width: 80%; */
    width: 100%;

    .TextInput {
      max-width: 100%;
    }
  }
`;

export const ListAction = styled.div`
  display: flex;
  align-items: center;
`;

export const AddBtn = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: relative;
  border: none;
  margin: 0 0 0 14px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 0 0 0 24px;
  }
`;

export const ListPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding-bottom: 60px;
`;
