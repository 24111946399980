import styled, { css } from 'styled-components';

const getSuccessVariation = (props) => css`
  border-color: ${() => props.theme.colors.success.success50};
  background-color: ${() => props.theme.colors.success.success05};

  .Title {
    color: ${() => props.theme.colors.success.success50};
  }

  .Content {
    border-top-color: ${() => props.theme.colors.success.success50};
  }
`;

const getErrorVariation = (props) => css`
  border-color: ${() => props.theme.colors.danger.danger50};
  background-color: ${() => props.theme.colors.danger.danger05};

  .Title {
    color: ${() => props.theme.colors.danger.danger50};
  }

  .Content {
    border-top-color: ${() => props.theme.colors.danger.danger50};
  }
`;

const getDefaultVariation = (props) => css`
  border-color: ${() => props.theme.colors.shades.warm.dawn80};
  background-color: ${() => props.theme.colors.shades.warm.dawn05};

  .Title {
    color: ${() => props.theme.colors.shades.warm.dawn80};
  }

  .Content {
    border-top-color: ${() => props.theme.colors.shades.warm.dawn80};
  }
`;

const typeStyling = (props) => {
  const { type } = props;

  switch (type) {
    case 'default':
      return getDefaultVariation(props);
    case 'error':
      return getErrorVariation(props);
    case 'success':
      return getSuccessVariation(props);
    default:
      return getDefaultVariation(props);
  }
};

export const Notice = styled.div`
  display: flex;
  flex-direction: column;
  background: #F6F5F4;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn80};
  background-color: ${props => props.theme.colors.shades.warm.dawn05};
  margin-bottom: 32px;

  ${props => typeStyling(props)}
`;

export const Title = styled.div`
  padding: 18px 16px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  svg {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  border-top-width: 1px;
  border-top-style: solid;
  padding: 18px 16px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.shades.warm.dawn90};

  a {
    color: ${props => props.theme.colors.primary.daintree.daintree40};
    font-weight: 600;
    margin-left: 4px;
  }
`;
