import styled from 'styled-components';

export const KycCamera = styled.div``;

export const KycCameraHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.shades.neutral.white};
  flex-shrink: 0;
`;

export const ImagePreview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  img {
    min-height: 60vh;
    max-height: 400px;
    max-width: 600px;
    width: 100%;

    @media screen and (max-width: 380px) {
      min-height: 340px;
      max-height: 340px;
    }

    @media screen and (max-width: 350px) {
      min-height: 250px;
      max-height: 250px;
    }
  }
`;

export const CameraPreview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  div {
    position: relative;

    video {
      min-height: 60vh;
      max-height: 435px;
      max-width: 600px;

      @media screen and (max-width: 380px) {
        min-height: 340px;
        max-height: 340px;
      }

      @media screen and (max-width: 350px) {
        min-height: 250px;
        max-height: 250px;
      }
    }
  }
`;

export const KycCameraFooter = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
`;

export const FooterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: ${props => props.theme.colors.shades.neutral.white};
  text-align: center;
`;

export const FooterActions = styled.div`
  margin-top: auto;

  .Button {
    svg {
      margin: 0;
    }
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;

  button {
    padding: 0;
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(56, 56, 56, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;
