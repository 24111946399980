import PropTypes from 'prop-types';

const IconPhone = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...rest} className="IconPhone">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.08289 6.66667H5.33333C6.06971 6.66667 6.66667 6.06971 6.66667 5.33333V2.66667C6.66667 1.19391 5.47276 0 4 0H1.33333C0.596954 0 0 0.596954 0 1.33333C0 9.02441 6.97559 16 14.6667 16C15.403 16 16 15.403 16 14.6667V12C16 10.5272 14.8061 9.33333 13.3333 9.33333H10.6667C9.93029 9.33333 9.33333 9.93029 9.33333 10.6667V11.9171C7.27942 10.7965 5.51253 9.08792 4.31477 7.07354L4.08289 6.66667Z"
      fill={color}
    />
  </svg>
);

IconPhone.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconPhone.defaultProps = {
  color: '#012934',
  height: 16,
  width: 16,
};

export default IconPhone;
