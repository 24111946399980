import * as actionTypes from './actionTypes';

const refreshSidebarSize = sidebarSize => ({
  type: actionTypes.changeSidebarSize,
  sidebarSize,
});

export const changeSidebarSize = sidebarSize => (dispatch) => {
  dispatch(refreshSidebarSize(sidebarSize));
};
