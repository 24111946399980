import styled from 'styled-components';

export const AddTicketForm = styled.div`

`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 12px 24px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const HeaderTitleText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  font-weight: bold;
`;

export const HeaderClose = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailsInner = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.neutral.gray50};
  padding: 0 24px 12px 24px;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const MessageContainer = styled.div`
  padding: 12px 24px;
`;

export const Message = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.shades.warm.dawn05};
  border-radius: 8px;
  margin: 16px 0;
`;

export const MessageText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const InputContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  display: flex;
  align-items: center;

  .TextInput {
    max-width: unset;
  }

  .Button {
    height: 40px;
    width: 40px;
    margin-left: 8px;

    .IconSend {
      flex-shrink: 0;
    }
  }
`;
