import PropTypes from 'prop-types';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './styles.css';

const SidePanel = ({
  close,
  children,
  isOpen,
  width,
  type,
}) => (
  <SlidingPane
    onRequestClose={close}
    isOpen={isOpen}
    width={width}
    type={type}
    hideHeader
    shouldCloseOnEsc
    className="SlidePane"
    overlayClassName="SlidePaneOverlay"
  >
    {children}
  </SlidingPane>
);

SidePanel.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  width: PropTypes.string,
};

SidePanel.defaultProps = {
  isOpen: false,
  width: '450px',
  type: 'right',
};

export default SidePanel;
