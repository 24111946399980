import { get } from 'lodash';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { changeSidebarSize } from 'redux/ui/actions';
import {
  logout,
  logoutError,
  logoutSuccess,
} from 'redux/account/actions';
import {
  IconBell,
  IconHamburger,
  IconHome,
  IconLogout,
  IconOffering,
  IconOrganization,
  IconKey,
  IconKyc,
  IconYOC,
  IconSurvey,
  IconUserOutline,
  IconSupport,
} from 'shared/icons';
import { checkKycStatus, canCreateAsset } from 'shared/helpers';
import Logo from 'shared/assets/logo_white.png';
import { ReactComponent as LongLogo } from 'shared/assets/logo_long_white.svg';
import version from 'version';
import * as Styled from './styles';

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sidebarSize = useSelector(state => get(state, 'ui.sidebarSize'));
  const expandedSidebar = sidebarSize === 'lg';
  const user = useSelector(state => get(state, 'account.user'));
  const kYCStatus = checkKycStatus(get(user, 'status'));
  const external = kYCStatus.isExternal;
  const validated = kYCStatus.isValidated;
  const isAllowedToCreate = canCreateAsset(user, false);

  const organizations = useSelector(state => get(state, 'investing.organizations'));
  // notifications
  const unreadNotifications = useSelector(state => get(state, 'notifications.unreadNotifications'));
  const unreadNotificationsCount = unreadNotifications.length;

  const handleSizeChange = () => {
    if (sidebarSize === 'lg') {
      return dispatch(changeSidebarSize('sm'));
    }
    return dispatch(changeSidebarSize('lg'));
  };

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        toast.success('Logout successful');
        history.push('/login');
      })
      .catch((err) => {
        dispatch(logoutError(err));
        toast.error('Unable to logout');
      });
  };

  return (
    <Styled.Sidebar size={sidebarSize}>
      <Styled.Toggle>
        <Styled.ToggleBtn type="button" onClick={handleSizeChange}>
          <IconHamburger />
        </Styled.ToggleBtn>
      </Styled.Toggle>
      <Styled.Nav>
        <Styled.EntityDetails className="EntityDetails">
          {expandedSidebar ? (
            <LongLogo width={120} />
          ) : (
            <Styled.EntityLogo src={Logo} alt="logo" />
          )}
        </Styled.EntityDetails>
        <Styled.NavItem>
          <NavLink to="/" exact className="SidebarLink">
            <IconHome />
            <Styled.NavItemText className="NavItemText">Dashboard</Styled.NavItemText>
          </NavLink>
        </Styled.NavItem>
        {!external && !validated && (
          <Styled.NavItem>
            <NavLink to="/kyc" exact className="SidebarLink">
              <IconKyc height={14} width={14} />
              <Styled.NavItemText className="NavItemText">Proof of identity</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        )}
        {false && (
          <Styled.NavItem>
            <NavLink to="/surveys" exact className="SidebarLink">
              <IconSurvey height={14} width={14} color="#E6EAEB" />
              <Styled.NavItemText className="NavItemText">Surveys</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        )}
        {!external && (
          <Styled.NavItem>
            <NavLink to="/yoc" className="SidebarLink">
              <IconYOC />
              <Styled.NavItemText className="NavItemText">Your Own Circle</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        )}
        {!external && (isAllowedToCreate || !!organizations.length) && (
          <Styled.NavItem>
            <NavLink to="/organizations" className="SidebarLink">
              <IconOrganization height={14} width={14} color="#E6EAEB" />
              <Styled.NavItemText className="NavItemText">Your organizations</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        )}
        {!external && (
          <Styled.NavItem>
            <NavLink to="/offerings" className="SidebarLink">
              <IconOffering />
              <Styled.NavItemText className="NavItemText">Offerings</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        )}
        {!external && (
          <Styled.NavItem>
            <NavLink to="/dealrooms" className="SidebarLink">
              <IconKey />
              <Styled.NavItemText className="NavItemText">Dealrooms</Styled.NavItemText>
            </NavLink>
          </Styled.NavItem>
        )}
        <Styled.NavItem>
          <NavLink to="/support" className="SidebarLink">
            <IconSupport />
            <Styled.NavItemText className="NavItemText">Support</Styled.NavItemText>
          </NavLink>
        </Styled.NavItem>
        <Styled.NavItem>
          <NavLink to="/profile" className="SidebarLink">
            <IconUserOutline height={14} width={14} color="#E6EAEB" />
            <Styled.NavItemText className="NavItemText">Profile</Styled.NavItemText>
          </NavLink>
        </Styled.NavItem>
      </Styled.Nav>
      <Styled.Footer>
        <Styled.NavItem>
          <NavLink to="/notifications" className="SidebarLink">
            <IconBell height={14} width={14} color="#E6EAEB" />
            <Styled.NavItemText className="NavItemText">
              <span className="text">Notifications</span>
              {!!unreadNotificationsCount && <Styled.UnreadCount>{unreadNotificationsCount}</Styled.UnreadCount>}
            </Styled.NavItemText>
            {!expandedSidebar && !!unreadNotificationsCount && <Styled.UnreadCountSmall>{unreadNotificationsCount}</Styled.UnreadCountSmall>}
          </NavLink>
        </Styled.NavItem>
        <Styled.FooterItem>
          <Styled.LogoutBtn type="button" onClick={handleLogout}>
            <IconLogout />
            <Styled.LogoutBtnText className="FooterItemText">Log out</Styled.LogoutBtnText>
          </Styled.LogoutBtn>
        </Styled.FooterItem>
      </Styled.Footer>
      <Styled.Version>
        {expandedSidebar ? <Styled.ExpandedVersion>{version}</Styled.ExpandedVersion> : <Styled.VersionSmall>{version}</Styled.VersionSmall>}
      </Styled.Version>
    </Styled.Sidebar>
  );
};

export default Sidebar;
