import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';

const KybCard = ({
  organization,
}) => {
  const organizationID = get(organization, 'id');
  const organizationName = get(organization, 'name');
  const history = useHistory();

  return (
    <Styled.KybCard type="button" onClick={() => history.push(`/organizations/${organizationID}`)}>
      <Styled.Title>{`Pending KYB for ${organizationName}`}</Styled.Title>
      <Styled.Text>Submit KYB before you can publish the organization.</Styled.Text>
    </Styled.KybCard>
  );
};

KybCard.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default KybCard;
