import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  SidePanel,
} from 'shared/components';
import {
  IconClose,
  IconSecurity,
  IconPasswordHide,
  IconPasswordShow,
} from 'shared/icons';
import api from 'shared/api';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required(),
  new_password: yup
    .string()
    .required(),
  // .oneOf([yup.ref('old_password'), null], 'Passwords must match'),
});

const ChangePasswordForm = ({
  isOpen,
  onClose,
}) => {
  const currentUser = useSelector(state => get(state, 'account.user'));
  const userID = get(currentUser, 'user_id');

  const [isLoading, setLoading] = useState(false);
  const [displayOldPassword, setOldPasswordDisplay] = useState(false);
  const [displayNewPassword, setNewPasswordDisplay] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (isLoading) {
      return false;
    }

    const passwordData = {
      user_id: userID,
      old_password: data.old_password,
      new_password: data.new_password,
    };
    api.post('/api/user-management/change-password', passwordData)
      .then(() => {
        toast.success('Password changed succesfully');
        onClose();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <SidePanel isOpen={isOpen} close={onClose}>
      <Styled.ChangePasswordForm>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconSecurity />
            <Styled.HeaderTitleText>Change password</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={onClose}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.DetailsRow>
            <Styled.InputContainer>
              <Controller
                name="old_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="old_password"
                    label="Old password"
                    placeholder="Your current password"
                    error={errors.old_password?.message}
                    type={displayOldPassword ? 'text' : 'password'}
                    button={displayOldPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                    onBtnClick={() => setOldPasswordDisplay(!displayOldPassword)}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.InputContainer>
              <Controller
                name="new_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="new_password"
                    label="New password"
                    placeholder="Your new password"
                    error={errors.new_password?.message}
                    type={displayNewPassword ? 'text' : 'password'}
                    button={displayNewPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                    onBtnClick={() => setNewPasswordDisplay(!displayNewPassword)}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
          </Styled.DetailsRow>
          <Button
            size="large"
            type="submit"
            isLoading={isLoading}
            loaderColor="#fff"
          >
            Submit
          </Button>
        </Styled.Form>
      </Styled.ChangePasswordForm>
    </SidePanel>
  );
};

ChangePasswordForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
