import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import { SidePanel, Selector } from 'shared/components';
import { IconClose, IconSupportFill } from 'shared/icons';
import { formatDate, getTicketSeverity } from 'shared/helpers';
import api from 'shared/api';
import { defaultDateTimeFormat, ticketSeverityOptions } from 'shared/constants';
import * as Styled from './styles';

const TicketDetails = ({
  isOpen,
  onClose,
  ticket,
  refreshTickets,
}) => {
  const ticketID = get(ticket, 'id');
  const [isLoading, setLoading] = useState(false);
  const [severity, setSeverity] = useState(getTicketSeverity(get(ticket, 'severity')));

  const onSubmit = (val) => {
    if (isLoading) {
      return false;
    }

    const formData = {
      severity: get(val, '[0].value'),
    };

    setLoading(true);
    api.patch(`/api/user-management/tickets/${ticketID}`, formData)
      .then(() => {
        refreshTickets();
        toast.success('Survey ticket updated');
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <SidePanel isOpen={isOpen} close={onClose}>
      <Styled.TicketDetails>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconSupportFill />
            <Styled.HeaderTitleText>{`Ticket #${ticket.id}`}</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={onClose}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.DetailsInner>
          <Styled.DetailsRow>
            <Styled.RowLabel>Created:</Styled.RowLabel>
            <Styled.RowValue>{formatDate(get(ticket, 'created_at'), defaultDateTimeFormat)}</Styled.RowValue>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Selector
              id="severity-input"
              name="severity"
              label="Priority"
              placeholder="Change priority"
              options={ticketSeverityOptions}
              onChange={(val) => {
                setSeverity(val);
                onSubmit(val);
              }}
              values={severity}
              labelField="label"
              valueField="value"
            />
          </Styled.DetailsRow>
        </Styled.DetailsInner>
        <Styled.MessageContainer>
          <Styled.Message>
            <Styled.MessageText>
              {get(ticket, 'text')}
            </Styled.MessageText>
          </Styled.Message>
        </Styled.MessageContainer>
      </Styled.TicketDetails>
    </SidePanel>
  );
};

TicketDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshTickets: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired,
};

export default TicketDetails;
