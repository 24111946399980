import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const ProgressBar = ({
  percentage, min, color, height,
}) => {
  const progressBarWidth = `${Math.max(min, percentage)}%`;

  return (
    <Styled.ProgressBar height={height}>
      <Styled.FilledBar width={progressBarWidth} color={color} />
    </Styled.ProgressBar>
  );
};

ProgressBar.propTypes = {
  min: PropTypes.number,
  height: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

ProgressBar.defaultProps = {
  min: 1,
  height: '4px',
  percentage: 0,
  color: '',
};

export default ProgressBar;
