import PropTypes from 'prop-types';
// import { get } from 'lodash';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import * as Styled from './styles';

const OrganizationCard = ({
  organization,
  onClick,
}) => {
  const {
    name,
    industry,
    bio,
    logo,
  } = organization;

  return (
    <Styled.OrganizationCard type="button" onClick={onClick}>
      <Styled.LogoContainer>
        <Styled.Logo src={logo || noImagePlaceholder} alt="company logo" />
      </Styled.LogoContainer>
      <Styled.DataContainer>
        {name && <Styled.Title>{name}</Styled.Title>}
        {industry && <Styled.Industry>{industry}</Styled.Industry>}
        <Styled.Description>{bio || 'Description N/A'}</Styled.Description>
      </Styled.DataContainer>
    </Styled.OrganizationCard>
  );
};

OrganizationCard.propTypes = {
  organization: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

OrganizationCard.defaultProps = {
  onClick: () => {},
};

export default OrganizationCard;
