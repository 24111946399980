import PropTypes from 'prop-types';

const IconKey = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconKey">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99992 1.66732C7.15897 1.66732 5.66659 3.1597 5.66659 5.00065C5.66659 5.33854 5.71665 5.66352 5.8093 5.96923C5.88064 6.20459 5.8166 6.46011 5.6427 6.63401L1.66658 10.6101L1.66659 12.334H2.99992V11.6673C2.99992 11.2991 3.2984 11.0007 3.66659 11.0007H4.33325V10.334C4.33325 9.96579 4.63173 9.66732 4.99992 9.66732H6.05711L7.36656 8.35787C7.54046 8.18397 7.79598 8.11993 8.03134 8.19127C8.33705 8.28392 8.66203 8.33398 8.99992 8.33398C10.8409 8.33398 12.3333 6.8416 12.3333 5.00065C12.3333 3.1597 10.8409 1.66732 8.99992 1.66732ZM4.33325 12.334V13.0007C4.33325 13.3688 4.03478 13.6673 3.66659 13.6673H1.66659C0.930206 13.6673 0.333252 13.0704 0.333252 12.334V10.6101C0.333252 10.2565 0.473727 9.91737 0.723776 9.66732L4.43192 5.95917C4.3672 5.64936 4.33325 5.32868 4.33325 5.00065C4.33325 2.42332 6.42259 0.333984 8.99992 0.333984C11.5772 0.333984 13.6666 2.42332 13.6666 5.00065C13.6666 7.57798 11.5772 9.66732 8.99992 9.66732C8.67189 9.66732 8.35121 9.63337 8.0414 9.56865L6.80466 10.8054C6.67963 10.9304 6.51006 11.0007 6.33325 11.0007H5.66659V11.6673C5.66659 12.0355 5.36811 12.334 4.99992 12.334H4.33325ZM8.33325 3.66732C8.33325 3.29913 8.63173 3.00065 8.99992 3.00065C10.1045 3.00065 10.9999 3.89608 10.9999 5.00065C10.9999 5.36884 10.7014 5.66732 10.3333 5.66732C9.96506 5.66732 9.66659 5.36884 9.66659 5.00065C9.66659 4.63246 9.36811 4.33398 8.99992 4.33398C8.63173 4.33398 8.33325 4.03551 8.33325 3.66732Z"
      fill={color}
    />
  </svg>
);

IconKey.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconKey.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconKey;
