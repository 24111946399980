import PropTypes from 'prop-types';

const IconProfileFill = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconProfileFill">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C1 0.89543 1.89543 0 3 0H11C12.1046 0 13 0.895431 13 2V14C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H10C9.44771 16 9 15.5523 9 15V13C9 12.4477 8.55229 12 8 12H6C5.44772 12 5 12.4477 5 13V15C5 15.5523 4.55228 16 4 16H1C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14V2ZM4 3H6V5H4V3ZM6 7H4V9H6V7ZM8 3H10V5H8V3ZM10 7H8V9H10V7Z"
      fill={color}
    />
  </svg>
);

IconProfileFill.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconProfileFill.defaultProps = {
  color: '#616E7F',
  height: 16,
  width: 14,
};

export default IconProfileFill;
