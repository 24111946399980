/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import * as Styled from './styles';

const RadioBtn = ({
  checked,
  disabled,
  id,
  label,
  name,
  onChange,
  value,
  ...rest
}) => (
  <Styled.RadioBtn disabled={disabled} className="RadioBtn">
    <Styled.RadioBtnLabel htmlFor={id} className="radio-btn-label">
      <Styled.Input
        className="radio-btn-input"
        checked={checked}
        type="radio"
        name={name}
        onChange={onChange}
        id={id}
        disabled={disabled}
        value={value}
        {...rest}
      />
      <Styled.Dot className="radio-btn-dot" />
      <Styled.LabelText>{label}</Styled.LabelText>
    </Styled.RadioBtnLabel>
  </Styled.RadioBtn>
);

RadioBtn.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

RadioBtn.defaultProps = {
  checked: false,
  disabled: false,
  label: '',
  value: '',
};

export default RadioBtn;
