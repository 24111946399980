import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Camera } from 'react-camera-pro';
import toast from 'react-hot-toast';
import ReactModal from 'react-modal';
import api from 'shared/api';
import { IconClose } from 'shared/icons';
import { cameraTypes } from 'shared/constants';
import { sendErrorReport } from 'shared/errorReport';
import { Alert, Button, Loader } from 'shared/components';
import IdGuidelines from './components/IdGuidelines';
import SelfieGuidelines from './components/SelfieGuidelines';
import CameraTitle from './components/CameraTitle';
import CameraFooterText from './components/CameraFooterText';
import CameraFooterActions from './components/CameraFooterActions';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgb(0, 0, 0)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    height: '100%',
  },
};

const KycCamera = ({
  closeCb,
  onKycComplete,
  documentType,
}) => {
  const camera = useRef(null);
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setIsLoading] = useState(false);
  const [cameraType, setCameraType] = useState(cameraTypes.front);
  const [fileID, setFileID] = useState(null);
  const [idCardFrontToken, setIdCardFrontToken] = useState(undefined);
  const [idCardBackToken, setIdCardBackToken] = useState(undefined);
  const [compareFacesFailed, setCompareFacesFailed] = useState(false);

  const [isPreview, setPreview] = useState(false);
  const [image, setImage] = useState(undefined);
  const [numberOfCameras, setNumberOfCameras] = useState(1);

  const isPassport = documentType.value === 'passport';
  const isFrontCamera = cameraType === cameraTypes.front;
  const isBackCamera = cameraType === cameraTypes.back;
  const isSelfieCamera = cameraType === cameraTypes.selfie;

  const handleKycFinish = (selfieKey) => {
    const data = {
      user_id: userID,
      selfie_key: selfieKey,
      id_document_front_key: idCardFrontToken,
    };

    setIsLoading(true);
    api.post('/api/kyc/compare-faces', data)
      .then(() => {
        setIsLoading(false);
        onKycComplete({
          ...data,
          id_document_back_key: idCardBackToken,
        });
      })
      .catch(() => {
        toast.error('KYC face compare has failed');
        setIsLoading(false);
        setCompareFacesFailed(true);
      });
  };

  const setFormData = async (type) => {
    const data = new FormData();
    await fetch(image)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'image');
        data.append('image', file);
        data.append('type', type);
        data.append('user_id', userID);
        if (type !== 'selfie' && !!fileID) {
          data.append('id', fileID);
        }
      });
    return data;
  };

  const handleUploadFileSuccess = (key) => {
    if (isFrontCamera) {
      setIdCardFrontToken(key);
      setPreview(false);
      setImage(undefined);
      if (isPassport) {
        setCameraType(cameraTypes.selfie);
      } else {
        setCameraType(cameraTypes.back);
      }
    } else if (isBackCamera) {
      setPreview(false);
      setImage(undefined);
      setIdCardBackToken(key);
      setCameraType(cameraTypes.selfie);
    } else {
      handleKycFinish(key);
    }
  };

  const sendImage = async () => {
    const type = isSelfieCamera ? 'selfie' : documentType.value;
    const data = await setFormData(type);
    api.post('/api/kyc/upload-file', data)
      .then((res) => {
        const id = get(res, 'data.file.id');
        const key = get(res, 'data.s3_key');
        setIsLoading(false);
        if (!fileID) {
          setFileID(id);
        }
        handleUploadFileSuccess(key);
      })
      .catch((err) => {
        sendErrorReport(err, `upload_${type}_file_failed`, data);
        toast.error('Image upload has failed');
        setIsLoading(false);
      });
  };

  const saveImage = () => {
    if (camera.current) {
      const img = camera.current.takePhoto();
      setImage(img);
      setPreview(true);
    }
  };

  const retryImage = () => {
    setPreview(false);
    setImage(null);
  };

  const submitImage = async () => {
    setIsLoading(true);
    await sendImage();
  };

  const handleCameraReverse = () => {
    if (camera.current) {
      camera.current.switchCamera();
    }
  };

  const getNumOfSteps = () => {
    if (isPassport) {
      return 2;
    }
    return 3;
  };

  const getActiveStep = () => {
    if (isFrontCamera) {
      return 1;
    }
    if (isBackCamera) {
      return 2;
    }
    if (isPassport) {
      return 2;
    }
    return 3;
  };

  return (
    <ReactModal
      isOpen
      style={styles}
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="KycCamera"
    >
      <Styled.CameraPreview>
        <Styled.KycCameraHeader>
          <CameraTitle
            activeStep={getActiveStep()}
            cameraType={cameraType}
            type={isSelfieCamera ? 'selfie' : documentType.value}
            numOfSteps={getNumOfSteps()}
          />
        </Styled.KycCameraHeader>
        {isPreview ? (
          <Styled.ImagePreview>
            <img src={image} alt="Snapshot" />
          </Styled.ImagePreview>
        ) : (
          <>
            <Camera
              ref={camera}
              aspectRatio="cover"
              numberOfCamerasCallback={setNumberOfCameras}
              facingMode={isSelfieCamera ? 'user' : 'environment'}
            />
            {isSelfieCamera ? <SelfieGuidelines /> : <IdGuidelines />}
          </>
        )}
        <Styled.KycCameraFooter>
          <Styled.FooterText>
            <CameraFooterText
              isImageDisplayed={!!image}
              isSelfie={isSelfieCamera}
            />
          </Styled.FooterText>
          <Styled.FooterActions>
            <CameraFooterActions
              cameraType={cameraType}
              handleCameraReverse={handleCameraReverse}
              isImageDisplayed={!!image}
              numberOfCameras={numberOfCameras}
              retryImage={retryImage}
              saveImage={saveImage}
              submitImage={submitImage}
              type={isSelfieCamera ? 'selfie' : documentType.value}
            />
          </Styled.FooterActions>
        </Styled.KycCameraFooter>
        {isLoading && (
          <Styled.LoadingOverlay>
            <Loader />
          </Styled.LoadingOverlay>
        )}
        {compareFacesFailed && (
          <Alert
            isOpen
            title="Face compare failed"
            text="We could not match the face from your selfie and your ID card"
            primaryBtnText="Try again"
            onPrimaryBtnClick={() => {
              setCameraType(cameraTypes.front);
              setIdCardFrontToken(null);
              setIdCardBackToken(null);
              setImage(undefined);
              setPreview(false);
              setCompareFacesFailed(false);
            }}
            secondaryBtnText="Exit verification"
            onSecondaryBtnClick={() => history.push('/')}
          />
        )}
      </Styled.CameraPreview>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={closeCb}
        >
          <IconClose color="#fff" />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

KycCamera.propTypes = {
  closeCb: PropTypes.func.isRequired,
  onKycComplete: PropTypes.func.isRequired,
  documentType: PropTypes.object.isRequired,
};

export default KycCamera;
