import styled from 'styled-components';

export const OrganizationOfferings = styled.div``;

export const OfferingsList = styled.div`
  margin-top: 8px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 294px);

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

export const ActionBtnText = styled.span`
  margin-left: 10px;
`;
