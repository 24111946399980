import styled from 'styled-components';

export const GlobalError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media screen and (max-width: 768px) {
    background-color: #fff;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  background-color: #fff;;
  max-width: 360px;
  border-width: 1px;
  border-style: solid;
  border-color: #D9D8D5;

  @media screen and (max-width: 768px) {
    border: none;
  }

  .IconAlert {
    margin-bottom: 34px;
  }
`;

export const ErrorTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #20201E;
  margin-bottom: 8px;
`;

export const ErrorText = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: #20201E;
  margin-bottom: 32px;
`;
