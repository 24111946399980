import styled from 'styled-components';

export const OfferingCard = styled.button`
  appearance: none;
  border: none;
  background: ${props => props.theme.colors.shades.neutral.white};
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  min-height: 340px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 100%;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 160px;
`;

export const BannerImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 160px;
  height: 100%;
  object-fit: cover;
`;

export const BannerImgDefault = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin: 0 16px 0 auto;
  margin-top: -32px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn40};
  background-color: ${props => props.theme.colors.shades.warm.dawn40};
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-height: 64px;
  object-fit: cover;
`;

export const CardInner = styled.div`
  padding: 0 16px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-top: 16px;
  word-break: break-word;
  text-align: left;
`;

export const Organization = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
  margin-bottom: 8px;
  text-align: left;
`;

export const Tagline = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-bottom: 16px;
  text-align: left;
  max-height: 42px;
  overflow: hidden;
  margin-bottom: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const VPContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const VPImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn40};
  margin-right: 10px;
`;

export const VPImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 24px;
  object-fit: cover;
  border-radius: 50%;
`;

export const VPLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn50};
  margin-right: 8px;
`;

export const VPName = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const FundingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const AmountRaised = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.success.success50};
`;

export const AmountSeparator = styled.div`
  margin: 0 4px;
`;

export const AmountGoal = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;
