import PropTypes from 'prop-types';

const IconPasswordShow = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.51203 7.99997C3.74775 11.4965 7.08286 14 11.0002 14C14.9175 14 18.2526 11.4966 19.4884 8.00003C18.2527 4.50345 14.9175 2 11.0002 2C7.08286 2 3.74776 4.50342 2.51203 7.99997ZM0.50396 7.70031C1.90538 3.23842 6.07329 0 11.0002 0C15.9271 0 20.0951 3.23846 21.4965 7.70039C21.5577 7.89546 21.5577 8.10462 21.4965 8.29969C20.095 12.7616 15.9271 16 11.0002 16C6.07329 16 1.90535 12.7615 0.503957 8.29961C0.442691 8.10454 0.442691 7.89538 0.50396 7.70031ZM10.9998 6C9.89519 6 8.99976 6.89543 8.99976 8C8.99976 9.10457 9.89519 10 10.9998 10C12.1043 10 12.9998 9.10457 12.9998 8C12.9998 6.89543 12.1043 6 10.9998 6ZM6.99976 8C6.99976 5.79086 8.79062 4 10.9998 4C13.2089 4 14.9998 5.79086 14.9998 8C14.9998 10.2091 13.2089 12 10.9998 12C8.79062 12 6.99976 10.2091 6.99976 8Z"
      fill={color}
    />
  </svg>
);

IconPasswordShow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconPasswordShow.defaultProps = {
  color: '#615F59',
  height: 16,
  width: 22,
};

export default IconPasswordShow;
