import PropTypes from 'prop-types';

const IconFilterOutline = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest} className="IconFilterOutline">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.89543 20 2V4.58579C20 5.11622 19.7893 5.62493 19.4142 6L13 12.4142L13 15C13 15.2652 12.8946 15.5196 12.7071 15.7071L8.70711 19.7071C8.42111 19.9931 7.99099 20.0787 7.61732 19.9239C7.24364 19.7691 7 19.4045 7 19V12.4142L0.585786 6C0.210712 5.62493 0 5.11622 0 4.58579V2ZM18 2H2V4.58579L8.41421 11C8.78929 11.3751 9 11.8838 9 12.4142V16.5858L11 14.5858V12.4142C11 11.8838 11.2107 11.3751 11.5858 11L18 4.58579V2Z"
      fill={color}
    />
  </svg>
);

IconFilterOutline.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconFilterOutline.defaultProps = {
  color: '#012934',
  height: 20,
  width: 20,
};

export default IconFilterOutline;
