import PropTypes from 'prop-types';

const IconEmail = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...rest} className="IconEmail">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9962 7.75082C15.8645 3.44784 12.3349 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.16103 16 10.2889 15.7519 11.3234 15.2789C11.8257 15.0492 12.0467 14.4559 11.817 13.9536C11.5874 13.4513 10.994 13.2303 10.4918 13.46C9.717 13.8142 8.87258 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8V9L13.9933 9.11662C13.9355 9.61396 13.5128 10 13 10C12.4477 10 12 9.55229 12 9V8L11.9951 7.80036C11.8911 5.68397 10.1422 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C9.04715 12 10.0003 11.5976 10.711 10.9393C11.2613 11.5882 12.0826 12 13 12C14.6569 12 16 10.6569 16 9V8L15.9962 7.75082ZM6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8Z"
      fill={color}
    />
  </svg>
);

IconEmail.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconEmail.defaultProps = {
  color: '#012934',
  height: 16,
  width: 16,
};

export default IconEmail;
