import { Route, Switch, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { checkKycStatus } from 'shared/helpers';
import { NotAuthorized } from 'shared/components';
import YocContainer from './YocContainer';
import YocDetails from './YocDetails';

const YOC = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const kYCStatus = checkKycStatus(get(user, 'status'));

  if (kYCStatus.isExternal) {
    return (
      <NotAuthorized
        title="You can’t access this page yet"
        text="Platform admin needs to verify you before you can use the app."
        btnText="Back to dashboard"
        onBtnClick={() => history.push('/')}
      />
    );
  }

  return (
    <Switch>
      <Route exact path="/yoc" component={YocContainer} />
      <Route exact path="/yoc/:yocID" component={YocDetails} />
    </Switch>
  );
};

export default YOC;
