import PropTypes from 'prop-types';
import * as Styled from './styles';

const IndicatorDot = ({
  isActive,
}) => (
  <Styled.IndicatorDot isActive={isActive} />
);

IndicatorDot.propTypes = {
  isActive: PropTypes.bool,
};

IndicatorDot.defaultProps = {
  isActive: false,
};

export default IndicatorDot;
