import PropTypes from 'prop-types';

const IconSearch = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...rest} className="IconSearch">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33342 2.33317C4.57199 2.33317 2.33341 4.57175 2.33341 7.33317C2.33341 10.0946 4.57199 12.3332 7.33342 12.3332C10.0948 12.3332 12.3334 10.0946 12.3334 7.33317C12.3334 4.57175 10.0948 2.33317 7.33342 2.33317ZM0.666748 7.33317C0.666748 3.65127 3.65152 0.666504 7.33342 0.666504C11.0153 0.666504 14.0001 3.65127 14.0001 7.33317C14.0001 8.87376 13.4775 10.2923 12.6 11.4212L17.0893 15.9106C17.4148 16.236 17.4148 16.7637 17.0893 17.0891C16.7639 17.4145 16.2363 17.4145 15.9108 17.0891L11.4215 12.5997C10.2925 13.4773 8.87401 13.9998 7.33342 13.9998C3.65152 13.9998 0.666748 11.0151 0.666748 7.33317Z"
      fill={color}
    />
  </svg>
);

IconSearch.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconSearch.defaultProps = {
  color: '#012934',
  height: 18,
  width: 18,
};

export default IconSearch;
