import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import { Button, Loader } from 'shared/components';
import { IconUpload } from 'shared/icons';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import api from 'shared/api';
import { getUserOrganizations } from 'redux/investing/actions';
import * as Styled from './styles';

const OrganizationLogoUpload = ({
  organization,
  handleFileChange,
  showBtn,
  showPreview,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');
  const organizationID = get(organization, 'id');

  const [isLoading, setLoading] = useState(false);
  const [logo, setLogo] = useState(get(organization, 'logo'));
  const [isLogoLoading, setLogoLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = event => {
    const file = get(event, 'target.files[0]');
    setSelectedFile(file);
    handleFileChange(file);
  };

  const updateBusinessLogo = useCallback(() => {
    setLoading(true);

    const formData = new FormData();
    formData.append(
      'image',
      selectedFile,
      get(selectedFile, 'name') || 'logo',
    );
    formData.append('type', 'logo');

    api.post(`/api/user-management/business-entities/${organizationID}/branding`, formData)
      .then(() => {
        setLogo(URL.createObjectURL(selectedFile));
        setLogoLoading(false);
        setLoading(false);
        setSelectedFile(null);
        dispatch(getUserOrganizations(userID));
        toast.success('Logo uploaded');
      })
      .catch(() => {
        setLoading(false);
        setLogoLoading(false);
        toast.error('Unable to upload logo');
      });
  }, [organizationID, selectedFile]);

  const handleSubmit = () => {
    if (isLoading) {
      return false;
    }

    setLoading(true);
    updateBusinessLogo();
    return true;
  };

  if (isLogoLoading) {
    return <Styled.OrganizationLogoUpload><Loader color="#A3A3A3" /></Styled.OrganizationLogoUpload>;
  }

  if (logo) {
    return (
      <Styled.OrganizationLogoUpload>
        <Styled.LogoPreview>
          {showPreview && <Styled.LogoPreviewImg src={logo} alt="logo" />}
        </Styled.LogoPreview>
        <div>
          <Styled.Label className="custom-file-upload" htmlFor="file-upload">
            <Styled.Input type="file" id="file-upload" onChange={onFileChange} />
            <Styled.UploadData>
              {selectedFile ? (
                <Styled.UploadPreviewWrapper>
                  <Styled.UploadPreview src={URL.createObjectURL(selectedFile)} />
                  <Button wide size="small" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
                    Upload
                  </Button>
                </Styled.UploadPreviewWrapper>
              ) : (
                <Styled.ChangeLogo>
                  <IconUpload height={14} width={14} />
                  Change logo
                </Styled.ChangeLogo>
              )}
            </Styled.UploadData>
          </Styled.Label>
        </div>
      </Styled.OrganizationLogoUpload>
    );
  }

  return (
    <Styled.OrganizationLogoUpload>
      {showPreview && <Styled.Logo src={noImagePlaceholder} alt="no logo placeholder" />}
      <Styled.Label className="custom-file-upload" htmlFor="file-upload">
        <Styled.Input type="file" id="file-upload" onChange={onFileChange} />
        <Styled.Uploader>
          <Styled.Left>
            <Styled.UploaderLabel>Organization logo</Styled.UploaderLabel>
            {selectedFile ? (
              <Styled.UploadPreviewWrapper>
                <Styled.UploadPreview src={URL.createObjectURL(selectedFile)} />
              </Styled.UploadPreviewWrapper>
            ) : (
              <Styled.Desc>Upload...</Styled.Desc>
            )}
          </Styled.Left>
          <Styled.Right><IconUpload /></Styled.Right>
        </Styled.Uploader>
      </Styled.Label>
      {selectedFile && showBtn && (
        <Button wide size="small" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
          Upload
        </Button>
      )}
    </Styled.OrganizationLogoUpload>
  );
};

OrganizationLogoUpload.propTypes = {
  organization: PropTypes.object.isRequired,
  handleFileChange: PropTypes.func,
  showBtn: PropTypes.bool,
  showPreview: PropTypes.bool,
};

OrganizationLogoUpload.defaultProps = {
  handleFileChange: () => {},
  showBtn: true,
  showPreview: true,
};

export default OrganizationLogoUpload;
