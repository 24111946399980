import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  engineName,
  engineVersion,
  getUA,
  deviceDetect,
} from 'react-device-detect';
import { appName } from './constants';
import api from './api';

const deviceData = {
  os_version: osVersion,
  os_name: osName,
  full_browser_version: fullBrowserVersion,
  browser_version: browserVersion,
  browser_name: browserName,
  mobile_vendor: mobileVendor,
  mobile_model: mobileModel,
  engine_name: engineName,
  engine_version: engineVersion,
  us: getUA,
  device_details: deviceDetect,
};

export const sendErrorReport = (err, action = '', additionalData) => {
  const data = {
    app_name: appName,
    message: JSON.stringify({
      device: deviceData,
      action,
      error: err,
      browser_name: deviceData.browser_name,
      data: additionalData,
    }),
  };
  api.post('/api/debugging', data);
};
