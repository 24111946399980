import PropTypes from 'prop-types';

const IconUserAdd = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" {...rest} className="IconUserAdd">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2C5.34315 2 4 3.34315 4 5C4 6.65685 5.34315 8 7 8C8.65685 8 10 6.65685 10 5C10 3.34315 8.65685 2 7 2ZM2 5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5C12 7.76142 9.76142 10 7 10C4.23858 10 2 7.76142 2 5ZM16 6C16.5523 6 17 6.44772 17 7V9H19C19.5523 9 20 9.44771 20 10C20 10.5523 19.5523 11 19 11H17V13C17 13.5523 16.5523 14 16 14C15.4477 14 15 13.5523 15 13V11H13C12.4477 11 12 10.5523 12 10C12 9.44771 12.4477 9 13 9H15V7C15 6.44772 15.4477 6 16 6ZM7 13C4.23858 13 2 15.2386 2 18H12C12 15.2386 9.76142 13 7 13ZM0 18C0 14.134 3.13401 11 7 11C10.866 11 14 14.134 14 18V19C14 19.5523 13.5523 20 13 20H1C0.447715 20 0 19.5523 0 19V18Z"
      fill={color}
    />
  </svg>
);

IconUserAdd.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUserAdd.defaultProps = {
  color: '#012934',
  height: 20,
  width: 20,
};

export default IconUserAdd;
