import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import {
  Button,
  FileUploader,
} from 'shared/components';
import { IconOffering, IconClose } from 'shared/icons';
import { getFileExtension } from 'shared/helpers';
import api from 'shared/api';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const DealRoomFilesUpload = ({
  isOpen,
  closeCb,
  dealRoom,
  refetchFiles,
  members,
}) => {
  const dealRoomID = get(dealRoom, 'id');
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const member = members.find(m => m.profile.user_id === userID);
  const memberRoleID = get(member, 'member.user_role_id');

  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const uploadFiles = useCallback((data) => {
    const formData = new FormData();
    formData.append(
      'content',
      data.file,
      get(data, 'file.name') || 'file',
    );
    formData.append('name', get(data, 'file.name'));
    formData.append('type', 'deal_room_document');
    formData.append('user_role_id', memberRoleID);
    formData.append('extension', getFileExtension(get(data, 'file.name')));
    return api.post(`/api/investing/deal-rooms/${dealRoomID}/files`, formData);
  }, [dealRoomID]);

  const submitAllFiles = () => Promise.all(files.map(uploadFiles));

  const handleSubmit = () => {
    if (!files.length) {
      toast.error('No files have been added');
      return false;
    }
    setLoading(true);
    submitAllFiles()
      .then(() => {
        refetchFiles();
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="DealRoomFilesUploadModal"
    >
      <Styled.DealRoomFilesUpload>
        <Styled.Main>
          <Styled.Header>
            <IconOffering color="#20201E" />
            <Styled.HeaderTitle>Dealroom files upload</Styled.HeaderTitle>
          </Styled.Header>
          <Styled.FileUploaderLabel>
            Upload shareholder list and financial information documents
          </Styled.FileUploaderLabel>
          <Styled.InputContainer>
            <FileUploader
              maxFiles={20}
              onUploadSuccess={(meta, allFiles) => {
                setFiles(allFiles);
              }}
              onItemRemove={(meta) => {
                const newFiles = files.filter(f => f.meta.id !== meta.id);
                setFiles(newFiles);
              }}
              validFileTypes=".pdf,.doc,.docx,.xls,.xlsx"
              disabled={isLoading}
            />
          </Styled.InputContainer>

          <Styled.ButtonContainer>
            <Button
              type="submit"
              wide
              handleClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </Button>
          </Styled.ButtonContainer>
        </Styled.Main>
      </Styled.DealRoomFilesUpload>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

DealRoomFilesUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchFiles: PropTypes.func.isRequired,
  dealRoom: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
};

export default DealRoomFilesUpload;
