import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import { Button, Checkbox, PriorityCell } from 'shared/components';
import { IconFilter, IconClose } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const TicketFiltersForm = ({
  isOpen,
  closeCb,
  filters,
}) => {
  const [priority, setPriority] = useState(get(filters, 'priority') || '');
  const [status, setStatus] = useState(get(filters, 'status') || '');

  const handleChange = (val, cb, stateField) => {
    if (stateField === val) {
      cb('');
    } else {
      cb(val);
    }
  };

  const handleClose = () => {
    const data = {
      priority,
      status,
    };
    closeCb(data);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="TicketFiltersFormModal"
    >
      <Styled.TicketFiltersForm>
        <Styled.Header>
          <IconFilter />
          <Styled.HeaderTitle>Select filters</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.FilterSection>
          <Styled.Subtitle>Priority</Styled.Subtitle>
          <Checkbox
            id="critical"
            onChange={() => handleChange('critical', setPriority, priority)}
            label={<PriorityCell value="critical" />}
            checked={priority === 'critical'}
          />
          <Checkbox
            id="high"
            onChange={() => handleChange('high', setPriority, priority)}
            label={<PriorityCell value="high" />}
            checked={priority === 'high'}
          />
          <Checkbox
            id="normal"
            onChange={() => handleChange('normal', setPriority, priority)}
            label={<PriorityCell value="normal" />}
            checked={priority === 'normal'}
          />
          <Checkbox
            id="low"
            onChange={() => handleChange('low', setPriority, priority)}
            label={<PriorityCell value="low" />}
            checked={priority === 'low'}
          />
        </Styled.FilterSection>
        <Styled.FilterSection>
          <Styled.Subtitle>Status</Styled.Subtitle>
          <Checkbox
            id="open"
            onChange={() => handleChange('open', setStatus, status)}
            label={<div>Open</div>}
            checked={status === 'open'}
          />
          <Checkbox
            id="closed"
            onChange={() => handleChange('closed', setStatus, status)}
            label={<div>Closed</div>}
            checked={status === 'closed'}
          />
        </Styled.FilterSection>
        <Button
          wide
          onClick={handleClose}
        >
          Save
        </Button>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={() => closeCb(null)}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.TicketFiltersForm>
    </ReactModal>
  );
};

TicketFiltersForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default TicketFiltersForm;
