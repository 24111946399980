import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import {
  IconLocation,
  IconNoProfilePicture,
  IconUserAddFill,
  IconEdit,
} from 'shared/icons';
// import { capitalizeFirstLetter } from 'shared/helpers';
import { Button } from 'shared/components';
import { AddUserForm } from 'Yoc/components';
import ChangePasswordForm from '../ChangePasswordForm';
import UserProfileForm from '../UserProfileForm';
import ChangeProfilePictureForm from '../ChangeProfilePictureForm';
import * as Styled from './styles';

const getCircleTypeName = (connection = {}, types = []) => {
  const circles = Object.keys(connection) || [];
  const userCircle = types.find((t) => circles.includes(t.name));
  if (!userCircle) {
    return '-';
  }
  // return capitalizeFirstLetter(get(userCircle, 'name'));
  return 'Connected';
};

const ProfileCard = ({
  data,
  connection,
  invitations,
  isAuth,
  refetchProfile,
  showActions,
  showLink,
}) => {
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');
  const topologies = useSelector(state => get(state, 'topology.types'));
  const [showPasswordForm, setPasswordFormDisplay] = useState(false);
  const [isAddUserFormDisplayed, setAddUserFormDisplay] = useState(false);
  const [isEditUserFormDisplayed, setEditUserFormDisplay] = useState(false);
  const [isChangeImageDisplayed, setChangeImageDisplayed] = useState(false);

  const backgroundImg = get(data, 'background_image') || '';
  const profilePicture = get(data, 'profile_picture') || '';

  const hasPendingInvitation = () => {
    const inv = invitations.find(i => i.inviter_id === userID);
    return !!inv;
  };

  const name = get(data, 'full_name') || get(data, 'user_name') || 'N/A';
  const country = get(data, 'country_of_residence') || '';
  const countries = useSelector(state => get(state, 'investing.countries'));
  const countryOfResidence = countries.find(c => get(c, 'id') === country);
  const city = get(data, 'city') || '';
  const address = `${city}${country ? `, ${get(countryOfResidence, 'name')}` : ''}`;

  return (
    <Styled.ProfileCard className="ProfileCard">
      <Styled.ProfileCardInner>
        <Styled.Banner>
          {backgroundImg ? (
            <Styled.BannerImg src={backgroundImg} alt="profile background" />
          ) : (
            <Styled.BannerImgDefault />
          )}
        </Styled.Banner>
        <Styled.ProfilePictureWrapper>
          {profilePicture ? (
            <Styled.ProfilePicture src={profilePicture} alt="profile picture" />
          ) : (
            <IconNoProfilePicture width={80} height={80} />
          )}
          {isAuth && (
            <Styled.EditBtn
              type="button"
              onClick={() => setChangeImageDisplayed(true)}
              className="EditBtn"
            >
              <IconEdit />
            </Styled.EditBtn>
          )}
        </Styled.ProfilePictureWrapper>
        <Styled.CardInner>
          <Styled.Name>
            {showLink ? <Link to={`/yoc/${get(data, 'user_id')}`}>{name}</Link> : name}
          </Styled.Name>
          <Styled.Title>
            {get(data, 'title') || 'N/A'}
          </Styled.Title>
          <Styled.Address>
            <IconLocation />
            <Styled.AddressText>{address}</Styled.AddressText>
          </Styled.Address>
          {showActions && (
            <>
              {isAuth && (
                <Button variant="outlined" size="small" onClick={() => setEditUserFormDisplay(true)}>
                  Edit profile
                </Button>
              )}
              {isAuth && (
                <Button variant="outlined" size="small" onClick={() => setPasswordFormDisplay(true)}>
                  Change password
                </Button>
              )}
              {connection && (
                <Styled.Connection>{getCircleTypeName(connection, topologies)}</Styled.Connection>
              )}
              {!connection && !isAuth && !hasPendingInvitation() && (
                <Button variant="outlined" size="small" onClick={() => setAddUserFormDisplay(true)}>
                  <Styled.ConnectBtnInner>
                    <IconUserAddFill />
                    <>Connect</>
                  </Styled.ConnectBtnInner>
                </Button>
              )}
              {hasPendingInvitation() && (
                <Styled.Connection>Pending invitation</Styled.Connection>
              )}
            </>
          )}
        </Styled.CardInner>
      </Styled.ProfileCardInner>
      {showPasswordForm && (
        <ChangePasswordForm
          isOpen={showPasswordForm}
          onClose={() => setPasswordFormDisplay(false)}
        />
      )}
      {isEditUserFormDisplayed && (
        <UserProfileForm
          isOpen={isEditUserFormDisplayed}
          closeCb={() => setEditUserFormDisplay(false)}
          user={data}
          refetchUser={refetchProfile}
        />
      )}
      {isAddUserFormDisplayed && (
        <AddUserForm
          isOpen={isAddUserFormDisplayed}
          onClose={() => {
            refetchProfile();
            setAddUserFormDisplay(false);
          }}
          userToAdd={data}
        />
      )}
      {isChangeImageDisplayed && (
        <ChangeProfilePictureForm
          isOpen={isChangeImageDisplayed}
          closeCb={() => setChangeImageDisplayed(false)}
          confirmCb={() => {
            refetchProfile();
            setChangeImageDisplayed(false);
          }}
          user={data}
        />
      )}
    </Styled.ProfileCard>
  );
};

ProfileCard.propTypes = {
  connection: PropTypes.object,
  invitations: PropTypes.array,
  data: PropTypes.object.isRequired,
  isAuth: PropTypes.bool,
  showActions: PropTypes.bool,
  showLink: PropTypes.bool,
  refetchProfile: PropTypes.func,
};

ProfileCard.defaultProps = {
  connection: null,
  invitations: [],
  isAuth: false,
  showActions: true,
  showLink: false,
  refetchProfile: () => {},
};

export default ProfileCard;
