import PropTypes from 'prop-types';

const IconEdit = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 20 21" fill="none" {...rest} className="IconEdit">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5606 2.43934C16.9748 1.85355 16.0251 1.85355 15.4393 2.43934L14.6464 3.23223L16.7677 5.35355L17.5606 4.56066C18.1464 3.97487 18.1464 3.02513 17.5606 2.43934ZM15.3535 6.76777L13.2322 4.64645L2 15.8786V18.0355H4.08579L15.3535 6.76777ZM14.0251 1.02513C15.3919 -0.341709 17.608 -0.341709 18.9748 1.02513C20.3417 2.39196 20.3417 4.60804 18.9748 5.97487L5.20711 19.7426C5.01957 19.9301 4.76522 20.0355 4.5 20.0355H1C0.447715 20.0355 0 19.5878 0 19.0355V15.4644C0 15.1992 0.105357 14.9448 0.292893 14.7573L14.0251 1.02513Z"
      fill={color}
    />
  </svg>
);

IconEdit.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconEdit.defaultProps = {
  color: '#012934',
  height: 21,
  width: 20,
};

export default IconEdit;
