import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Page,
  PageLoader,
  Table,
} from 'shared/components';
import { capitalizeFirstLetter, formatDate } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
import { IconKey } from 'shared/icons';
import api from 'shared/api';
import * as Styled from './styles';

const DealRoomsList = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const getDealRooms = useCallback(() => {
    api.get(`/api/investing/users/${userID}/deal-rooms`)
      .then((res) => {
        setData(get(res, 'data') || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getDealRooms();
  }, [getDealRooms]);

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: cell => capitalizeFirstLetter(cell.value, 'N/A'),
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: cell => formatDate(get(cell, 'value'), defaultDateFormat),
    },
  ], []);

  const handleRowClick = row => {
    history.push(`/dealrooms/${get(row, 'id')}`);
  };

  if (isLoading) {
    return <Page type="dealrooms" title="Dealrooms"><PageLoader color="#616E7F" /></Page>;
  }

  return (
    <Page type="dealrooms" title="Dealrooms">
      <Styled.DealRoomsWrapper>
        <Styled.DealRoomsList>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconKey color="#7A7A7A" height={18} width={18} /></Styled.TitleIcon>
              <Styled.TitleText>Deal rooms</Styled.TitleText>
            </Styled.ListTitle>
          </Styled.ListHeader>
          <Styled.ListWrapper>
            <Table
              columns={tableColumns}
              data={tableData}
              isClickable
              onRowClick={handleRowClick}
              rowClassName="DealRoomsListRow"
            />
          </Styled.ListWrapper>
        </Styled.DealRoomsList>
      </Styled.DealRoomsWrapper>
    </Page>
  );
};

export default DealRoomsList;
