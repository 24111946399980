import * as actionTypes from './actionTypes';

const initialState = {
  topologyTypesLoading: true,
  types: [],
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getTopologyTypesInit:
      return { ...state, topologyTypesLoading: true };
    case actionTypes.getTopologyTypesSuccess:
      return {
        ...state,
        topologyTypesLoading: false,
        types: action.types,
        error: undefined,
      };
    case actionTypes.getTopologyTypesError:
      return { ...state, topologyTypesLoading: false };
    default:
      return { ...state };
  }
}
