import PropTypes from 'prop-types';

const EmojiSmiling = ({
  height,
  width,
  background,
  strokes,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" {...rest} className="EmojiSmiling">
    <path d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0 8.06 8.06 0 18 0C27.941 0 36 8.06 36 18Z" fill={background} />
    <path d="M11.5 18C12.8807 18 14 15.5376 14 12.5C14 9.46243 12.8807 7 11.5 7C10.1193 7 9 9.46243 9 12.5C9 15.5376 10.1193 18 11.5 18Z" fill={strokes} />
    <path d="M24.5 18C25.8807 18 27 15.5376 27 12.5C27 9.46243 25.8807 7 24.5 7C23.1193 7 22 9.46243 22 12.5C22 15.5376 23.1193 18 24.5 18Z" fill={strokes} />
    <path d="M18 22C14.377 22 11.973 21.578 9 21C8.321 20.869 7 21 7 23C7 27 11.595 32 18 32C24.404 32 29 27 29 23C29 21 27.679 20.868 27 21C24.027 21.578 21.623 22 18 22Z" fill={strokes} />
    <path d="M9 23C9 23 12 24 18 24C24 24 27 23 27 23C27 23 25 27 18 27C11 27 9 23 9 23Z" fill="white" />
  </svg>
);

EmojiSmiling.propTypes = {
  background: PropTypes.string,
  strokes: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EmojiSmiling.defaultProps = {
  background: '#FFCC4D',
  strokes: '#664500',
  height: 26,
  width: 26,
};

export default EmojiSmiling;
