import PropTypes from 'prop-types';

const IconUserProfile = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" {...rest} className="IconUserProfile">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.66666C4.05448 1.66666 1.66666 4.05448 1.66666 7C1.66666 8.11978 2.01176 9.15896 2.60141 10.017C3.9294 9.36559 5.42279 9 7 9C8.5772 9 10.0706 9.36559 11.3986 10.017C11.9882 9.15896 12.3333 8.11978 12.3333 7C12.3333 4.05448 9.94551 1.66666 7 1.66666ZM12.1028 11.2904C13.0787 10.1309 13.6667 8.63412 13.6667 7C13.6667 3.3181 10.6819 0.333328 7 0.333328C3.3181 0.333328 0.333328 3.3181 0.333328 7C0.333328 8.63413 0.921278 10.1309 1.89718 11.2904C1.90245 11.2969 1.90783 11.3033 1.91332 11.3095C3.13619 12.7515 4.96122 13.6667 7 13.6667C9.03877 13.6667 10.8638 12.7515 12.0867 11.3095C12.0922 11.3033 12.0975 11.2969 12.1028 11.2904ZM10.4658 11.0539C9.40493 10.5905 8.23314 10.3333 7 10.3333C5.76686 10.3333 4.59506 10.5905 3.53424 11.0539C4.4664 11.8516 5.67694 12.3333 7 12.3333C8.32305 12.3333 9.53359 11.8516 10.4658 11.0539ZM7 4.33333C6.26362 4.33333 5.66666 4.93028 5.66666 5.66666C5.66666 6.40304 6.26362 7 7 7C7.73637 7 8.33333 6.40304 8.33333 5.66666C8.33333 4.93028 7.73637 4.33333 7 4.33333ZM4.33333 5.66666C4.33333 4.1939 5.52724 2.99999 7 2.99999C8.47275 2.99999 9.66666 4.1939 9.66666 5.66666C9.66666 7.13942 8.47275 8.33333 7 8.33333C5.52724 8.33333 4.33333 7.13942 4.33333 5.66666Z"
      fill={color}
    />
  </svg>
);

IconUserProfile.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconUserProfile.defaultProps = {
  color: '#E6EAEB',
  height: 14,
  width: 14,
};

export default IconUserProfile;
