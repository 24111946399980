import PropTypes from 'prop-types';

const IconNoProfilePicture = ({
  height,
  width,
  color,
  ...rest
}) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} className="IconNoProfilePicture">
    <rect width={36} height={36} rx={width / 2} fill="#B4B1AA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C16.3431 10 15 11.3431 15 13C15 14.6569 16.3431 16 18 16C19.6569 16 21 14.6569 21 13C21 11.3431 19.6569 10 18 10ZM13 13C13 10.2386 15.2386 8 18 8C20.7614 8 23 10.2386 23 13C23 15.7614 20.7614 18 18 18C15.2386 18 13 15.7614 13 13ZM12.083 26H23.917C23.441 23.1623 20.973 21 18 21C15.027 21 12.559 23.1623 12.083 26ZM10 27C10 22.5817 13.5817 19 18 19C22.4183 19 26 22.5817 26 27C26 27.5523 25.5523 28 25 28H11C10.4477 28 10 27.5523 10 27Z"
      fill={color}
    />
  </svg>
);

IconNoProfilePicture.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconNoProfilePicture.defaultProps = {
  color: '#E6EAEB',
  height: 36,
  width: 36,
};

export default IconNoProfilePicture;
