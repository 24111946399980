import styled from 'styled-components';

export const Pagination = styled.div`

  .Pagination {
    display: flex;
    align-items: center;
    margin-inline: 0;
    margin-block: 0;
    padding-inline: 0;
    list-style-type: none;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.colors.shades.warm.dawn30};
      min-width: 40px;
      height: 40px;

      &:last-of-type {
        margin-right: 0;
      }

      @media screen and (max-width: ${props => props.theme.breakpoints.xxxs}) {
        min-width: 30px;
        height: 30px;
        font-size: 14px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: ${props => props.theme.colors.primary.daintree.daintree50};
      }

      &.Pagination-active {
        border: none;
        font-weight: 600;
      }

      &.next,
      &.previous {

        &.disabled {
          background: ${props => props.theme.colors.shades.warm.dawn10};
          border-color: transparent;
          
          a {
            cursor: not-allowed;
          }
          
          svg {

            path {
              fill: ${props => props.theme.colors.shades.warm.dawn50}
            }
          }
        }
      }
    }
  }
`;
