import styled from 'styled-components';

export const SelfieGuidelines = styled.div`
  position: absolute !important;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -65%);

  @media screen and (max-width: ${props => props.theme.breakpoints.xxxs}) {
    top: 48%;
    
    svg {
      height: 304px;
    }
  }

  @media screen and (max-width: 280px) {
    top: 42%;
    
    svg {
      height: 304px;
    }
  }
`;
