import PropTypes from 'prop-types';
import { Button } from 'shared/components';
import * as Styled from './styles';

const SubmitImage = ({
  onImageSubmit,
  onImageRetry,
  type,
}) => (
  <Styled.SubmitImage>
    <Button variant="outlined" handleClick={() => onImageRetry(type)}>No, retry</Button>
    <Button handleClick={() => onImageSubmit(type)}>Yes, submit</Button>
  </Styled.SubmitImage>
);

SubmitImage.propTypes = {
  onImageSubmit: PropTypes.func.isRequired,
  onImageRetry: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default SubmitImage;
